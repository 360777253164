import styled from 'styled-components'

export const Container = styled.footer`
  background-color: var(--footer-background);
  height: 300px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

`;

export const H3 = styled.h3`
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--off-white);
  letter-spacing: 2px;
`;

export const CopyrightText = styled.p`
  font-size: 0.75rem;
  color: var(--footer-text);
  margin-bottom: 15px;
  padding-bottom: 1em;

`;
