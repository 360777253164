// Provides context for the Add Property - Basic Details Component

import React, { useState, useRef, useEffect } from 'react'
import * as GPSLOC from '../constants/gps-locations'
import {RibbitPrimaryPropTypesI, RibbitStdCommercialTypesII} from '../constants/RibbitPropertyTypes'

const NewPropContext = React.createContext()

// const AppTopContext = React.createContext()

const propFullAddrObj_INIT = {
    type: 'feature',
    geometry: {
        type: 'Point',
        coordinates: [0, 0]
    },
    geoCoded: {
        geometry: null,
        bounds: null,
        addr: null,
    },
    propType: '',
    propTypeNm: '',
    propSubType: '',
    propSubTypeNm: '',
    userAddr: '',
    userAddrFormatted: '',
    zoomLevel: 17.5,
}


function NewPropContextProvider(props) {
    
    const [ contactDetails,     setContactDetails     ] = useState(JSON.parse(window.sessionStorage.getItem('cdtls')) || '')
    const [ addPropDataPageNum, setAddPropDataPageNum ] = useState(1)
    
    // Property Details
    const [ propertyID,         setPropertyID ] = useState(null)            // _Id assigned by database
    const [ RPID,               setRPID       ] = useState(null)
    
    // propType - Com/Res/Lnd/Mxd
    const [ propType,           setPropType       ] = useState('COM')   // COM / RES / LND / MXD
    const [ propTypeNm,         setPropTypeNm     ] = useState('Commercial')
    // Sub Property Type - Main
    const [ propSubType,        setPropSubType    ] = useState(null)
    const [ propSubTypeNm,     _setPropSubTypeNm  ] = useState('')



    const [ propMaster,         setPropMaster     ] = useState(false)

    
    
    const [ addrDetails,        setAddrDetails      ] = useState({})  // Controlled form state for <AddressDetails />
    const [ fullAddrDetails,    setFullAddrDetails  ] = useState(JSON.parse(window.sessionStorage.getItem('propData-1-Addr')) || propFullAddrObj_INIT)

    const [ basicPropData1,  setBasicPropData1 ] = useState(JSON.parse(window.sessionStorage.getItem('propBasic-1-data')) || {})
    const [ basicPropData2,  setBasicPropData2 ] = useState(JSON.parse(window.sessionStorage.getItem('propBasic-2-data')) || {})
    const [ basicPropData3,  setBasicPropData3 ] = useState(JSON.parse(window.sessionStorage.getItem('propBasic-3-data')) || {})
    const [ basicPropData4,  setBasicPropData4 ] = useState(JSON.parse(window.sessionStorage.getItem('propBasic-4-data')) || {})
    const [ basicPropData5,  setBasicPropData5 ] = useState(JSON.parse(window.sessionStorage.getItem('propBasic-5-data')) || {})
    const [ basicPropData6,  setBasicPropData6 ] = useState(JSON.parse(window.sessionStorage.getItem('propBasic-6-data')) || {})

    // Regional Settings - 
    const [ currency,           setCurrency          ] = useState('eur')                // Local Currency  EURO: €,  USD, $, {cur:'EUR',sym:'€'}
    const [ areaUnits,          setAreaUnits         ] = useState('sqm')                // Area Units: Sq.ft | Sq.m
    const [ linearUnits,        setLinearUnits       ] = useState('m')                  // Linear Units: ft | m
    const [ landAreaUnits,      setLandAreaUnits     ] = useState('ha')                 // Land Area Units acr acres | ha: hectares
    const [ combinedAreaUnits,  setCombinedAreaUnits ] = useState('sqm')                // Combined area units: sqM sqFt ha ac

    // SessionStorage handle - for autosaving form data
    const sessionRefPassed = useRef(window.sessionStorage)

    // gpsCoords is [lng, lat]
    const [gpsCoords, setGpsCoords] = useState(GPSLOC.MALTA.geometry.coordinates)

    //
    const [address, setAddress] = useState({})

    function savePropType(e) {
        const { value } = e.target
        console.log("(NewPropContext): PropType: ", value)
        setPropType(value)
        setPropTypeNm( RibbitPrimaryPropTypesI.map(rec =>  rec.ab === value )[0].nm )
    }
    function saveSubPropType(value) {
        //const { value } = e.target
        console.log("(NewPropContext):SubType: ", value)
        setPropSubType(value)
        // setPropSubTypeNm( null )
    }
    function setPropSubTypeNm(value) {
        console.log("CALLED: setPropSubTypeNm() ", value)
        _setPropSubTypeNm( value )
    }


    

    function updateContactDetails(e) {
        const { value } = e.target
        //console.log("UpdateContactDetails: ",value)
        setContactDetails(value)
        window.sessionStorage.setItem('cdtls', JSON.stringify(value))
    }
    function btnDisabled() {                                         // For addr start page.  Should move this to the page. It's local control.
        return contactDetails.length === 0  && propSubType !== null  // Check to see if Contact info is added and the subproperty type has been selected
    }

    // Store all Address details in Session storage. Default key.
    // 
    function sessionStoreFullAddrData(key = 'propData-1-Addr') {
        console.log("Session Store: ", fullAddrDetails)
        if (fullAddrDetails) {
            window.sessionStorage.setItem(key, JSON.stringify(fullAddrDetails))
        }
    }

    // Retrieve stored address details from session storage
    // 
    function sessionGetFullAddrData(key = 'AddrData') {
        return window.sessionStorage.getItem(key) || null
    }

    // Debug - propTypeNm - works!
    //useEffect(()=>{console.log("PropTypeNm: ", propTypeNm)},[propTypeNm])
    useEffect(()=>{console.log("propSubTypeNm: ", propSubTypeNm)},[propSubTypeNm])

    // function setBasicPropData1(data) {
    //     _setBasicComPropData(data)
    //     console.log("BasicPropData: ",basicPropData1)
    // }

    // useEffect(() => {
    //     console.log("PropCntxt BasicPropData1: ", basicPropData1)
    // }, [basicPropData1])
    // useEffect(() => {
    //     console.log("PropCntxt BasicPropData2: ", basicPropData2)
    // }, [basicPropData2])
    // useEffect(() => {
    //     console.log("PropCntxt BasicPropData3: ", basicPropData3)
    // }, [basicPropData3])
    // useEffect(() => {
    //     console.log("PropCntxt BasicPropData4: ", basicPropData4)
    // }, [basicPropData4])
    // useEffect(() => {
    //     console.log("PropCntxt BasicPropData5: ", basicPropData5)
    // }, [basicPropData5])
    // useEffect(() => {
    //     console.log("PropCntxt BasicPropData6: ", basicPropData6)
    // }, [basicPropData6])
    // useEffect(() => {
    //     console.log("PropCntxt Currency: ", currency)
    // }, [currency])

    useEffect(()=>{
        console.log("(NewPropContext): propTypeNm: ", propTypeNm)
    },[propTypeNm])

    //console.log("Full Addr Details:", fullAddrDetails)
    return (
        <NewPropContext.Provider value={{
            propertyID, propType, propSubType, contactDetails, addrDetails, gpsCoords, address, fullAddrDetails,
            sessionRefPassed, currency, areaUnits, linearUnits, landAreaUnits, combinedAreaUnits,
            basicPropData1, basicPropData2, basicPropData3, basicPropData4, basicPropData5, basicPropData6, RPID,
            propTypeNm,propSubTypeNm,setPropTypeNm,setPropSubTypeNm,
            setLandAreaUnits, setLinearUnits, setAreaUnits, setCombinedAreaUnits, setCurrency,
            setBasicPropData1, setBasicPropData2, setBasicPropData3, setBasicPropData4, setBasicPropData5, setBasicPropData6,
            setPropertyID, setFullAddrDetails, btnDisabled, savePropType, saveSubPropType, updateContactDetails,
            setAddrDetails, setGpsCoords, setAddress, sessionStoreFullAddrData, sessionGetFullAddrData,
            addPropDataPageNum, setAddPropDataPageNum, setRPID, propMaster, setPropMaster
        }}>
            {props.children}
        </NewPropContext.Provider>
    )

}
export { NewPropContextProvider, NewPropContext }