import React, { useState , useRef, useEffect} from 'react'

const AppContext = React.createContext()


function AppContextProvider(props) {

  // User Name / UUID
  const [userName, setUserName] = useState(window.localStorage.getItem('username') || null)

  // set once logged in through Auth
  const [loggedIn, setLoggedIn] = useState(window.localStorage.getItem('loggedIn') || false)

  // Access token returned from the server once the user has successfully logged in
  const [ authToken, setAuthToken ] = useState(window.localStorage.getItem('rtk') || null)

  const [localCntry, setLocalCntry] = useState( window.localStorage.getItem('localCntry') || 'ARE')

  const [rememberMe, setRememberMe] = useState( window.localStorage.getItem('rmbMe') || false )  // TODO: Add to userconfig and to login modal - use only for username/pwd type
  

  // TODO:
  // Need to add useEffect handlers to set the localStorage values whenever 
  // any of these falue change:



  const exportValues = ({ 
            userName, setUserName, 
            loggedIn, setLoggedIn,
            authToken, setAuthToken,
            localCntry, setLocalCntry,
            rememberMe, setRememberMe,
  })

  return(
    <AppContext.Provider  value={exportValues}>
       {props.children}
    </AppContext.Provider>
  )
}

export {AppContextProvider, AppContext}