import React,{useState} from 'react'
import { Container, SearchLink, DropDown, DropBtn, DropDownContent, SearchSelect, SearchTextbox, SearchCheckBox, 
       CheckBoxItem, Btn, RadioButton, RadioButtonItem, Label, TextInputItem, Textbox, Row, Group  } from './styles/searchBar'

export default function SearchBar({ children, ...restProps }) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

SearchBar.SearchLink = function NavbarSearchLink({ to, children, ...restProps }) {
    return <SearchLink to={to} {...restProps}>{children}</SearchLink>
}

SearchBar.Row = function SearchBarRow({children, ...restProps}) {
  return <Row {...restProps}>{children}</Row>
}

SearchBar.Group = function SearchBarRow({children, ...restProps}) {
  return <Group {...restProps}>{children}</Group>
}


SearchBar.DropDown = function SearchBarDropDown({ children, ...restProps }) {
    return <DropDown {...restProps}>{children}</DropDown>
}

SearchBar.DropBtn = function SearchBarDropBtn({ children, ...restProps }) {
  return <DropBtn {...restProps} >{children}</DropBtn>
}

SearchBar.DropDownContent = function SearchBarDropDownContent({ children, ...restProps }) {
  return <DropDownContent {...restProps} >{ children}</DropDownContent>
}

SearchBar.Select = function SearchBarSelect({children, ...restProps}) {
  return <SearchSelect {...restProps} >{children}</SearchSelect>
}

SearchBar.TextInput = function SearchBarTextBox({id, name, value,...restProps}) {
  return <SearchTextbox id={id} name={name} value={value} {...restProps} />
}

// SearchBar.CheckBox = function SearchBarCheckBox({ ...restProps}) {
//   return <SearchCheckBox {...restProps} />
// }

SearchBar.RadioButton = function SearchBarRadioButton({id, name, value, children, ...restProps}) {
  return <RadioButton id={id} name={name} value={value} {...restProps}>{children}</RadioButton>
}

SearchBar.Btn = function SearchBarBtn({children, ...restProps}) {
  return <Btn {...restProps}>{children}</Btn>
}

SearchBar.CheckBoxItem = function SearchBarCheckBoxItem({children, id, name, value, onChange, ...restProps}) {
  return(
    <CheckBoxItem {...restProps}>
      <SearchCheckBox id={id} name={name} value={value} {...restProps} onChange={onChange}/>
      <Label htmlFor={id}>{children}</Label>
    </CheckBoxItem>
  )
}

SearchBar.RadioButtonItem = function SearchBarRadioItem({children, id, name, value, ...restProps}) {
  return(
    <RadioButtonItem {...restProps}>
        <RadioButton {...restProps} id={id} name={name} value={value}></RadioButton>
        <Label htmlFor={id}>{children}</Label> 
    </RadioButtonItem>
  )
}

SearchBar.TextInputItem = function SearchBarTextInputItem({children, id, name, value, ...restProps}) {
  return(
    <TextInputItem {...restProps}>
       <Label htmlFor={id}>{children}</Label>
       <Textbox id={id} name={name} value={value} {...restProps} />
    </TextInputItem>
  )
}