import React, { lazy, Suspense, } from 'react'
import { Route, Switch } from 'react-router-dom'
import * as ROUTES from './constants/routes'

import { HeaderContainer } from './containers/headerContainer'
import { Main } from './components'
import FooterContainer from './containers/footerContainer'

import { NewPropContextProvider } from './contexts/NewPropContext'


const Home = lazy(() => import('./pages/Home'))
const MapWorkspace = lazy(() => import('./pages/MapWorkspace'))
const NotFound = lazy(() => import('./pages/NotFound'))
const DevTest = lazy(() => import('./pages/DevTest'))
const DevTest2 = lazy(() => import('./pages/DevTest2/DevTest2'))
const DevTest3 = lazy(() => import('./pages/DevTest3/DevTest3'))
const DevTest4 = lazy(() => import('./pages/DevTest4/DevTest4'))
const DevTest5 = lazy(() => import('./pages/DevTest5/DevTest5'))
const DevTest6 = lazy(() => import('./pages/DevTest6/DevTest6'))

const AddProperty       = lazy(() => import('./pages/addProperty/AddProperty'))
const AddPropertyByAddr = lazy(() => import('./pages/addProperty/AddPropertyByAddr'))
const AddPropertyByMap  = lazy(() => import('./pages/addProperty/AddPropertyByMap'))
const PropSummary       = lazy(() => import('./pages/PropPage/PropSummary'))

const AddComPropData    = lazy(() => import('./pages/addProperty/AddComPropData'))
const AddResPropData    = lazy(() => import('./pages/addProperty/AddResPropData'))
const AddMxdPropData    = lazy(() => import('./pages/addProperty/AddMxdPropData'))
const AddLndPropData    = lazy(() => import('./pages/addProperty/AddLndPropData'))

//<Suspense fallback={<div style={{height:'100vh', width:'auto'}}><p className="loading">Ribbit is loading . . .</p></div>}>

const PropSummaryWithID = ({ match }) => { return (<PropSummary propId={match.params.rpid} />) }

function App() {
  return (
    <div className="app">
      <HeaderContainer />
      <Suspense fallback={<div style={{ height: '100vh', width: 'auto' }}><p className="loading">Ribbit is loading . . .</p></div>}>
        <Main>
          <NewPropContextProvider>
            <Switch>
              <Route path={ROUTES.PREFERENCES}><NotFound /></Route>
              <Route path={ROUTES.ADD_PROP} exact><AddProperty /></Route>
              {/* <Route path={ROUTES.ADD_PROP_2}><AddProperty2 /></Route> */}
              <Route path={ROUTES.ADD_PROP_ADDR}><AddPropertyByAddr /></Route>
              <Route path={ROUTES.ADD_PROP_MAP}><AddPropertyByMap /></Route>
              <Route path={ROUTES.ADD_PROP_COM_BASIC}><AddComPropData /></Route>
              <Route path={ROUTES.ADD_PROP_RES_BASIC}><AddResPropData /></Route>
              <Route path={ROUTES.ADD_PROP_MXD_BASIC}><AddMxdPropData /></Route>
              <Route path={ROUTES.ADD_PROP_LND_BASIC}><AddLndPropData /></Route>
              <Route path={`${ROUTES.PROP_SUMMARY}/:rpid`} component={PropSummaryWithID} />
              {/* <Route path={ROUTES.ADD_PROP_RES_BASIC}><AddResPropData /></Route> */}
              <Route path={ROUTES.MAPWORKSPACE}><MapWorkspace /></Route>
              <Route path={ROUTES.DEV_TEST} exact><DevTest /></Route>
              <Route path={ROUTES.DEV_TEST2} ><DevTest2 /></Route>
              <Route path={ROUTES.DEV_TEST3} ><DevTest3 /></Route>
              <Route path={ROUTES.DEV_TEST4} ><DevTest4 /></Route>
              <Route path={ROUTES.DEV_TEST5} ><DevTest5 /></Route>
              <Route path={ROUTES.DEV_TEST6} ><DevTest6 /></Route>
              <Route path={ROUTES.HOME} exact><Home /></Route>
              <Route path={ROUTES.NOT_FOUND}><NotFound /></Route>
              <Route path={'*'}><NotFound /></Route>
            </Switch>
          </NewPropContextProvider>
        </Main>
      </Suspense>
      <FooterContainer />
    </div>
  )
}

export default App