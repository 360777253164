
/* Function: trimGpsCoords(point, len)                     */
/* Returns array [lon, lat] trimed to 'len' decimal places */
/*                                                         */
export function trimGpsCoords(gpsPointArray, decLen = 5) {
  //console.log("GPS-POINT_ARRAY: ",gpsPointArray)
  let _gpsPointArray = []
  _gpsPointArray[0] = gpsPointArray[0].toFixed(decLen)
  _gpsPointArray[1] = gpsPointArray[1].toFixed(decLen)
  // console.log("_gpsPointArray: ", _gpsPointArray)
  // console.log("Len: ", len)
  return _gpsPointArray
}

// function takes a GeoJSON Point and returns [lng,lat] array
export function geoJsonToLngLat(GeoJSONpt) {
  return GeoJSONpt.geometry.coordinates;
}