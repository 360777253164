// Longitude, Latitude

// TODO: 
//      Change these to standard GoeJSON points with information fields.
//      Need a helper function which will extract lat/lon from a GeoJSON 
//      point and place it into an array like below
// LNG / LAT

export const BAHAMAS =  {type: 'feature', geometry: {type: 'Point', coordinates: [-77.345001,25.059999]}, properties: {zoom: 2} }
export const DEFAULT =  {type: 'feature', geometry: {type: 'Point', coordinates: [-97.903982, 43.020403]},  properties: {zoom: 2}}
export const MALTA =    {type: 'feature', geometry: {type: 'Point', coordinates: [14.442859841676153, 35.89039845180393]},  properties: {zoom: 2}}
export const ZEROZERO = {type: 'feature', geometry: {type: 'Point', coordinates: [0,0]},  properties: {zoom: 0}}


// Need to include a centering point for every defined region on the map and for each country which can be set in user preferences
/*
   const AFNRT =  0;  // North Africa Region
    const AFSUB =  1;  // Sub-Saharan Africa
    const AMCNT =  2;  // America - Central
    const AMNOR =  3;  // America - North
    const AMSTH =  4;  // America - South
    const ANTAR =  5;  // Antarctic
    const ASEST =  6;  // Asia - East
    const ASWCN =  7;  // Asia - Central & West
    const ASNRT =  8;  // Asia - North
    const ASSSE =  9;  // Asia - South & Southeast
    const CARIB = 10;  // Carribean Islands
    const EURPE = 11;  // Europe
    const OCEAN = 12;  // Oceania
    const OTHER = 13;  // OTHER
*/