import React from 'react'
import {ModalBackground, Modal, Container, Wrapper, Button, Button2, Title, Text, OkBtn, CancelBtn, Radio, RadioBtn, RadioLabel, FunctionGroup} from './styles/dialogBox'


export default function DialogBox({ showModal=false, onClose, children, title, ...restProps}) {
    return <ModalBackground showModal={showModal}>
                <Modal>
                    <Container {...restProps}>
                        
                        {children}
                        
                    </Container>
                </Modal>
           </ModalBackground>
}

DialogBox.Wrapper = function DialogBoxWrapper({children, ...restProps}) {
    return <Wrapper {...restProps}>{children}</Wrapper>
}

DialogBox.Button = function DialogBoxButton({onClose, children, ...restProps}) {
    return <Button type='button' onClick={onClose} {...restProps}>{children}</Button>
}

DialogBox.Button2 = function DialogBoxButton2({onClick, children, ...restProps}) {
    return <Button2 onClick={onClick} {...restProps}>{children}</Button2>
}

DialogBox.CancelBtn = function DialogBoxCancelBtn({children, ...restProps}) {
    return <CancelBtn {...restProps}>{children}</CancelBtn>
}

DialogBox.OkBtn = function DialogBoxOkBtn({children, ...restProps}) {
    return <OkBtn {...restProps}>{children}</OkBtn>
}

DialogBox.Title = function DialogBoxText({children, ...restProps}){
    return <Title {...restProps}>{children}</Title>
}

DialogBox.Text = function DialogBoxText({children, ...restProps}) {
    return <Text {...restProps}>{children}</Text>
}

DialogBox.ModalBackground = function DialogBoxModalBackground({showModal, children, ...restProps}){
    return <ModalBackground showModal={showModal} {...restProps}>{children}</ModalBackground>
}

DialogBox.Modal = function DialogBoxModal({children, ...restProps}) {
    return <Modal>{children}</Modal>
}

DialogBox.FunctionGroup = function DialogBoxModalFunctionGroup({children, ...restProps}) {
    return <FunctionGroup {...restProps}>{children}</FunctionGroup>
}

DialogBox.Radio = function DialogBoxRadio({ id,name,value,checked,onChange, children, ...restProps}) {
    return( <Radio>
                <RadioBtn id={id} name={name} value={value} checked={checked} onChange={onChange} {...restProps}/>
                <RadioLabel htmlFor={id}>{children}</RadioLabel>
            </Radio> 
    )
}

