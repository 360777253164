import React from 'react'
import './styles/mapPopupMedium.css'


export default function PopupHtmlString(thumbnail, geoPoint, geoPointCoords, mouseEnter) {
  console.log("POPUP FIELDS: ", geoPoint)

  let popupStr;
  if (geoPoint.properties.addr) {

    const { addr: { house_number: num, road: street1, state_code: state, city, town, postcode: postal } } = geoPoint.properties

    popupStr = `
      <div onclick="${mouseEnter}">
        <img class='popupImg' src="${thumbnail}" alt="Building" />
        <a class="popup-link" href="/pages/${geoPoint.properties.RPID}" alt="Building" target="_blank"> <h3>${geoPoint.properties.title}</h3></a>
        <p>${num || ''} ${street1 || ''}</p>
        <p>${city || town || ''}, ${state || ''} ${postal || ''}</p>
        
        <!-- < p > <strong>LAT: </strong>${geoPointCoords[1]}</p > <p><strong>LON: </strong>${geoPointCoords[0]}</p> -->
      </div > `
  } else if (geoPoint.properties.description) {
    // else check for description property present
    const { description } = geoPoint.properties
    popupStr = `
    < div >
    <p>${description}</p>
      </div > `
  } else {
    popupStr = '<p>No details available</p>'
  }
  return popupStr
}