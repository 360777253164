import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react'
import { sysConfig } from '../../config'
//import { Link } from 'react-router-dom'
import { trimGpsCoords } from '../../helpers/geo-helpers'
import * as MAPPINGMODES from '../../constants/geocodeDefaultStructs'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import './styles/map.css'
import { MapPopupMedium } from '../index.js'
import {MARKER_HEIGHT_INACTIVE, MARKER_WIDTH_INACTIVE, MARKER_HEIGHT_ACTIVE, MARKER_WIDTH_ACTIVE} from '../../constants/sysConfig'




// FIX for the MapBox js gl WebWorker parsing error:
// 03825baa-6eb6-4734-849a-964eaeabbe56:1 An error occurred while parsing the WebWorker bundle. 
// This is most likely due to improper transpilation by Babel; please see https://docs.mapbox.com/mapbox-gl-js/api/#transpiling-v2
// Fix at: https://github.com/mapbox/mapbox-gl-js/issues/10173
// self.onerror @ 03825baa-6eb6-4734-849a-964eaeabbe56:1

//  WORKS in production build, but not dev
if(process.env.NODE_ENV==='production') {
  // eslint-disable-next-line import/no-webpack-loader-syntax
  mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
}

mapboxgl.accessToken = sysConfig.REACT_APP_MAPBOX_TOKEN

// TODO: Change mapFeatures to GeoJSON Points w/ information fields
// mapFeatures = [ {lng: -86, lat: 30} ]  Array of point objects


const MAPSTYLES = ['mapbox://styles/mapbox/satellite-streets-v11?optimize=true', 'mapbox://styles/mapbox/streets-v11?optimize=true']

export function DisplayResultsMap4({
  mapFeatures = [{ type: 'feature', geometry: { type: 'Point', coordinates: [null, null] }, properties: { zoom: 0, propType: 'COM'  }}],
  center, // = [-30,30],
  zoom = 5,
  zoomToLastPin = false,
  setGpsPos,
  setLastMarker,
  setPinDropPos,
  switchMap = false,
  mode = MAPPINGMODES.ADDRMODE,
  NAVCTRL,
  h = 200,
  w = 200,
  bdr = '7px solid gray',
  br = 25,
  DEBUG = false,
  pinDim = { width: '45px', height: '45px' },
  recenterAndZoom = false,
  currentMapBounds,
  markerZoom ={id:null, selected:null}
}) {
  const [mapStyle, setMapStyle] = useState(MAPSTYLES[1])

  const boundsDistance = useRef([0, 0])


  // exportGpsPos is [lng, lat]
  //const [exportGpsPos, setExportGpsPos] = useState(center)

  // mapCenterCoords is [lng, lat] so is center.
  const [mapCenterCoords, setMapCenterCoords] = useState(center || [0,40])

  // ContextCoords is [x, y] screen coords - Not used at present
//  const [contextCoords, setContextCoords] = useState([0, 0])

  // geoPoints is an array of map features: [ {type:'feature, geometry:{ type: 'point',coordinates:[lng,lat]}, properties:{zoom: 0}} ]
  const [geoPoints, setGeoPoints] = useState([{ type: 'feature', geometry: { type: 'Point', coordinates: [null, null] }, properties: { zoom: 0, propType: 'COM'} }])

  const [pointSetFlag, setPointSetFlag] = useState(false)

  const [markerList, setMarkerList] = useState([])

//  const [updateGeoPoints, setUpdateGeoPoints] = useState(false)

  // To handle mapSwitch initial glitches, 'initialized' is set as the last step of map instantiation
  const [initialized, setInitialized] = useState(false)
  const [mapSwitch, setMapSwitch] = useState(false)

  const [curZoomLevel, setCurZoomLevel] = useState(0.15)
  const [curMapCenter, setCurMapCenter] = useState(center || [0,40])

  //const [_pinDropPos, _setPinDropPos] = useState({ gps: [null, null], zoom })
  //const [_pinDropPos, _setPinDropPos] = useState({gps:center, zoom})

  const [zoomToPin, setZoomToPin] = useState(false)
  const [reCenterAndZoom, setReCenterAndZoom] = useState(false)

  //const [initialLoad, setInitialLoad] = useState(true)

  // Hold the current map bounds on Zoom or Move
  const [curBounds, setCurBounds] = useState({_ne:{lng:179.9990,lat:90}, _sw:{lng:-179.9990, lat:-90, type:'zoom', zoom:0}})

  const [markerRPIDList, setMarkerRPIDList] = useState([])

  const [stages, setStages] = useState([])

  const [zoomLevel, setZoomLevel] = useState([0.15, 0.15])  // Z0 --> Z1


  const mapContainer = useRef()
  const map = useRef()
  const isLoading = useRef(false)
  // const mapCanvas = useRef;



  useEffect(()=>{
    // Export map bounds whenever curBounds changes AND the zoom level indicates a "big enough" change
    if(curBounds.type==='zoom') {
    
      const mapZoom = map.current.getZoom()
      const Zc_Z1_ratio = Math.abs(mapZoom / zoomLevel[1])
      console.log("%cCurrentZoomLevel:","color:blueviolet", mapZoom, " ZL-0:",zoomLevel[0], " ZL-1:",zoomLevel[1], " RATIO: ", Zc_Z1_ratio)
      console.log("CurBounds: ", curBounds)

      if(    Zc_Z1_ratio < 0.95 ||  Zc_Z1_ratio > 1 ) {
        currentMapBounds(curBounds)
        setZoomLevel(zoomLevel => [mapZoom, zoomLevel[0]])  //DO NOT update on every zoom query, only on those where a GET was made to the backend
      }
      setZoomLevel(zoomLevel => [mapZoom, zoomLevel[0]])  //Update on every zoom query

    } else {
      currentMapBounds(curBounds)
    }
    
  },[curBounds])





  useEffect(() => {
    console.log("CENTER: ", center)
  }, [])

  function mouseEnter() {
    console.log("MOUSE ENTER")
  }


  function clearMarkers() {
    markerList.forEach(mark => {let key = mark._element.key; mark._element.removeListener("mouseover",()=>{console.log(`Hovering ${key}`)}); mark.remove()})
    setMarkerList([])
    //setUpdateGeoPoints(false)
    //setInitialLoad(true)
  }

  // TODO: Write the Context Menu handler
  const userContextMenu = (e) => {
    e.preventDefault()
    const { pageX, pageY } = e
    //setContextCoords([pageX, pageY])
    if (DEBUG) {
      console.log(`ContextMenu right - click: [x: ${pageX}, y: ${pageY}]`)
    }
  }

  //
  // SWITCH MAP STYLE Handling
  // 
  // 
  //useLayoutEffect(()=>{ setMapSwitch(false) },[])

  
  /////  SWITCH MAP HANDLING CODE   //////////////////////////////////////////////////////
  
  useEffect(() => {
    // Toggle the map style on signal. switchMap acts as a pulse to kickoff the switch.
    console.log("MapSwitch: initialized: ", initialized)
    if (initialized) {
      setMapSwitch(true)  //Set mapSwitch flag here. 
      setCurZoomLevel(map.current.getZoom())
      // console.log("setCurZoomLevel: ", map.current.getZoom())
      setCurMapCenter(map.current.getCenter())
      // console.log("CENTER: ", map.current.getCenter())
      // Set the current map style
      
      //setMapStyle(switchMap)// ? MAPSTYLES[1] : MAPSTYLES[0] )

      // Switch the actual map
      // console.log("Start setStyle()....")
      map.current.setStyle(switchMap ? MAPSTYLES[0] : MAPSTYLES[1])
      // console.log("End setStyle()....")
      map.current.setCenter(curMapCenter)
      //map.current.setZoom( curZoomLevel )

      // Do NOT allow markers to redraw on a map switch:
      setPointSetFlag(true)
    }
  }, [switchMap])

////////////////////////////////////////////////////////////////////////////////////////////


  // State: ZoomToLastPin
  useEffect(() => {
    setZoomToPin(true)
  }, [zoomToLastPin])

  // State: SetReCenterAndZoom - one shot
  useEffect(() => {
    console.log("%cSetRecenterAndZoom set", "color:fuchsia")
    setReCenterAndZoom(true)
  }, [recenterAndZoom])




  // HANDLE MAP FEATURE ARRAY UPDATES - eg. markers ////////////////////////
  //
  useEffect(() => {
    console.log("CHANGE IN MAP FEATURES ARRAY")
    //console.log("MAP: setGeoPoints")

    // Allow map to redraw new pins on mapFeature changes
    //setPointSetFlag(false)

    // Map Features is an array of markers passed into this renderer
    // copy to internal state provider
    setGeoPoints(mapFeatures)

    // unset the pointSetFlag - Enables MapFeature processing in the marker pipeline
    setPointSetFlag(false)

    // Save the current zoom level for the redraw
    setCurZoomLevel(map.current.getZoom())

    // clear current markers on a change in mapFeatures
    // clearMarkers()

  }, [mapFeatures])






  //  CREATE MARKER ARRAY /////////////////////////////////////////////////////////////////////////////////////////
  //  MARKERS 
  useEffect(() => {
    
    // This code is run whenever geoPoints is updated (a new list on the input) or the mapStyle switches 

    // Map Features contains an Array of GeoJSON points
    //
    console.log("map- mapFeatures: ", mapFeatures)
    console.log("geoPoints: ", geoPoints && geoPoints)
    console.log("pointSetFlag: ", pointSetFlag)

    
    // Allow the mapFeatures array to be turned into a list of markers if:
    // 1) This is NOT a map switch. Keep the current list of markers
    // 2) geoPoints is valid on the input, and
    // 3) geoPoints array contains one or more entries
    // 4) the pointSet Flag is false, so we're not currently in the middle of processing a new list of markers
    // 5) the first geoPontinas a valid coordinate in the longitude position (ARBIITRARY CHOICE) - can only process geoPoints with valid Lng/Lat pairs. Otherwise discard.
    console.log("%cHere-1", "color:darkgreen")
    if (!mapSwitch && geoPoints && geoPoints.length > 0 && !pointSetFlag && geoPoints[0].geometry.coordinates[0] !== null) {  //&& !updateGeoPoints   !recenterAndZoom &&
      // set processing flag - (used to be a single point that was set)
      setPointSetFlag(true)
      console.log("%cHere-2", "color:darkgreen")

      // New flag - is processing a geoPoint set - stored in a ref to survive refreshes
      isLoading.current = true

      console.log("%cGEOPOINTS: ", "color:fuchsia", geoPoints, "ArrayLen: ", geoPoints.length)

      //Prevent markerList from adding markers which alread exist. Still doesn't help with reducing the number of GET calls to the db on bounds change.
      // But now we've reducing screen flicker since the entire Marker List is not being cleared and recreated on each response to GET. Problem is that the marker
      // list has the definite potential to get VERY large! 
      
      //const markerRPIDList = markerList.map( marker => marker._element.key) // Speed this process up by storing this list. Upate it at the same time as the marker list.
      

      console.log("MarkerPIDs: ", markerRPIDList)
      const newGeoPoints = geoPoints.filter(pt => markerRPIDList.indexOf(pt.properties.RPID)===-1)
      console.log("NewGeoPoints: ", newGeoPoints)

      // Create an ARRAY of markers........
      newGeoPoints.forEach((geoPoint) => {
        console.log("GEOPOINT-COORDS", geoPoint.geometry.coordinates)

        // Capture the GeoJSON Point data, icon data and thumbnail
        const geoPointCoords = geoPoint.geometry.coordinates
        const geoPointIconSize = geoPoint.properties.iconSize || [MARKER_WIDTH_INACTIVE, MARKER_HEIGHT_INACTIVE]  //TODO: Change default size to pinDim dimensions
        const ptPropType = geoPoint.properties.propType
        const thumbnail = geoPoint.properties.image
        const rpid = geoPoint.properties.RPID

        // Create a marker element - typically a div
        let el = document.createElement('div')
        el.addEventListener("mouseenter",()=>{
            console.log(`Hovering ${rpid}`)
            el.style.height=`${MARKER_HEIGHT_ACTIVE}px`
            el.style.width=`${MARKER_WIDTH_ACTIVE}px`
          })
        el.addEventListener("mouseleave",()=> {
          el.style.height=`${MARKER_HEIGHT_INACTIVE}px`
          el.style.width=`${MARKER_WIDTH_INACTIVE}px`
        })

        switch( ptPropType ) {
          case 'COM' : { el.className = 'mapbox-marker-com'; break; }
          case 'RES' : { el.className = 'mapbox-marker-res'; break; }
          case 'LND' : { el.className = 'mapbox-marker-land'; break; }
          case 'MIX' : { el.className = 'mapbox-marker-mix'; break; }
          case 'VAC' : { el.className = 'mapbox-marker-vac'; break; }
          default    : el.className = 'mapbox_marker'
        }
        
        el.style.width =  (geoPointIconSize[0] || `${MARKER_WIDTH_INACTIVE}`) + 'px' // pinDim.width) + 'px'
        el.style.height = (geoPointIconSize[1] || `${MARKER_HEIGHT_INACTIVE}`) + 'px' //pinDim.height) + 'px'
        el.key = geoPoint.properties.RPID


        // TODO: Make this a card w/ styled content
        // create a popup

        // Check for address Properties present and construct popup string....
        //  function PopupHtmlString(thumbnail, geoPoint, geoPointCoords, mouseEnter)
        //  Renamed as MapPopMedium in index.js
        //
        let popupStr = MapPopupMedium(thumbnail, geoPoint, trimGpsCoords(geoPointCoords), mouseEnter);
        const popup = new mapboxgl.Popup({ offset: 20 }).setHTML(popupStr)


        //console.log("CREATE NEW MARKER")
        ////////////////////////////////////////  CREATE MARKER ////////////////////////////////////
        //
        // Create the actual Marker and add it to the map
        // console.log("Instantiate marker at: ", point)
        //
        const marker = new mapboxgl.Marker(el, {
          anchor: 'bottom',
          draggable: geoPoint.properties.draggable,  // Pins for location by map are draggable. All others are not.
          color: 'green',
          scale: 10,
        })
          .setLngLat(geoPointCoords)   // Set its position
          .setPopup(popup)             // Bind the popup created above to marker  NOTE: COULD have used .getPopupt() instead to create a bound popup instance
          // .on('dragstart', () => {     // Event Handler for dragState event
          //   // console.log("DRAG BEGIN ------------------------------") //Nothing to do here
          // })
          // .on('dragend', () => {      // Event Handler for dragEnd event - Only required for Address by Map Location code.

          //   // When the pin is dragged and dropped, record the new GPS coordinates of
          //   // where the pin was placed and re-center the map on those coordinates.
          //   //
          //   console.log("geoPointNDX: ", geoPoint.properties.index)
          //   const geoPointNDX = geoPoint.properties.index // Not sure here....

          //   const { lng, lat } = marker.getLngLat()

          //   // console.log("DRAG END---------------- ",[lng,lat])
          //   setLastMarker({ coords: [lng, lat], zoom: map.current.getZoom() })
          //   _setPinDropPos({ gps: [lng, lat], zoom: map.current.getZoom() })
          //   setMapCenterCoords([lng, lat])
          //   map.current.flyTo({
          //     center: [lng, lat],
          //   })
          //   popup.setHTML(MapPopupMedium(geoPoint, trimGpsCoords([lng, lat])))

          //   //update lng/lat in the geoPoint array entry
          //   const modGeoPoints = geoPoints.map(gp => {
          //     return (
          //       (gp.properties.index === geoPointNDX) ?
          //         { ...gp, geometry: { coordinates: [lng, lat] }, } :  //properties:{...gp.properties, zoom: map.current.getZoom() 
          //         gp
          //     )
          //   })

          //   //console.log("Modified geoPoints: ", modGeoPoints)
          //   //setUpdateGeoPoints(true)

          //   // Now save the newly created array of Modified GeoPoints
          //   setGeoPoints(modGeoPoints)

          // })
          // .on('drag', () => {  // Only supports Address by Map Location 
          //   // TODO: Add configuration bit to disable/enable marker updating during drags
          //   const { lng, lat } = marker.getLngLat()
          //   popup.setHTML(MapPopupMedium(geoPoint, trimGpsCoords([lng, lat])))
          // })
          .addTo(map.current);  // Add the marker to the map. Done.


        // Add the marker to the markerList (a list of current markers):

        console.log("Adding Marker to MarkerList: ", marker, " mapSwitchStatus: ", mapSwitch)
        !mapSwitch && setMarkerList(markerList => [...markerList, marker])   // Does this have to be zeroed on an updated GeoPoint list?
        !mapSwitch && setMarkerRPIDList(markerRPIDList => [...markerRPIDList, marker._element.key])
        console.log("%cMARKER-LIST: ","color:fuchsia", markerList)
      })
      // Arbitrarily take the first point at present.
      // TODO: Later we'll bound the group if more than one loc is present.
      //console.log( ".setCenter To: ",geoPoints[0])
      

      // geoPoints is an array of Point objects 
      let [lng, lat] = geoPoints[0].geometry.coordinates


      // Re-center the map - only on pin drop
      // Function expects an array comprised of two values [lng,lat]. Thats it. Don't change.
      // Set the zoom to a decent zoom-in level: 18 seems good...
      // If only one point, get the zoom from it. Otherwise use arbitrary default.
      // TODO: implement bounding box for two or more points
      //       Fix centering with more than one point.
      //

      //console.log("mapSwitch: ", mapSwitch )

      //  ZOOM Handling for all cases......
      if (reCenterAndZoom) {
        console.log("ReCenterAndZoom - setZoom")
        //map.current.setZoom(zoom)
      } else if (mapSwitch) {
        console.log("CurrentZoomLevel: ", curZoomLevel)
        map.current.setZoom(curZoomLevel)  // value preserved at the start of mapSwitch
      } else if (geoPoints[0].properties.zoom && geoPoints.length === 1) {
        console.log("Marker ZOOM:", geoPoints[0].properties.zoom )
        //-> map.current.setZoom(geoPoints[0].properties.zoom)
        //map.current.setZoom( curZoomLevel )
      } else if (geoPoints.length > 1) {
        console.log("%cCurZoom: ", "color:red", curZoomLevel)
        //map.current.setZoom(curZoomLevel)
      } else { 
        console.log("Default Zoom case")
        //map.current.setZoom(7) 
      }  //7  curZoomLevel


      // Map Centering for All Cases....
      if (reCenterAndZoom) {
        console.log("ReCenterAnd Zoom - setCenter")
        map.current.setCenter(center || [0,40]);

        [lng, lat] = geoPoints[0].geometry.coordinates;

        // map.current.setZoom(zoom)
        setReCenterAndZoom(false)
      } else if (mapSwitch) {
        map.current.setCenter(curMapCenter)
      } else {
        //map.current.setCenter([lng, lat])
        //map.current.setCenter(geoPoints[0].geometry.coordinates)
      }
      //_setPinDropPos({ gps: map.current.getCenter(), zoom: map.current.getZoom() })
      //console.log("OSM-Center: ", map.current.getCenter())
      //console.log("OSM-Zoom: ", map.current.getZoom())
      //map.current.setCenter( {lng,lat})

      setMapCenterCoords([lng, lat])
      setMapSwitch(false)  // Complete the mapSwitch if this was the case for the redraw.

    } else {
      if (mapSwitch) {
        map.current.setCenter(curMapCenter)
        map.current.setZoom(curZoomLevel)
        setMapSwitch(false)  //  Make sure mapSwtich is reset to complete the cycle
      }
    }
    isLoading.current = false
    return () => { }
  }, [geoPoints, mapStyle])  // , mapStyle



  //ZoomToPin - Code runs on zoom to pon
  // useEffect(() => {
  //   console.log("Zoom to Pin: ", JSON.stringify(_pinDropPos))
  //   map.current.setCenter(_pinDropPos.gps)
  //   map.current.setZoom(Math.min(19, _pinDropPos.zoom))  // Maximum zoom set to 19. //TODO: make user configurable
  //   setMapCenterCoords(_pinDropPos.gps)
  //   setZoomToPin(false)
  // }, [zoomToPin])



  useEffect(() => {
    console.log("useEffect->reCenter and Zoom: ",reCenterAndZoom)
    if (reCenterAndZoom) {
      console.log("%cCENTER: ", "color:red", center)
      console.log("%cZOOM: ", "color:red", zoom)
      //map.current.setCenter(center)
      //setMapCenterCoords(center)
      // setReCenterAndZoom(false)
      setCurMapCenter(center)
      //map.current.setZoom(zoom)
      map.current.flyTo({
        center: mapCenterCoords,
      })
    }

  }, [recenterAndZoom])


  
  // MOUSE & MAO MARKER HANDLER:
  //
  // Input:  markerZoom: {id: RPID, selected: boolean}  
  //
  //          selected: whether the card is active (mouseenter) or not (mouseleave)
  //
  // This useEffect handles mouseenter / mouseleave events for the card carousel and highlights correspondine pins on the map.
  // If the mouse moves up and down the card stack, then the code has to follow that action, but, occassionaly (actually often), 
  // mouseleave events get lost so that the corresponding map marker was left in a highlighted state. Not good. So here 
  // a two-stage pipeline is implemented on mouseenters so that the active card is pushed onto the top stage of the pipeline to be
  // highlighted and the previous entry is pushed to the second stage to be turned off. The pipeline advances whenever a new 
  // mouseenter event occurs.  The only exception to this is when the mouse leaves a card but does not immediately enter an 
  // adjacent card, rather it leaves the carousel to the map area or some other part of the screen. Then the stage-0 entry is 
  // immediately turned off and hte pipeline progreeses with a null value (bubble) entered into stage-0. This is ok because null
  // values are screened for.
  useEffect(()=> {
    if(markerZoom && markerZoom.selected ) {  

    const selectedMarker = markerList.filter( marker => marker._element.key === markerZoom.id)[0]

    if( selectedMarker ) {
      //console.log("Selected Marker: ", selectedMarker)
      //console.log("DisplayResultsMap4): SelectedMarker: ", markerZoom.id, markerZoom.selected,  selectedMarker)

      selectedMarker._element.style.height = `${MARKER_HEIGHT_ACTIVE}px`
      selectedMarker._element.style.width = `${MARKER_WIDTH_ACTIVE}px`
      
      if( stages[0] ) {
        stages[0]._element.style.height = MARKER_HEIGHT_INACTIVE + 'px'
        stages[0]._element.style.width = MARKER_WIDTH_INACTIVE +'px'
      }
      setStages(stages => [ selectedMarker, stages[0] ] )
      //console.log("Stages: ", stages[0],stages[1])
    } else { console.error("(DisplayResultsMap4): Marker ERROR: No Marker Selected") }

  } else if(stages[0] && markerZoom && !markerZoom.selected) { // Handle a mouse out event where the cursor moves from the card directly to the map
    stages[0]._element.style.height=`${MARKER_HEIGHT_INACTIVE}px`
    stages[0]._element.style.width=`${MARKER_WIDTH_INACTIVE}px`  
    setStages(stages => [ null, stages[0] ] )
  }
  },[markerZoom])




  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// CREATE THE MAP  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useLayoutEffect(() => {

    if (DEBUG) {
      console.log('mapCenterCoords : ', mapCenterCoords )
      console.log('ZOOM: :', curZoomLevel)  // zoom is an attribute to Map
    }

    console.log("%cMap Cnter Coords: ","color:green", mapCenterCoords)

    //
    //center: [lng,lat] or {lng:30, lat:30}
    if(map.current) return;  // Initialize the map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: MAPSTYLES[1],              //mapStyle,
      center: mapCenterCoords,
      zoom: curZoomLevel,
      doubleClickZoom: false,
      //renderWorldCopies: false
      minZoom: 1.10,
      //maxBounds: [[-179.999999, -78], [179.999999, 82]]
      // maxBounds: [[0, -83], [-125.9999, 85]]
      //maxBounds: [[-125.9999, -85],[0, 83] ]
    })


    if (NAVCTRL) {
      map.current.addControl(new mapboxgl.NavigationControl())
    }

    // Center the map on the coordinates of any clicked symbol from the 'symbols' layer.
    //Ok, this works....
    map.current.on('dblclick', function (e) {
      //console.log('Re-ceter map on clicked symbol++++++++++++++++++++++++')
      map.current.flyTo({
        center: [e.lngLat.lng, e.lngLat.lat],
      })
      if (setGpsPos) {
        setGpsPos([e.lngLat.lng, e.lngLat.lat])
      }
    })

    map.current.on('move', ()=>{
      clearTimeout(boundsDistance.current[0])
      boundsDistance.current[0] = setTimeout(() => {
        const zoom = map.current.getZoom()
        const curBounds = {...map.current.getBounds(), zoom, type:'move'}
        console.log("BoundsMove: ", curBounds, isLoading.current)
         setCurBounds( curBounds )  /* !isLoading.current && */
      }, 500)
    })

    map.current.on('zoom', () => {
      //console.log("Bounds: ", map.current.getBounds())
      // const bounds = map.current.getBounds()
      // const neX = bounds._ne.lng
      // const neY = bounds._ne.lat
      // const swX = bounds._sw.lng
      // const swY = bounds._sw.lat
      // const distanceSquared = Math.sqrt(Math.pow((neX - swX), 2) + Math.pow((neY - swY), 2))
      // console.log("DELTA: ", distanceSquared, " : ", boundsDistance.current[0])
      // const deltaDist = Math.abs(Math.abs(distanceSquared) - Math.abs(boundsDistance.current[0]))
      // if (deltaDist[0] - deltaDist[1] < .01)
      //   console.log("Delta-distance:", deltaDist)
      // boundsDistance.current = [boundsDistance.current[1], deltaDist]

      // if(mapCanvas.current)
      //   console.log("Box: ", mapCanvas.current.getBoundingClientRect())

      clearTimeout(boundsDistance.current[1])
      boundsDistance.current[1] = setTimeout(() => {
        const zoom = map.current.getZoom()
        const curBounds = {...map.current.getBounds(), zoom, type:'zoom'}
        console.log("BoundsZoom: ", curBounds)
         setCurBounds( curBounds )  /* !pointSetFlag && !isLoading.current &&  */
      }, 270)

    })

    // This fxn was to drop a pin on the map. Currently is does nothing in this Map component
    // except log out the GPS coordinates of the mouse click.
    map.current.on('click', (e) => {
      const { ctrlKey } = e.originalEvent;
      //console.log("MapClick: ", e)

      // var features = map.current.queryRenderedFeatures(e.point, {});
      // console.log("FEATURES: ", features)
      
      if (setGpsPos) {
        console.log("Map-OnClick: ", [e.lngLat.lng, e.lngLat.lat])
        setGpsPos([e.lngLat.lng, e.lngLat.lat]) // Call interface function to export mouse click gps coordinates
        // if (ctrlKey && mode === MAPPINGMODES.MAPMODE) {  // TODO:  clean this up
        //   _setPinDropPos({ gps: [e.lngLat.lng, e.lngLat.lat], zoom: map.current.getZoom() })
        //   setPinDropPos( { gps: [e.lngLat.lng, e.lngLat.lat], zoom: map.current.getZoom() })
        // }
      }
    })

    //setInitialized(true)

    map.current.on('load', () => {
      console.log("MAP ON LOAD called =======================")
      //mapCanvas.current = map.current.getCanvasContainer();  // returns the coordinates and screen size of the map canavas on the screen.
      setInitialized(true)
    })

    return () => { map.current.remove() }  // Remove the map when component unmounts
  }, [])
  

  //console.log("CurrentmapCenterCoords : ", mapCenterCoords)
  console.log("markerList Length: ", markerList.length)
  // console.log("%cMARKER-LIST: ","color:fuchsia", markerList)

  return (
    <div
      onContextMenu={userContextMenu}
      ref={mapContainer}
      style={{
        height: h,
        width: w,
        borderRadius: br,
        border: bdr,
        backgroundColor: 'navy',
      }}
    />
  )
}