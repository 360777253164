/* 
	Copyright 2016-2021 by Downing Hopkins. All rights reserved.
	This code is proprietary and trade secret and may not
	be copied, stored or transmitted without the written
	permision of the author.
*/
/*

    9/30/19:  Imported from former Ribbit Angular project and re-coded for React

    9/18/16: Added whole world list to module. Included ISO-3166 3-letter codes.
             Changed field names to single/double letters to save data costs.
             Updated REGION constants to ISO list of recognized regions.
    
    9/9/16: Divided services into new files. Created servicesCountryCode.js to
    hold all country code details
*/


//.service('countryCodeSvc',[function(){
	
    
    // COUNTRY REGION CONSTANTS
    const AFNRT =  0;  // North Africa Region
    const AFSUB =  1;  // Sub-Saharan Africa
    const AMCNT =  2;  // America - Central
    const AMNOR =  3;  // America - North
    const AMSTH =  4;  // America - South
    const ANTAR =  5;  // Antarctic
    const ASEST =  6;  // Asia - East
    const ASWCN =  7;  // Asia - Central & West
    const ASNRT =  8;  // Asia - North
    const ASSSE =  9;  // Asia - South & Southeast
    const CARIB = 10;  // Carribean Islands
    const EURPE = 11;  // Europe
    const OCEAN = 12;  // Oceania
    const OTHER = 13;  // OTHER
	
    
    /* nm: name  d: description ab: abbreviation cur: currency  rg: region at: addressType */
    
    // DEPRECATED LIST - replaced by full ISO list of countries. Useful for testing still....
/*    
const countriesTest = [
        {nm: 'Antigua',       ab:'ANT',  rg: 0, at: 0 },
		{nm: 'United States', ab:'USA',  rg: 0, at: 0 },
        {nm: 'Bahamas',       ab:'BHM',  rg: 0, at: 0 },
        {nm: 'Belgium',       ab:'BLG',  rg: 0, at: 0 },
        {nm: 'Canada',        ab:'CAN',  rg: 0, at: 0 },
        {nm: 'Denmark',       ab:'DMK',  rg: 0, at: 0 },
		{nm: 'England',  	  ab:'ENG',  rg: 0, at: 0 },		
		{nm: 'France',        ab:'FRN',  rg: 0, at: 0 },
		{nm: 'Germany',       ab:'GER',  rg: 0, at: 0 },		
		{nm: 'Italy',         ab:'ITA',  rg: 0, at: 0 },	
        {nm: 'Mexico',        ab:'MEX',  rg: 0, at: 0 },
		{nm: 'Portugal',      ab:'POR',  rg: 0, at: 0 },
		{nm: 'Poland',        ab:'POL',  rg: 0, at: 0 },		
		{nm: 'Russia',        ab:'RUS',  rg: 0, at: 0 },
        {nm: 'Spain',         ab:'SPN',  rg: 0, at: 0 },
		{nm: 'Switzerland',   ab:'SWI',  rg: 0, at: 0 }
	];
*/

const countryCodes3Ltr = [
        {nm: '-- Select a country --',            d:'-- Select a country --',     ab:'---',  cur:'---', rg: OTHER,  at: 0 },
        {nm: 'Afghanistan',                       d: 'Afghanistan',               ab:'AFG',  cur:'AFN', rg: ASWCN,  at: 0 },
        {nm: 'Åland Islands',                     d: 'Åland Islands',             ab:'ALA',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Albania',                           d: 'Albania',                   ab:'ALB',  cur:'ALL', rg: EURPE,  at: 2 },
        {nm: 'Algeria',                           d: 'Algeria',                   ab:'DZA',  cur:'DZD', rg: AFNRT,  at: 0 },
        {nm: 'American Samoa',                    d: 'American Samoa',            ab:'ASM',  cur:'USD', rg: OCEAN,  at: 0 },
        {nm: 'Andorra',                           d: 'Andorra',                   ab:'AND',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Angola',                            d: 'Angola',                    ab:'AGO',  cur:'AOA', rg: AFSUB,  at: 0 },
        {nm: 'Anguilla',                          d: 'Anguilla',                  ab:'AIA',  cur:'XCD', rg: CARIB,  at: 0 },
        {nm: 'Antarctica',                        d: 'Antarctica',                ab:'ATA',  cur:'---', rg: ANTAR,  at: 0 },
        {nm: 'Antigua and Barbuda',               d: 'Antigua and Barbuda',       ab:'ATG',  cur:'XCD', rg: CARIB,  at: 0 },
        {nm: 'Argentina',                         d: 'Argentina',                 ab:'ARG',  cur:'ARS', rg: AMSTH,  at: 0 },
        {nm: 'Armenia',                           d: 'Armenia',                   ab:'ARM',  cur:'AMD', rg: ASWCN,  at: 0 },
        {nm: 'Aruba',                             d: 'Aruba',                     ab:'ABW',  cur:'AWG', rg: CARIB,  at: 0 },
        {nm: 'Australia',  d: 'Australia [includes the island groups of Ashmore-Cartier, Lord Howe and Macquarie]',
                                                                                  ab:'AUS',  cur:'AUD', rg: OCEAN,  at: 0 },
        {nm: 'Austria',                           d: 'Austria',                   ab:'AUT',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Azerbaijan',                        d: 'Azerbaijan',                ab:'AZE',  cur:'AZN', rg: ASWCN,  at: 0 },

        {nm: 'Bahamas',                           d: 'Bahamas',                   ab:'BHS',  cur:'BSD', rg: CARIB,  at: 0 },
        {nm: 'Bahrain',                           d: 'Bahrain',                   ab:'BHR',  cur:'BHD', rg: ASWCN,  at: 0 },
        {nm: 'Bangladesh',                        d: 'Bangladesh',                ab:'BGD',  cur:'BDT', rg: ASSSE,  at: 0 },
        {nm: 'Barbados',                          d: 'Barbados',                  ab:'BRB',  cur:'BBD', rg: CARIB,  at: 0 },
        {nm: 'Belarus',                           d: 'Belarus',                   ab:'BLR',  cur:'BYR', rg: ASNRT,  at: 0 },
        {nm: 'Belgium',                           d: 'Belgium',                   ab:'BEL',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Belize',                            d: 'Belize',                    ab:'BLZ',  cur:'BZD', rg: AMCNT,  at: 0 },
        {nm: 'Benin',                             d: 'Benin',                     ab:'BEN',  cur:'XOF', rg: AFSUB,  at: 0 },
        {nm: 'Bermuda',                           d: 'Bermuda',                   ab:'BMU',  cur:'BMD', rg: CARIB,  at: 0 },
        {nm: 'Bhutan',                            d: 'Bhutan',                    ab:'BTN',  cur:'BTN', rg: ASSSE,  at: 0 },
        {nm: 'Bolivia',                           d:' Bolivia, Plurinational State of', 
                                                                                  ab:'BOL',  cur:'BOB', rg: AMSTH,  at: 0 },
        {nm: 'Bonaire, Sint Eustatius and Saba',  d: 'Bonaire, Sint Eustatius and Saba', 
                                                                                  ab:'BES',  CUR:'USD', rg: CARIB,  at: 0 },
        {nm: 'Bosnia and Herzegovina',            d: 'Bosnia and Herzegovina',    ab:'BIH',  cur:'BAM', rg: EURPE,  at: 2 },
        {nm: 'Botswana',                          d: 'Botswana',                  ab:'BWA',  cur:'BWP', rg: AFSUB,  at: 0 },
        {nm: 'Bouvet Island',                     d: 'Bouvet Island',             ab:'BVT',  cur:'NOK', rg: ANTAR,  at: 0 },
        {nm: 'Brazil',                            d: 'Brazil',                    ab:'BRA',  cur:'BRL', rg: AMSTH,  at: 0 },
        {nm: 'British Indian Ocean Territory',    d: 'British Indian Ocean Territory [includes the Chagos Archipelago]', 
                                                                                  ab:'IOT',  cur:'GBP', rg: ASSSE,  at: 0 },
        {nm: 'Brunei Darussalam',                 d: 'Brunei Darussalam',         ab:'BRN',  cur:'BND', rg: ASSSE,  at: 0 },
        {nm: 'Bulgaria',                          d: 'Bulgaria',                  ab:'BGR',  cur:'BGN', rg: EURPE,  at: 2 },
        {nm: 'Burkina Faso',                      d: 'Burkina Faso',              ab:'BFA',  cur:'XOF', rg: AFSUB,  at: 0 },
        {nm: 'Burundi',                           d: 'Burundi',                   ab:'BDI',  cur:'BIF', rg: AFSUB,  at: 0 },

        {nm: 'Cambodia',                          d: 'Cambodia',                  ab:'KHM',  cur:'KHR', rg: ASSSE,  at: 0 },
        {nm: 'Cameroon',                          d: 'Cameroon',                  ab:'CMR',  cur:'XAF', rg: AFSUB,  at: 0 },
        {nm: 'Canada',                            d: 'Canada',                    ab:'CDN',  cur:'CAD', rg: AMNOR,  at: 0 },
        {nm: 'Cape Verde',                        d: 'Cape Verde',                ab:'CVI',  cur:'CVE', rg: AFSUB,  at: 0 }, /* NO ISO 3166 code listed */
        {nm: 'Cayman Islands',                    d: 'Cayman Islands',            ab:'CYM',  cur:'KYD', rg: CARIB,  at: 0 },
        {nm: 'Central African Republic',          d: 'Central African Republic',  ab:'CAF',  cur:'XAF', rg: AFSUB,  at: 0 },
        {nm: 'Chad',                              d: 'Chad',                      ab:'TCD',  cur:'XAF', rg: AFSUB,  at: 0 },
        {nm: 'Chile',                             d: 'Chile [includes Easter Island]',  
                                                                                  ab:'RCH',  cur:'CLP', rg: AMSTH,  at: 0 },
        {nm: 'China',                             d:  'China',                    ab:'CHN',  cur:'CNY', rg: ASEST,  at: 0 },
        {nm: 'Christmas Island',                  d: 'Christmas Island',          ab:'CXR',  cur:'AUD', rg: OCEAN,  at: 0 },
        {nm: 'Clipperton Island',                 d: 'Clipperton Island',         ab:'CPT',  cur:'EUR', rg: EURPE,  at: 2 },  /** FRANCE */
        {nm: 'Cocos (Keeling) Islands',           d: 'Cocos (Keeling) Islands',   ab:'CCK',  cur:'AUD', rg: OCEAN,  at: 0 },
        {nm: 'Colombia',                          d: 'Colombia',                  ab:'COL',  cur:'COP', rg: AMSTH,  at: 0 },
        {nm: 'Comoros',                           d: 'Comoros',                   ab:'COM',  cur:'KMF', rg: AFSUB,  at: 0 },
        {nm: 'Congo, Republic of',                d: 'Congo, Republic of',        ab:'COG',  cur:'XAF', rg: AFSUB,  at: 0 },
        {nm: 'Congo, Democratic Republic',        d: 'Congo, The Democratic Republic of the',  
                                                                                  ab:'COD',  cur:'CDF', rg: AFSUB,  at: 0 },
        {nm: 'Cook Islands',                      d: 'Cook Islands',              ab:'COK',  cur:'NZD', rg: OCEAN,  at: 0 },
        {nm: 'Costa Rica',                        d: 'Costa Rica',                ab:'CRI',  cur:'CRC', rg: AMCNT,  at: 0 },
        {nm: 'Côte d\'Ivoire',                    d: 'Côte d\'Ivoire',            ab:'CIV',  cur:'XOF', rg: AFSUB,  at: 0 },
        {nm: 'Croatia/Hrvatska',                  d: 'Croatia',                   ab:'HRV',  cur:'HRK', rg: EURPE,  at: 2 },
        {nm: 'Cuba',                              d: 'Cuba',                      ab:'CUB',  cur:'CUP', rg: CARIB,  at: 0 },
        {nm: 'Curaçao',                           d: 'Curaçao',                   ab:'CUW',  cur:'ANG', rg: CARIB,  at: 0 }, /** Netherland Antilles, Sint Maarten */
        {nm: 'Cyprus',                            d: 'Cyprus',                    ab:'CYP',  cur:'EUR', rg: ASWCN,  at: 0 },    /*Nortyuern Cyprus => Turkish Lira */
        {nm: 'Czech Republic',                    d: 'Czech Republic',            ab:'CZE',  cur:'CZK', rg: EURPE,  at: 2 },


        {nm: 'Denmark',                           d: 'Denmark',                   ab:'DNK',  cur:'DKK', rg: EURPE,  at: 2 },
        {nm: 'Disputed Territory',      d: 'Disputed Territory [includes the Paracel Islands and Spratly Islands]',  
                                                                                  ab:'DSP',  cur:'---',    rg: ASSSE,  at: 0 },/* NO ISO 3166 3-ltr code */
        {nm: 'Djibouti',                          d: 'Djibouti',                  ab:'DJI',  cur:'DJF', rg: AFSUB,  at: 0 },
        {nm: 'Dominica',                          d: 'Dominica',                  ab:'DMA',  cur:'XCD', rg: CARIB,  at: 0 },
        {nm: 'Dominican Republic',                d: 'Dominican Republic',        ab:'DOM',  cur:'DOP', rg: CARIB,  at: 0 },


        {nm: 'Ecuador',                           d: 'Ecuador [includes the Galápagos islands]',  
                                                                                  ab:'ECU',  cur:'USD', rg: AMSTH,  at: 0 },
        {nm: 'Egypt',                             d: 'Egypt',                     ab:'EGY',  cur:'EGP', rg: AFNRT,  at: 0 },
        {nm: 'El Salvador',                       d: 'El Salvador',               ab:'SLV',  cur:'USD', rg: AMCNT,  at: 0 },
        {nm: 'England',                           d: 'England',                   ab:'ENG',  cur:'---',    rg: EURPE,  at: 1 }, /* Not Officially a country */
        {nm: 'Equatorial Guinea',                 d: 'Equatorial Guinea [includes the islands of Annobón and Bioko]',  
                                                                                  ab:'GNQ',  cur:'XAF', rg: AFSUB,  at: 0 },
        {nm: 'Eritrea',                           d: 'Eritrea',                   ab:'ERI',  cur:'ERN', rg: AFSUB,  at: 0 },
        {nm: 'Estonia',                           d: 'Estonia',                   ab:'EST',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Ethiopia',                          d: 'Ethiopia',                  ab:'ETH',  cur:'ETB', rg: AFSUB,  at: 0 },


        {nm: 'Falkland Islands (Malvinas)',       d: 'Falkland Islands (Malvinas)',  
                                                                                  ab:'FLK',  cur:'FKP', rg: AMSTH,  at: 0 },
        {nm: 'Faroe Islands',                     d: 'Faroe Islands',             ab:'FRO',  cur:'FOK', rg: EURPE,  at: 2 },
        {nm: 'Fiji',                              d: 'Fiji',                      ab:'FJI',  cur:'FJD', rg: OCEAN,  at: 0 },
        {nm: 'Finland',                           d: 'Finland [excludes the Åland Islands]',  
                                                                                  ab:'FIN',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'France',                            d: 'France [includes Clipperton Island in the eastern Pacific Ocean]',  
                                                                                  ab:'FRA',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'French Guiana',                     d: 'French Guiana',             ab:'GUF',  cur:'EUR', rg: AMSTH,  at: 0 },
        {nm: 'French Polynesia',                  d: 'French Polynesia [includes the island groups of the Marquesas, Society, Tuamotu and Tubai]',
                                                                                  ab:'PYF',  cur:'XPF', rg: OCEAN,  at: 0 },
        {nm: 'French Southern Territories',       d: 'French Southern Territories [includes the Amsterdam-St Paul, Crozet, Kerguelen and Mozambique Channel island groups]',
                                                                                  ab:'ATF',  cur:'EUR', rg: ANTAR,  at: 0 },


        {nm: 'Gabon',                             d: 'Gabon',                     ab:'GAB:', cur:'XAF', rg: AFSUB,  at: 0 },
        {nm: 'Gambia',                            d: 'Gambia',                    ab:'GMB',  cur:'GMD', rg: AFSUB,  at: 0 },
        {nm: 'Georgia',                           d: 'Georgia',                   ab:'GEO',  cur:'GEL', rg: ASWCN,  at: 0 },
        {nm: 'Germany',                           d: 'Germany',                   ab:'DEU',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Ghana',                             d: 'Ghana',                     ab:'GHA',  cur:'GHC', rg: AFSUB,  at: 0 },
        {nm: 'Gibraltar',                         d: 'Gibraltar',                 ab:'GIB',  cur:'GIP', rg: EURPE,  at: 2 },
        {nm: 'Greece',                            d: 'Greece',                    ab:'GRC',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Greenland',                         d: 'Greenland',                 ab:'GRL',  cur:'DKK', rg: EURPE,  at: 2 },
        {nm: 'Grenada',                           d: 'Grenada',                   ab:'GRD',  cur:'XCD', rg: CARIB,  at: 0 },
        {nm: 'Guadeloupe',                        d: 'Guadeloupe',                ab:'GLP',  cur:'EUR', rg: CARIB,  at: 0 },
        {nm: 'Guam',                              d: 'Guam',                      ab:'GUM',  cur:'USD', rg: OCEAN,  at: 0 },
        {nm: 'Guatemala',                         d: 'Guatemala',                 ab:'GTM',  cur:'GTQ', rg: AMCNT,  at: 0 },
        {nm: 'Guernsey',                          d: 'Guernsey',                  ab:'GGY',  cur:'GGP', rg: EURPE,  at: 2 },
        {nm: 'Guinea',                            d: 'Guinea',                    ab:'GIN',  cur:'GNF', rg: AFSUB,  at: 0 },
        {nm: 'Guinea-Bissau',                     d: 'Guinea-Bissau',             ab:'GNB',  cur:'GWP', rg: AFSUB,  at: 0 },
        {nm: 'Guyana',                            d: 'Guyana',                    ab:'GUY',  cur:'GYD', rg: AMSTH,  at: 0 },


        {nm: 'Haiti',                             d: 'Haiti',                     ab:'HTI',  cur:'USD', rg: CARIB,  at: 0 },
        {nm: 'Heard Island and McDonald Islands', d: 'Heard Island and McDonald Islands',  
                                                                                  ab:'HMD',  cur:'AUD',    rg: ANTAR,  at: 0 },
        {nm: 'Holy See (Vatican City State)',     d: 'Holy See (Vatican City State)',  
                                                                                  ab:'VAT',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Honduras',                          d: 'Honduras',                  ab:'HND',  cur:'HNL', rg: AMCNT,  at: 0 },
        {nm: 'Hong Kong',                         d: 'Hong Kong',                 ab:'HKG',  cur:'HKD', rg: ASEST,  at: 0 },
        {nm: 'Hungary',                           d: 'Hungary',                   ab:'HUN',  cur:'HUF', rg: EURPE,  at: 2 },


        {nm: 'Iceland',                           d: 'Iceland',                   ab:'ISL',  cur:'ISK', rg: EURPE,  at: 2 },
        {nm: 'India',                       d: 'India [includes the Andaman, Laccadive and Nicobar island groups]',  
                                                                                  ab:'IND',  cur:'INR', rg: ASSSE,  at: 0 },
        {nm: 'Indonesia',                         d: 'Indonesia',                 ab:'IDN',  cur:'IDR', rg: ASSSE,  at: 0 },
        {nm: 'Iran',                              d: 'Iran, Islamic Republic of', ab:'IRN',  cur:'IRR', rg: ASWCN,  at: 0 },
        {nm: 'Iraq',                              d: 'Iraq',                      ab:'IRQ',  cur:'IQD', rg: ASWCN,  at: 0 },
        {nm: 'Ireland',                           d: 'Ireland',                   ab:'IRL',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Isle of Man',                       d: 'Isle of Man',               ab:'IMN',  cur:'GBP',   rg: EURPE,  at: 2 },
        {nm: 'Israel',                            d: 'Israel',                    ab:'ISR',  cur:'ILS', rg: ASWCN,  at: 0 },
        {nm: 'Italy',                             d: 'Italy',                     ab:'ITA',  cur:'EUR', rg: EURPE,  at: 2 },

        {nm: 'Jamaica',                           d: 'Jamaica',                   ab:'JAM',  cur:'JMD', rg: CARIB,  at: 0 },
        {nm: 'Japan',                             d: 'Japan',                     ab:'JPN',  cur:'JPY', rg: ASEST,  at: 0 },
        {nm: 'Jersey',                            d: 'Jersey',                    ab:'JEY',  cur:'GBP', rg: EURPE,  at: 2 },
        {nm: 'Jordan',                            d: 'Jordan',                    ab:'JOR',  cur:'JOD', rg: ASWCN,  at: 0 },

        {nm: 'Kazakhstan',                        d: 'Kazakhstan',                ab:'KAZ',  cur:'KZT', rg: ASWCN,  at: 0 },
        {nm: 'Kenya',                             d: 'Kenya',                     ab:'KEN',  cur:'KES', rg: AFSUB,  at: 0 },
        {nm: 'Kiribati',             d: 'Kiribati [includes the Gilbert, Kiribati Line and Phoenix island groups]',  
                                                                                  ab:'KIR',  cur:'AUD', rg: OCEAN,  at: 0 },
        {nm: 'North Korea',                       d: 'Korea, Democratic People\'s Republic of',  
                                                                                  ab:'PRK',  cur:'KPW', rg: ASEST,  at: 0 },
        {nm: 'South Korea',                       d: 'Korea, Republic of',        ab:'KOR',  cur:'KRW', rg: ASEST,  at: 0 },
        {nm: 'Kuwait',                            d: 'Kuwait',                    ab:'KWT',  cur:'KWD', rg: ASWCN,  at: 0 },
        {nm: 'Kyrgyzstan',                        d: 'Kyrgyzstan',                ab:'KGZ',  cur:'KGS', rg: ASWCN,  at: 0 },

        {nm: 'Laos ',                             d: 'Lao People\'s Democratic Republic',  
                                                                                  ab:'LAO',  cur:'LAK', rg: ASSSE,  at: 0 },
        {nm: 'Latvia',                            d: 'Latvia',                    ab:'LVA',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Lebanon',                           d: 'Lebanon',                   ab:'LBN',  cur:'LBP', rg: ASWCN,  at: 0 },
        {nm: 'Lesotho',                           d: 'Lesotho',                   ab:'LSO',  cur:'LSL', rg: AFSUB,  at: 0 },
        {nm: 'Lesotho',                           d: 'Lesotho',                   ab:'LBR',  cur:'LSL', rg: AFSUB,  at: 0 },
        {nm: 'Libya',                             d: 'Libya',                     ab:'LBY',  cur:'LYD', rg: AFNRT,  at: 0 },
        {nm: 'Liechtenstein',                     d: 'Liechtenstein',             ab:'LIE',  cur:'CHF', rg: EURPE,  at: 2 },
        {nm: 'Lithuania',                         d: 'Lithuania',                 ab:'LTU',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Luxembourg',                        d: 'Luxembourg',                ab:'LUX',  cur:'EUR', rg: EURPE,  at: 2 },


        {nm: 'Macao',                             d: 'Macao',                     ab:'MAC',  cur:'MOP', rg: ASEST,  at: 0 },
        {nm: 'Macedonia',                         d: 'Macedonia, the former Yugoslav Republic of',  
                                                                                  ab:'MKD',  cur:'MKD', rg: EURPE,  at: 2 },
        {nm: 'Madagascar',                        d: 'Madagascar',                ab:'MDG',  cur:'MGF', rg: AFSUB,  at: 0 },
        {nm: 'Malawi',                            d: 'Malawi',                    ab:'MWI',  cur:'MWK', rg: AFSUB,  at: 0 },
        {nm: 'Malaysia',                          d: 'Malaysia',                  ab:'MYS',  cur:'MYR', rg: ASSSE,  at: 0 },
        {nm: 'Maldives',                          d: 'Maldives',                  ab:'MDV',  cur:'MVR', rg: ASSSE,  at: 0 },
        {nm: 'Mali',                              d: 'Mali',                      ab:'MLI',  cur:'XOF', rg: AFSUB,  at: 0 },
        {nm: 'Malta',                             d: 'Malta',                     ab:'MLT',  cur:'MTL', rg: EURPE,  at: 2 },
        {nm: 'Marshall Islands',                  d: 'Marshall Islands',          ab:'MHL',  cur:'USD', rg: OCEAN,  at: 0 },
        {nm: 'Martinique',                        d: 'Martinique',                ab:'MTQ',  cur:'EUR', rg: CARIB,  at: 0 },
        {nm: 'Mauritania',                        d: 'Mauritania',                ab:'MRT',  cur:'MRO', rg: AFSUB,  at: 0 },
        {nm: 'Mauritius',                         d: 'Mauritius [includes Rodrigues]',  
                                                                                  ab:'MUS',  cur:'MUR', rg: AFSUB,  at: 0 },
        {nm: 'Mayotte',                           d: 'Mayotte',                   ab:'MYT',  cur:'EUR', rg: AFSUB,  at: 0 },
        {nm: 'Mexico',                            d: 'Mexico',                    ab:'MEX',  cur:'MXN',    rg: AMCNT,  at: 0 },
        {nm: 'Micronesia',                        d: 'Micronesia, Federated States of',  
                                                                                  ab:'FSM',  cur:'USD', rg: OCEAN,  at: 0 },
        {nm: 'Moldova',                           d: 'Moldova',                   ab:'MDA',  cur:'MDL', rg: ASNRT,  at: 0 },
        {nm: 'Monaco',                            d: 'Monaco',                    ab:'MCO',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Mongolia',                          d: 'Monaco',                    ab:'MNG',  cur:'MNT', rg: ASEST,  at: 0 },
        {nm: 'Montenegro',                        d: 'Mongolia',                  ab:'MNE',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Montserrat',                        d: 'Montserrat',                ab:'MSR',  cur:'XCD', rg: CARIB,  at: 0 },
        {nm: 'Morocco',                           d: 'Morocco',                   ab:'MAR',  cur:'MAD', rg: AFNRT,  at: 0 },
        {nm: 'Mozambique',                        d: 'Mozambique',                ab:'MOZ',  cur:'MZM', rg: AFSUB,  at: 0 },
        {nm: 'Myanmar',                           d: 'Myanmar',                   ab:'MMR',  cur:'MMK', rg: ASSSE,  at: 0 },
        {nm: 'Namibia',                           d: 'Namibia',                   ab:'NAM',  cur:'NAD', rg: AFSUB,  at: 0 }, /**ALSO THE SA RAND */
        {nm: 'Nauru',                             d: 'Nauru',                     ab:'NRU',  cur:'AUD', rg: OCEAN,  at: 0 },
        {nm: 'Nepal',                             d: 'Nepal',                     ab:'NPL',  cur:'NPR', rg: ASSSE,  at: 0 },
        {nm: 'Netherlands',                       d: 'Netherlands',               ab:'NLD',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'New Caledonia',                     d: 'New Caledonia',             ab:'NCL',  cur:'XPF', rg: OCEAN,  at: 0 },
        {nm: 'New Zealand',         d: 'New Zealand [includes the Antipodean, Chatham and Kermadec island groups]',  
                                                                                  ab:'NZL',  cur:'NZD', rg: OCEAN,  at: 0 },
        {nm: 'Nicaragua',                         d: 'Nicaragua',                 ab:'NIC',  cur:'NIO', rg: AMCNT,  at: 0 },
        {nm: 'Niger',                             d: 'Niger',                     ab:'NER',  cur:'XOF', rg: AFSUB,  at: 0 },
        {nm: 'Nigeria',                           d: 'Nigeria',                   ab:'NGA',  cur:'NGN', rg: AFSUB,  at: 0 },
        {nm: 'Niue',                              d: 'Niue',                      ab:'NIU',  cur:'NZD', rg: OCEAN,  at: 0 },
        {nm: 'Norfolk Island',                    d: 'Norfolk Island',            ab:'NFK',  cur:'AUD', rg: OCEAN,  at: 0 },
        {nm: 'Northern Mariana Islands',          d: 'Northern Mariana Islands',  ab:'MNP',  cur:'USD', rg: OCEAN,  at: 0 },
        {nm: 'Norway',                            d: 'Norway',                    ab:'NOR',  cur:'NOK', rg: EURPE,  at: 2 },


        {nm: 'Oman',                              d: 'Oman',                      ab:'OMN',  cur:'OMR', rg: ASWCN,  at: 0 },


        {nm: 'Pakistan',                          d: 'Pakistan',                  ab:'PAK',  cur:'PKR', rg: ASWCN,  at: 0 },
        {nm: 'Palau',                             d: 'Palau',                     ab:'PLW',  cur:'USD', rg: OCEAN,  at: 0 },
        {nm: 'Palestine, State of',               d: 'Palestine, State of',       ab:'PSE',  cur:'EGP', rg: ASWCN,  at: 0 }, /** egp, ils & jod */
        {nm: 'Panama',                            d: 'Panama',                    ab:'PAN',  cur:'PAB', rg: AMCNT,  at: 0 }, /** USD */
        {nm: 'Papua New Guinea',  d: 'Papua New Guinea [includes the Bismarck Archipelago and the North Solomons]',  
                                                                                  ab:'PNG',  cur:'PGK', rg: OCEAN,  at: 0 },
        {nm: 'Paraguay',                          d: 'Paraguay',                  ab:'PRY',  cur:'PYG', rg: AMSTH,  at: 0 },
        {nm: 'Peru',                              d: 'Peru',                      ab:'PER',  cur:'PEN', rg: AMSTH,  at: 0 },
        {nm: 'Philippines',                       d: 'Philippines',               ab:'PHL',  cur:'PHP', rg: ASSSE,  at: 0 },
        {nm: 'Pitcairn',                          d: 'Pitcairn',                  ab:'PCN',  cur:'NZD', rg: OCEAN,  at: 0 },
        {nm: 'Poland',                            d: 'Poland',                    ab:'POL',  cur:'PLN', rg: EURPE,  at: 2 },
        {nm: 'Portugal',                     d: 'Portugal [includes the Azores, Madeira and the Selvagens islands]',  
                                                                                  ab:'PRT',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Puerto Rico',                       d: 'Puerto Rico',               ab:'PRI',  cur:'USD', rg: CARIB,  at: 0 },

        {nm: 'Qatar',                             d: 'Qatar',                     ab:'QAT',  cur:'QAR', rg: ASWCN,  at: 0 },

        {nm: 'Réunion',                           d: 'Réunion',                   ab:'REU',  cur:'EUR', rg: AFSUB,  at: 0 },
        {nm: 'Romania',                           d: 'Romania',                   ab:'ROU',  cur:'ROL', rg: EURPE,  at: 2 },
        {nm: 'Russia',                            d: 'Russia',                    ab:'RUS',  cur:'RUR', rg: ASNRT,  at: 0 }, /** RUR & RUB */
        {nm: 'Russian Federation',                d: 'Russian Federation',        ab:'RUS',  cur:'RUB', rg: ASNRT,  at: 0 },
        {nm: 'Rwanda',                            d: 'Rwanda',                    ab:'RWA',  cur:'RWF', rg: AFSUB,  at: 0 },


        {nm: 'Saint Bathélemy',                   d: 'Saint Bathélemy',           ab:'BLM',  cur:'EUR', rg: CARIB,  at: 0 },
        {nm: 'Saint Helena, Ascension and Tristan da Cunha',            
                                                  d: 'Saint Helena, Ascension and Tristan da Cunha',  
                                                                                  ab:'SHN',  cur:'SHP', rg: AFSUB,  at: 0 },
        {nm: 'Saint Kitts and Nevis',             d: 'Saint Kitts and Nevis',     ab:'KNA',  cur:'XCD', rg: CARIB,  at: 0 },
        {nm: 'Saint Lucia',                       d: 'Saint Lucia',               ab:'LCA',  cur:'XCD', rg: CARIB,  at: 0 },
        {nm: 'Saint Martin (French Part)',        d: 'Saint Martin (French Part)',ab:'MAF',  cur:'EUR', rg: CARIB,  at: 0 },
        {nm: 'Saint Pierre and Miquelon',         d: 'Saint Pierre and Miquelon', ab:'SPM',  cur:'EUR', rg: AMNOR,  at: 0 },
        {nm: 'Saint Vincent and the Grenadines',  d: 'Saint Vincent and the Grenadines',  
                                                                                  ab:'VCT',  cur:'XCD', rg: CARIB,  at: 0 },
        {nm: 'Samoa',                             d: 'Samoa',                     ab:'WSM',  cur:'WST', rg: OCEAN,  at: 0 },
        {nm: 'San Marino',                        d: 'San Marino',                ab:'SMR',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Sao Tomé and Principe',             d: 'Sao Tomé and Principe',     ab:'STP',  cur:'STD', rg: AFSUB,  at: 0 },
        {nm: 'Saudi Arabia',                      d: 'Saudi Arabia',              ab:'SAU',  cur:'SAR', rg: ASWCN,  at: 0 },
        {nm: 'Senegal',                           d: 'Senegal',                   ab:'SEN',  cur:'XOF', rg: AFSUB,  at: 0 },
        {nm: 'Serbia',                            d: 'Serbia',                    ab:'SRB',  cur:'CSD', rg: EURPE,  at: 2 },
        {nm: 'Seychelles',                        d: 'Seychelles [includes the island of Aldabra]',  
                                                                                  ab:'SYC',  cur:'SCR', rg: AFSUB,  at: 0 },
        {nm: 'Sierra Leone',                      d: 'Sierra Leone',              ab:'SLE',  cur:'SLL', rg: AFSUB,  at: 0 },
        {nm: 'Singapore',                         d: 'Singapore',                 ab:'SGP',  cur:'SGD', rg: ASSSE,  at: 0 },
        {nm: 'Sint Maarten (Dutch Part)',         d: 'Sint Maarten (Dutch Part)', ab:'SXM',  cur:'ANG', rg: OTHER,  at: 0 },
        {nm: 'Slovakia',                          d: 'Slovakia',                  ab:'SVK',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Slovenia',                          d: 'Slovenia',                  ab:'SVN',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Solomon Islands',                   d: 'Solomon Islands',           ab:'SLB',  cur:'SBD', rg: OCEAN,  at: 0 },
        {nm: 'Somalia',                           d: 'Somalia',                   ab:'SOM',  cur:'SOS', rg: AFSUB,  at: 0 },
        {nm: 'South Africa',                      d: 'South Africa [includes Marion and Prince Edward Islands]',  
                                                                                  ab:'ZAF',  cur:'ZAR', rg: AFSUB,  at: 0 },
        {nm: 'South Georgia and the South Sandwich Islands',            
                                                  d: 'South Georgia and the South Sandwich Islands',  
                                                                                  ab:'SGS',  cur:'FKP', rg: ANTAR,  at: 0 },
        {nm: 'South Sudan',                       d: 'South Sudan',               ab:'SSD',  cur:'SSP', rg: AFSUB,  at: 0 },
        {nm: 'Spain', d: 'Spain [includes the Belearic and Canary islands and the Spanish North African Territories]',  
                                                                                  ab:'ESP',  cur:'EUR', rg: EURPE,  at: 2 },
        {nm: 'Sri Lanka',                         d: 'Sri Lanka',                 ab:'LKA',  cur:'LKR', rg: ASSSE,  at: 0 },
        {nm: 'Sudan',                             d: 'Sudan',                     ab:'SDN',  cur:'SDG', rg: AFSUB,  at: 0 },
        {nm: 'Suriname',                          d: 'Suriname',                  ab:'SUR',  cur:'SRG', rg: AMSTH,  at: 0 },
        {nm: 'Svalbard and Jan Mayen',            d: 'Svalbard and Jan Mayen',    ab:'SJM',  cur:'NOK', rg: EURPE,  at: 2 },
        {nm: 'Swaziland',                         d: 'Swaziland',                 ab:'SWZ',  cur:'SZL', rg: AFSUB,  at: 0 },
        {nm: 'Sweden',                            d: 'Sweden',                    ab:'SWE',  cur:'SEK', rg: EURPE,  at: 2 },
        {nm: 'Switzerland',                       d: 'Switzerland',               ab:'CHE',  cur:'CHF', rg: EURPE,  at: 2 },
        {nm: 'Syrian Arab Republic',              d: 'Syrian Arab Republic',      ab:'SYR',  cur:'SYP', rg: ASWCN,  at: 0 },
        {nm: 'Taiwan, Republic of China',         d: 'Taiwan, Republic of China', ab:'TWN',  cur:'TWD', rg: ASEST,  at: 0 }, /**Province or Republic OC ?? */
        {nm: 'Tajikistan',                        d: 'Tajikistan',                ab:'TJK',  cur:'TJS', rg: ASWCN,  at: 0 },
        {nm: 'Tanzania, United Republic of',      d: 'Tanzania, United Republic of',  
                                                                                  ab:'TZA',  cur:'TZS', rg: AFSUB,  at: 0 },
        {nm: 'Thailand',                          d: 'Thailand',                  ab:'THA',  cur:'THB', rg: ASSSE,  at: 0 },
        {nm: 'Timor-Leste',                       d: 'Timor-Leste',               ab:'TLS',  cur:'USD', rg: ASSSE,  at: 0 },
        {nm: 'Togo',                              d: 'Togo',                      ab:'TGO',  cur:'XOF', rg: AFSUB,  at: 0 },
        {nm: 'Tokelau',                           d: 'Tokelau',                   ab:'TKL',  cur:'NZD', rg: OCEAN,  at: 0 },
        {nm: 'Tonga',                             d: 'Tonga',                     ab:'TON',  cur:'TOP', rg: OCEAN,  at: 0 },
        {nm: 'Trinidad and Tobago',               d: 'Trinidad and Tobago',       ab:'TTO',  cur:'TTD', rg: CARIB,  at: 0 },
        {nm: 'Tunisia',                           d: 'Tunisia',                   ab:'TUN',  cur:'TND', rg: AFNRT,  at: 0 },
        {nm: 'Turkey',                            d: 'Turkey',                    ab:'TUR',  cur:'TRY', rg: ASWCN,  at: 0 },
        {nm: 'Turkmenistan',                      d: 'Turkmenistan',              ab:'TKM',  cur:'TMM', rg: ASWCN,  at: 0 },
        {nm: 'Turks and Caicos Islands',          d: 'Turks and Caicos Islands',  ab:'TCA',  cur:'USD', rg: CARIB,  at: 0 },
        {nm: 'Tuvalu',                            d: 'Tuvalu',                    ab:'TUV',  cur:'AUD', rg: OCEAN,  at: 0 },


        {nm: 'Uganda',                            d: 'Uganda',                    ab:'UGA',  cur:'UGX', rg: AFSUB,  at: 0 },
        {nm: 'Ukraine',                           d: 'Ukraine',                   ab:'UKR',  cur:'UAH', rg: ASNRT,  at: 0 },
        {nm: 'United Arab Emirates',              d: 'United Arab Emirates',      ab:'ARE',  cur:'AED', rg: ASWCN,  at: 0 },
        {nm: 'United Kingdom',                    d: 'United Kingdom [excludes Guernsey, Jersey and Isle of Man]',  
                                                                                  ab:'GBR',  cur:'GBP', rg: EURPE,  at: 1 },
        {nm: 'United States',                     d: 'United States',             ab:'USA',  cur:'USD', rg: AMNOR,  at: 0 }, /*SAME DAY: USS, NEXT DAY: USN */
        {nm: 'USA',                               d: 'United States',             ab:'USA',  cur:'USD', rg: AMNOR,  at: 0 },
        {nm: 'United States Minor Outlying Islands', 
            d: 'United States Minor Outlying Islands [includes the Howland-Baker, Johnston, Midway, US Line and Wake island groups]',    
                                                                                  ab:'UMI',  cur:'USD', rg: OCEAN,  at: 0 },
        {nm: 'Uruguay',                           d: 'Uruguay',                   ab:'UYR',  cur:'UYU', rg: AMSTH,  at: 0 },
        {nm: 'Uzbekistan',                        d: 'Uzbekistan',                ab:'UZB',  cur:'UZS', rg: ASWCN,  at: 0 },
        {nm: 'Vanuatu',                           d: 'Vanuatu',                   ab:'VUT',  cur:'VUV', rg: OCEAN,  at: 0 },
        {nm: 'Venezuela',                         d: 'Venezuela, Bolivarian Republic of',  
                                                                                  ab:'VEN',  cur:'VEF', rg: AMSTH,  at: 0 },
        {nm: 'Viet Nam',                          d: 'Viet Nam',                  ab:'VNM',  cur:'VND', rg: ASSSE,  at: 0 },
        {nm: 'Virgin Islands, British',           d: 'Virgin Islands, British',   ab:'VGB',  cur:'USD', rg: CARIB,  at: 0 },
        {nm: 'Virgin Islands, U.S.',              d: 'Virgin Islands, U.S.',      ab:'VIR',  cur:'USD', rg: CARIB,  at: 0 },
        {nm: 'Wallis and Futuna',                 d: 'Wallis and Futuna',         ab:'WLF',  cur:'XPF', rg: OCEAN,  at: 0 },
        {nm: 'Western Sahara',                    d: 'Western Sahara',            ab:'ESH',  cur:'MAD', rg: AFNRT,  at: 0 },
        {nm: 'Yemen',                             d: 'Yemen [includes the island of Socotra]',  
                                                                                  ab:'YEM',  cur:'YER', rg: ASWCN,  at: 0 },
        {nm: 'Zambia',                            d: 'Zambia',                    ab:'ZMB',  cur:'ZMW', rg: AFSUB,  at: 0 },
        {nm: 'Zimbabwe',                          d: 'Zimbabwe',                  ab:'ZWE',  cur:'ZWD', rg: AFSUB,  at: 0 },    
        {nm: 'Other / Not listed',                d: 'Other',                     ab:'OTH',  cur:'USD', rg: OTHER,  at: 0 }
    ];
    
    
export const getCntryListByRegion = function( reg ){
        
        var cntryList = [];

        var regInt = parseInt( reg, 10);
        
        if( regInt >= 0 && regInt < 13) {
            for(var ndx=0; ndx< countryCodes3Ltr.length; ndx++){
            
                if(countryCodes3Ltr[ndx].rg === regInt){
                    cntryList.push( countryCodes3Ltr[ndx] );
                }
            }
            //console.log(cntryList);
            return cntryList;
        } else {
            //return countryCodes3Ltr;
            return null;
        }
        
};


export const getCntryNameByAbv = function( abv ){
        
        for(var ndx=0; ndx < countryCodes3Ltr.length; ndx++) {
            if( countryCodes3Ltr[ndx].ab === abv ){
                return countryCodes3Ltr[ndx].nm;
            }
        }
        return null;
};    


export const getCntryRegionByAbv = function( abv ){
        
        for(var ndx=0; ndx < countryCodes3Ltr.length; ndx++) {
            if( countryCodes3Ltr[ndx].ab === abv ){
                return countryCodes3Ltr[ndx].rg;
            }
        }
        return null;
};    


export const getCntryAddrTypeByAbv = function( abv ){
        
        for(var ndx=0; ndx < countryCodes3Ltr.length; ndx++) {
            if( countryCodes3Ltr[ndx].ab === abv ){
                return countryCodes3Ltr[ndx].at;
            }
        }
        return null;
};    
    
    
export const getAreaUnitsByCountryAbv = function( abv ) {
      
        switch( getCntryRegionByAbv( abv ) ) {
            case 1: return 'sq.ft.';
            default: return 'sq.m.';
        };
};


export const getCountryList = () => {
    
    return countryCodes3Ltr.map( (country) => country.nm );
};


export const selectCountry = () => {
    
    return countryCodes3Ltr.map( (country) => ({'code': country.ab, 'name': country.nm}) );
};


export const countryOptionList = () => {
    
     return  countryCodes3Ltr.map( (cntry) => { return(  "<option value=\"" +  cntry.ab  + "\">" + cntry.nm  + "</option>"  ); } ).join('\n');

};

// This works with Formik <Field as="select" >
export const countrySelectOptionList =  countryCodes3Ltr.map( (c, index) => {return(<option  key={index} value={`${c.ab}`}>{`${c.nm}`}</option>);} )

export const countryDetailsByCurrency = (cur) => countryCodes3Ltr.filter( cntry => cntry.cur === cur)

export const currencyByCountryAbv = (cntry) => countryCodes3Ltr.filter( country => country.ab === cntry )[0].cur