import styled from 'styled-components'
import {Link as RouterLink} from 'react-router-dom'



export const Form = styled.form`
    display: grid;
    grid-template-columns: ${ (props) => props.cols || "100px 250px" };
    grid-template-rows: ${ (props) => props.rows || "25px"};
    grid-column-gap: 5px;    
    grid-row-gap: 5px;

    padding: .25em .5em .75em .5em;
    border: 2px solid darkgreen;
    border-radius: 8px;
`;

export const Label = styled.label`
    grid-column-start: ${({grid}) => grid.cs};
    grid-column-end: ${({grid}) => grid.ce };
    grid-row-start:${({grid}) => grid.rs };
    grid-row-end: ${({grid}) => grid.re};

    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: darkgreen;
    
    font-size: 0.75rem;
    font-weight: 600;
    text-align: right;
    transform: translateY(25%);

`;

export const Input = styled.input.attrs({
    type: 'text'
})`
    grid-column-start: ${({grid}) => grid.cs};
    grid-column-end: ${({grid}) => grid.ce };
    grid-row-start:${({grid}) => grid.rs };
    grid-row-end: ${({grid}) => grid.re};

    &[type='text']:not(focus),
    &[type='text'] {
        min-width: 55px;
        width: auto;
        height: 25px;
        margin: 5px 0;
        border: 1px solid green;
        border-radius: 5px;
        padding-left: 0.5em;
    }

    &[type='text']:hover,
    &[type='text']:focus {
        border: 2px solid darkgreen;
        border-radius: 5px;
    }

`;


export const Radio = styled.div`
    grid-column-start: ${({grid}) => grid.cs};
    grid-column-end: ${({grid}) => grid.ce };
    grid-row-start:${({grid}) => grid.rs };
    grid-row-end: ${({grid}) => grid.re};

    display: flex;
    align-items: center;
    /* margin-left: 1em; */
    margin: .5em .1em .5em;

    &:first-of-type {
        margin-top: 1em;
    }

`;
export const RadioLabel = styled.label`
    display: inline-block;
    color: black;
    font-weight: 400;
    background: transparent;
    width: fit-content;
    height: auto;
    margin-left: 0.75em;
`;
export const RadioBtn = styled.input.attrs({
    type:'radio'
})`
    width: auto;
    height: auto;
    border: 1px solid red;
`;

export const RadioText = styled.div`
    display: inline-block;
    color: black;
    font-weight: 400;
    background: transparent;
    width: fit-content;
    height: auto;
    margin-left: 0.75em;
`;

export const TextArea = styled.textarea`
    grid-column-start: ${({grid}) => grid.cs};
    grid-column-end: ${({grid}) => grid.ce };
    grid-row-start:${({grid}) => grid.rs };
    grid-row-end: ${({grid}) => grid.re};


    color: black;
    background-color: white;
    min-width: 55px;
    width: auto;
`;

export const SubmitBtn = styled.button`
    grid-column-start: ${({grid}) => grid.cs};
    grid-column-end: ${({grid}) => grid.ce };
    grid-row-start:${({grid}) => grid.rs };
    grid-row-end: ${({grid}) => grid.re};

    border: 1px solid green;
    border-radius: .5em;
    margin: 5px 0 0 0;
    height: 100%;
    width: 100%;

    &:active {
        background-color: lightgreen;
    }

`;

export const Text = styled.p`
    grid-column-start: ${({grid}) => grid.cs};
    grid-column-end: ${({grid}) => grid.ce };
    grid-row-start:${({grid}) => grid.rs };
    grid-row-end: ${({grid}) => grid.re};

    color: white;
    font-size: 16px;
`;

export const TextSmall = styled(Text)`
    font-size: 12px;
`;

export const Link = styled(RouterLink)`
    grid-column-start: ${({grid}) => grid.cs};
    grid-column-end: ${({grid}) => grid.ce };
    grid-row-start:${({grid}) => grid.rs };
    grid-row-end: ${({grid}) => grid.re};

    display: inline-block;
    text-decoration: none;
`;









