import React from 'react'
import { Container, MainLogo } from './styles/header'

export default function Header({ children, ...restProps}) {
    return <Container ref={restProps.Ref} {...restProps}>{children}</Container>
}

Header.Logo = function HeaderLogo({children, ...restProps}) {
  return <MainLogo {...restProps}>{children}</MainLogo>
}
