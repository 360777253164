import styled from 'styled-components'

export const ModalBackground = styled.div`
  ${({ showModal }) => console.log("ShowModal: ", showModal)}

  display: ${({ showModal }) => showModal ? 'flex' : 'none'};


  position: absolute;
  background: transparent;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  /* border: 2px solid red; */
`;

export const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  /* border: 2px solid fuchsia; */
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 500px;
    height: 500px;
    border: 3px solid black;
    border-radius: 2em;
    background: white;
    
`;

export const Wrapper = styled.div`
    display: block;
    /* flex-direction: column;
    justify-content: center; */
    text-align: left;
    width: 100%;
    height: auto;
    background: #f2f2f2;
    overflow-y: scroll;

    border-top: 2px solid black;
    border-bottom: 2px solid black;
`;

export const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 150px;
    // height: 50px;
    font-size: 1.5rem;
    padding: 10px 20px;
    border-radius: .3em;
    background-color: dodgerblue;
    color: white;
    margin-bottom: .5em;
    margin-right: auto;
    margin-left: auto;
    margin-top: .5em;

    &:active{
        background: darkgreen;
    }
    &:hover {
        background: blueviolet;
    }
`;
export const Button2 = styled(Button).attrs({
    type:'button'
})`
    width: fit_content;
    height: auto;
    padding: 8px 15px;
    font-size: 1.2rem;
    letter-spacing: 1px;
    background: steelblue;
`;
export const OkBtn = styled(Button).attrs({
    type: 'button'
})``;

export const CancelBtn = styled(Button).attrs({
    type: 'cancel'
})``;


export const Title = styled.h2`
    color: black;
    background: white;
    width: fit-content;
    margin: 0 auto;
    padding-top: .5em;
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
    
`;

export const Text = styled.div`
    color: black;
    background: white;
    width: fit-content;
    margin: 0 auto;
    padding-top: .5em;
    font-size: 1rem;
    font-weight: 400;
    
`;

export const Radio = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin-left: 1em; */
    margin: .5em .1em .5em;

    /* border: 1px solid red; */

    &:hover {
        background: #CCC;
    }
    &:first-of-type {
        margin-top: 1em;
    }

`;
export const RadioLabel = styled.label`
    display: inline-block;
    text-align: left;
    color: black;
    font-weight: 400;
    background: transparent;
    width: fit-content;
    height: auto;
    margin-left: 0.75em;
`;
export const RadioBtn = styled.input.attrs({
    type: 'radio'
})`
    width: auto;
    height: auto;
    border: 1px solid green;
    margin-left: 1em;
`;

// Not used at present...
export const RadioText = styled(RadioLabel)`
    font-weight: 400;
`;

export const FunctionGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    height: auto;
    width: 100%;

`;