import styled from 'styled-components';
import { Link } from 'react-router-dom'

export const Container = styled.section`
    /* margin: 0.5em 0.5em 0em 0; -0.5em; */
    position: relative;
    margin: 0 0.5em 0em 0;
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  
    /* border: 1px solid red; */

    /* overflow: hidden; */
`;

export const SearchLink = styled(Link)`
    font-size: 0.65rem;
    color: darkgreen;
    font-weight: 600;
    padding: 0 0.3em;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 3px;
    letter-spacing: .3px;

    &:visited {
        color: darkgreen;
    }
    &:hover {
        border: 1px solid darkgreen;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    }
    ${Container} > &:first-child {
        /* margin-left: -1.25em; */
    }
    ${Container} > &:last-child {
        margin-right: 1.5em;
    }
    
`;

export const DropBtn = styled.div`
  font-size: .8rem;
  font-weight: 400;
  /* padding: 0 0.3em; */
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  letter-spacing: 0.3px;
  
  outline: none;
  color: black;
  background-color: white;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
  border: 1px solid #1f1f1f;
  border-radius: .3em;

  padding: .4em .2em;
  padding-left: .5em;
  padding-right: .5em;
  margin: .2em .2em;

  cursor: pointer;

  &:hover {
        /* border-bottom: 1px solid darkgreen; */
        /* border: 1px solid darkgreen; */
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        border: none;
        outline:none;
    }
  &::after {
    content: ">";
    transform: rotate(-90deg);
  }
  &::before {
    
  }
  /* &:first-of-type{
    margin-left: 0;
  } */
`;

export const SearchSelect = styled.select`
  /* border: 1px solid red; */
  font-weight: 0.85rem;
  padding: .5em .2em;
  margin: .5em .2em;
  border: 1px solid #1f1f1f;
  border-radius: .3em;
  width: fit-content;
  cursor: pointer;
`;

export const SearchTextbox = styled.input.attrs(props => ({
  type:'text',
  name: props.name,
  id: props.id,
  value: props.value,
}))`
font-size: 0.85rem;
  padding: .5em .2em;
  margin: .5em .2em;
  border: 1px solid #1f1f1f;
  border-radius: .3em;
  width: 350px;
`;

export const Textbox = styled.input.attrs(props => ({
  type:'text',
  name: props.name,
  id: props.id,
  value: props.value,
}))`
  font-size: 0.85rem;
  padding: .5em .2em;
  margin: .5em .2em;
  border: 1px solid #1f1f1f;
  border-radius: .3em;
  width: 50px;
`

export const SearchCheckBox = styled.input.attrs(props => ({
  type:'checkbox',
  value: props.value,
  name: props.name,
  id: props.id,
  onChange: props.onChange,
}))`
  font-size: 0.65rem;
  margin: 0 .5em;
  color: darkgreen;
  font-weight: 600;
  padding: 0 0.3em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  letter-spacing: .3px;
  border: 3px solid red;

  cursor: pointer;
`;


export const DropDownItem = styled.div`
  display: none;
  position: absolute;
  top: 40px;
  background-color: green;
  min-width: 60px;
  z-index: 1;
`;

export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  top:40px;
  /* width: 180px; */
  width: fit-content;
  padding: 0em .7em;
  background-color: #f9f9f9;
  min-width: 60px; /** 160px */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100;
  font-size: 1rem;
  /* border: 2px solid fuchsia; */

 & ${SearchCheckBox} {
    float: none;
    color: darkgreen;
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    /* border: 1px solid red; */
 }

  & ${SearchLink}:hover {
    background-color: #ddd;
  }
`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  font-size: .85rem;
  margin: auto 0.15em;
  padding: 0;
  overflow: hidden;
  outline: none;
  background-color: inherit;
  
  /* border: 1px solid orange; */
  
  &:hover {
    background-color: inherit;
  }
  &:hover ${DropDownContent} {
    display: block;
  }
  &:hover ${DropBtn} {
      border: 1px solid #1f1f1f;
  }
`;

export const CheckBoxItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  height: 35px;
  padding: 0 10px 0 5px;
  cursor: pointer;
`;

export const RadioButtonItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  height: 35px;
  padding: 0 10px 0 5px;
  cursor: pointer;
`;

export const TextInputItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  width: 150px;
  /* height: 35px; */
  padding: 0 10px 0 5px;
  /* border: 1px solid darkgreen; */
`;

export const Btn = styled.div`
  font-size: .8rem;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  /* border-radius: 3px; */
  letter-spacing: 0.3px;
  outline: none;
  color: black;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */

  border: 1px solid #1f1f1f;
  border-radius: .3em;
  padding: .45em .5em;
  margin: 0; /* Important for vertical align on mobile phones */
  margin: .5em .2em;
  cursor: pointer;

  &:hover {
        /* border-bottom: 1px solid darkgreen; */
        /* border: 1px solid darkgreen; */
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        /* border: none; */
        outline: none;
    }
  &::after {
    content: ">";
    transform: rotate(-90deg);
  }
  &::before {
    
  }
`;

export const RadioButton = styled.input.attrs(props => ({
  type: 'radio',
  value: props.value,
  name: props.name,
  id: props.id,
}))`
  cursor: pointer;
`;

export const Label = styled.label`
  padding: 0 10px;
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  padding: 0 .5em;
  /* background-color: darkgreen;
  width: 100%; */
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  padding: 0 .25em;
  /* background-color: darkgreen;
  width: 100%; */
  /* border: 1px solid green; */
`;