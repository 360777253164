import React from 'react'
import {MPage, Page, Background, Container, Wrapper, Box, Button, Title, Text, OkBtn, CancelBtn} from './styles/modal'

/**
 *  Usage:
 *  import {Modal, ModalPage } from '../components/Modal'
 *  <ModalPage>
 *     <<ModalPage.Page>
 * 
 *       // Use ModalPage component //
 *       // REGULAR PAGE STUFF HERE //
 * 
 * 
 *     </ModalPage.Page>
 * 
 *     <Modal displayModal={displayModal} style={{left:'20vw', top:'50px'}}>
 *        <Modal.Box>  OPTIONAL - Adds default Background and Border
 *          <h1>MODAL DIALOG BOX</h1>
 *        </Modal.Box>
 *     </Modal>
 * 
* </ModalPage>
 */

// Modal is the base component. Usually you don't want to call it directly, but you can...
// There is no styling on this component.
//
export function ModalPage({children, ...restProps}) {
    return <MPage {...restProps}>{children}</MPage>
}

ModalPage.Page = function ModalPagePage({children, ...restProps}) {
    return <Page {...restProps}>{children}</Page>
}




export  function Modal({ width, height, displayModal, children, ...restProps}) {
    return <Background displayModal={displayModal} {...restProps}>
                <Container>
                    <Wrapper width={width} height={height} {...restProps}>
                            {children}
                    </Wrapper>
                </Container>
           </Background>
}

// DialogModal - Displays whatever is passed
//             - used where user interaction / decision is reqired
//
// <DialogModal displayModal={...} style={{left:'20vw', top:'50px'}> CONTENT </DisplayModal>
//
export function DialogModal({displayModal,  children, ...restProps}) {
    return <Modal displayModal={displayModal} {...restProps} >
        <Modal.Box {...restProps}>
            {children}
        </Modal.Box>
    </Modal>
}

// AlertModal - displays whatever is passed and displays a single default OK button
//            - for passing information to the user where a response is not desired
//
//  <AlertModal displayModal={...} onClose={...}>This is an ALERT!</AlertModal>
//
//  params:
//    displayModal = pass true from state to open modal
//    onClose = callback fxn called w/ false to reset parent state
//    The modal maintains its own internal display (open/closed) state.
//
export function AlertModal({displayModal, onClose, children, ...restProps}) {
    React.useEffect(()=>{_setDisplayModal(displayModal)},[displayModal])
    const[_displayModal, _setDisplayModal] = React.useState(displayModal)
    return <Modal displayModal={_displayModal} {...restProps}>
        {children}
        <OkBtn onClick={()=>{_setDisplayModal(false); onClose(false)}}>OK</OkBtn>
    </Modal>
}

Modal.Background = function ModalBackground({showModal, children, ...restProps}){
    return <Background showModal={showModal} {...restProps}>{children}</Background>
}

Modal.Container = function ModalContainer({children, ...restProps}) {
    return <Container>{children}</Container>
}

Modal.Wrapper = function ModalWrapper({children, ...restProps}) {
    return <Wrapper>{children}</Wrapper>
}

Modal.Box = function ModalBox({children, ...restProps}) {
    return <Box>{children}</Box>
}
Modal.Button = function ModalButton({onBtnClick, children, ...restProps}) {
    return <Button type='button' onClick={onBtnClick} {...restProps}>{children}</Button>
}

Modal.CancelBtn = function ModalCancelBtn({children, ...restProps}) {
    return <CancelBtn {...restProps}>{children}</CancelBtn>
}

Modal.OkBtn = function ModalOkBtn({children, ...restProps}) {
    return <OkBtn {...restProps}>{children}</OkBtn>
}

Modal.Title = function ModalText({children, ...restProps}){
    return <Title {...restProps}>{children}</Title>
}

Modal.Text = function ModalText({children, ...restProps}) {
    return <Text {...restProps}>{children}</Text>
}





