import styled from 'styled-components'

export const Background = styled.div.attrs(props => ({

    left: props.style ? props.style["left"] : "0px",
    top: props.style ? props.style["top"] : "0px",
    
    
}))`
  
  /* This controls if the modal is displayed or not (display: 'none') */
  display: ${({ displayModal }) => displayModal ? 'flex' : 'none'};

  position: absolute;
  background: transparent;
  /* background: rgba(0,0,0,.4); */
  width: 100vw;
  height: 85vh;
  overflow: clip;
  /* border: 5px solid fuchsia; */
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  /* border: 3px solid green; */
`;

export const Wrapper = styled.div.attrs(props => ({
    width: props.style ? props.style['width'] : '95%',
    height: props.style ?  props.style['height'] : '70%',
}))`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 500px; */
    /* height: 500px; */
    background: transparent;
    border: none;
    border-radius: 2em;
    /* border: 2px solid blue; */

`;

export const Box = styled(Wrapper)`
    width: 100%;
    height: 100%;
    padding: 1em;
    border: 1px solid gray;
    border-radius: 2em;
    background: white;
`;

export const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 150px;
    height: 50px; */
    padding: 15px 45px;
    font-size: 1.5rem;
    padding: 10px 20px;
    border-radius: .3em;
    background-color: dodgerblue;
    color: white;
    margin-bottom: .5em;
    margin-right: auto;
    margin-left: auto;

    &:active{
        background: darkgreen;
    }
`;

export const OkBtn = styled(Button).attrs({
    type: 'button'
})``;

export const CancelBtn = styled(Button).attrs({
    type: 'cancel'
})``;


export const Title = styled.h2`
    color: black;
    background: white;
    width: fit-content;
    margin: 0 auto;
    padding-top: .5em;
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
    
`;

export const Text = styled.div`
    color: black;
    background: white;
    width: fit-content;
    margin: 0 auto;
    padding-top: .5em;
    font-size: 1rem;
    font-weight: 400;
    
`;



// Modal Page  Styles
export const MPage = styled.div`
    position: relative;
    /* margin-top: 55px; */
    /* border: 3px solid green; */
`;

export const Page = styled.div``;