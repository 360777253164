import React, { useState, useRef, useEffect, useContext } from 'react'
import { NewPropContext } from '../../contexts/NewPropContext'
import { countrySelectOptionList } from '../../services/countryCodeSvc'
import './styles/addressDetailsBasic.css'



export default function AddressDetailsBasic(props) {

    const [streetNum, setStreetNum] = useState('')
    const [street1, setStreet1] = useState('')
    const [street2, setStreet2] = useState('')
    const [city, setCity] = useState('')
    const [municipality, setMunicipality] = useState('')
    const [addrState, setAddrState] = useState('')
    const [region, setRegion] = useState('')
    const [postal, setPostal] = useState('')
    const [countryCode, setCountryCode] = useState('')
    const inputRef = useRef()

    // Persist the address details into the property context
    const { setAddrDetails } = useContext(NewPropContext)

    let _address = { streetNum, street1, street2, city, municipality, addrState, region, postal, countryCode }

    function handleOnBlur(e) {
        setAddrDetails(_address)
    }

    useEffect(() => {
        inputRef.current.focus()
    }, [])

    //console.log(address)

    return (
        <div className="address_details">
            <form>
                <div className="add-prop__addrform">
                    
                    <label className="form-label addr-l-country" htmlFor="country">Country</label>
                    <select className="form-text-input addr-country" type="text" id="country" name="countryCode" value={countryCode}
                        onChange={(e) => { setCountryCode(e.target.value) }} placeholder="Country" onBlur={handleOnBlur}>
                        {countrySelectOptionList}
                    </select>                        

                    <label className="form-label addr-l-streetNum" htmlFor="streetNum">Street Num</label>
                    <input ref={inputRef} className="form-text-input addr__street addr-streetNum" type="text" id="streetNum" name="streetNum" value={streetNum}
                        onChange={(e) => setStreetNum(e.target.value)} placeholder="Street Number e.g.,'2885'" onBlur={handleOnBlur} />

                    <label className="form-label addr-l-street1" htmlFor="street1">Street</label>
                    <input className="form-text-input addr-street1" type="text" id="street1" name="street1" value={street1}
                        onChange={(e) => { setStreet1(e.target.value) }} placeholder="Street" onBlur={handleOnBlur} />


                    <label className="form-label addr-l-street2" htmlFor="street2">Street 2</label>
                    <input className="form-text-input addr-street2" type="text" id="street2" name="street2" value={street2}
                        onChange={(e) => { setStreet2(e.target.value) }} placeholder="Street2" onBlur={handleOnBlur} />

                    <label className="form-label addr-l-city" htmlFor="city">City</label>
                    <input className="form-text-input addr-city" type="text" id="city" name="city" value={city}
                        onChange={(e) => { setCity(e.target.value) }} placeholder="City" onBlur={handleOnBlur} />

                    <label className="form-label addr-l-muni" htmlFor="municipality">Municipality</label>
                    <input className="form-text-input addr-muni" type="text" id="municipality" name="municipality" value={municipality}
                        onChange={(e) => { setMunicipality(e.target.value) }} placeholder="Municipality" onBlur={handleOnBlur} />

                    <label className="form-label addr-l-state" htmlFor="state">State/Province</label>
                    <input className="form-text-input addr-state" type="text" id="state" name="state" value={addrState}
                        onChange={(e) => { setAddrState(e.target.value) }} placeholder="State or Province" onBlur={handleOnBlur} />

                    <label className="form-label addr-l-region" htmlFor="region">Region</label>
                    <input className="form-text-input addr-region" type="text" id="region" name="region" value={region}
                        onChange={(e) => { setRegion(e.target.value) }} placeholder="Region" onBlur={handleOnBlur} />

                    <label className="form-label addr-l-post" htmlFor="postal">Postal Code</label>
                    <input className="form-text-input addr-post" type="text" id="postal" name="postal" value={postal}
                        onChange={(e) => { setPostal(e.target.value) }} placeholder="Postal Code" onBlur={handleOnBlur} />

                </div>
            </form>
        </div>
    )

}
