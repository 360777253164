import{REACT_APP_MAPBOX_TOKEN, REACT_APP_OPENCAGE_API_KEY, FB_APP_ID} from 'envcli' 



export  const sysConfig = {
  CUR_SVR: 'LOCAL',
  DEV_MODE: false || process.env.DEV_MODE === 'development',
  BACKEND_URI                : 'http://localhost',
  BACKEND_PORT               : '5500',
  REACT_APP_MAPBOX_TOKEN     ,//: process.env.REACT_APP_MAPBOX_TOKEN,
  REACT_APP_OPENCAGE_API_KEY ,//: process.env.REACT_APP_OPENCAGE_API_KEY,
  FB_APP_ID                  : process.env.FB_APP_ID,
}

// CUR_SVR: 'LOCAL' | 'AWS'
// DEV_MODE: true: turn on all the menus, false: only build what's going to be pushed to production; basically  process.env.DEV_MODE with some controle



