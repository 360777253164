import styled from 'styled-components'


export const BoxContainer = styled.div`
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

export const Label = styled.label`
  width: auto;
  font-size: 16px;
  font-weight: 700;
`;

export const Input = styled.input`
  width: 250px;
  font-size: 16px;
  padding: .2em;
  margin: 0 0 0 10px;
`;

export const TextInput = styled(Input).attrs({type:'text'})`
`;

export const PwdInput = styled(Input).attrs({type: 'password'})`
`;

export const ErrorText= styled.div`
  color: red;
  font-size: 12px;

`;

export const FbLogin = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(24,110,242);
  margin: 10px auto;
  padding: 4px;
  width: 300px;
  height: 50px;
  color: white;
  font-size: 20px;
  font-weight: 700;
  border-radius: 6px;
  letter-spacing: 1px;

  &:hover {
    background-color: rgba(24, 80, 255);
  }

`;

export const FbLogo = styled.img`
  margin: auto 0;
  max-width:35px;
  height: auto;
`;