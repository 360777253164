import styled from 'styled-components'


export const Details = styled.div.attrs( ({disabled}) =>({
  disabled: disabled
}))`
  margin: 0.35em 0 0.75em 1em;
  border: 1px solid darkgreen;
  border-radius: 8px;
  padding: 10px;
  max-width: fit-content;
  width: 500px;
`;

export const Form = styled.form.attrs( ({disabled}) =>({
  disabled: disabled
}))`
  display: flex;
`;

export const Layout = styled.div`
  /* border: 1px solid fuchsia; */
  width: 100%;
  display: grid;
  grid-template-columns: ${({gridCol}) => gridCol};
  grid-template-rows: ${({gridRows}) => gridRows};
`;

export const FieldLabel = styled.label`
  grid-column-start: ${({grid}) => grid.cs};
  grid-column-end: ${({grid}) => grid.ce };
  grid-row-start:${({grid}) => grid.rs };
  grid-row-end: ${({grid}) => grid.re};

  font-size: 0.75rem;
  font-weight: 700;
  text-align: right;
  transform: translateY(25%);
`;

export const Input = styled.input`
  grid-column-start: ${({grid}) => grid.cs};
  grid-column-end: ${({grid}) => grid.ce };
  grid-row-start:${({grid}) => grid.rs };
  grid-row-end: ${({grid}) => grid.re};

  &[type='text']:not(focus),
  &[type='text'] {
    margin: 5px 0;
    border: 1px solid green;
    border-radius: 8px;
    padding-left: 0.5em;
  }

  &[type='text']:hover,
  &[type='text']:focus {
    border: 2px solid dodgerblue;
    border-radius: 5px;
  }
`;

export const Select = styled.select`
  grid-column-start: ${({grid}) => grid.cs};
  grid-column-end: ${({grid}) => grid.ce };
  grid-row-start:${({grid}) => grid.rs };
  grid-row-end: ${({grid}) => grid.re};

  &[type='text']:not(focus),
  &[type='text'] {
    margin: 5px 0;
    border: 1px solid green;
    border-radius: 8px;
    padding-left: 0.5em;
  }

  &[type='text']:hover,
  &[type='text']:focus {
    border: 2px solid dodgerblue;
    border-radius: 5px;
  }
`;