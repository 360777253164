import React from 'react'
import useFacebook from '../../hooks/useFacebook'
import {BoxContainer, Form, FormGroup, Label, TextInput, PwdInput, ErrorText, FbLogin, FbLogo } from './styles/LoginBox'
import FBBrandImgWhite from  '../../assets/f_logo_RGB-White_58.png'


export default function LoginBox({ children, username, setUsername, password, setPassword, setFacebookData, ...restProps }) {
  const [facebook, isFacebookReady] = useFacebook()

  const handleFacebookLogin = async () => {
    const response = await facebook.login()
    //console.log(response)
    setFacebookData({
      name: response.name,
      email: response.email,
      username: response.email,
      password: null,
      id: response.userID,
      fbToken: response.accessToken,
    })
  }
  
  return (
      <BoxContainer {...restProps}>
        <h3>LOGIN</h3>
        <ErrorText>{restProps.errResponse}</ErrorText>

        <FbLogin onClick={handleFacebookLogin} disabled={!isFacebookReady}><FbLogo src={FBBrandImgWhite} alt='L'/>Login with Facebook</FbLogin>

        <Form>
          <FormGroup>
            <Label htmlFor='username'>Username</Label>
            <TextInput id='username' name='username' autocomplete="username" value={username} onChange={(e)=>setUsername(e.target.value)}/>
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor='password'>Password</Label>
            <PwdInput id='password' name='password' value={password} onChange={(e)=>setPassword(e.target.value)} />
          </FormGroup>
          
        </Form>
      </BoxContainer>
  )
}
