import React from 'react'
import {Form, Label, Input, TextArea, SubmitBtn, Text, TextSmall, Link, Radio, RadioBtn, RadioText, RadioLabel} from './styles/testform'


export default function TestForm({children, ...restProps}) {
    return(
        <Form {...restProps}>{children}</Form>
    )
}

TestForm.Label = function TestFormLabel({children, ...restProps}) {
    return <Label {...restProps}>{children}</Label>
}

TestForm.Input = function TestFormInput({passedRef, ...restProps}) {
    return <Input ref={passedRef} {...restProps} />
}

TestForm.Radio = function TestFormRadio({ grid, id, children, ...restProps}) {
    return( <Radio grid={grid}>
                <RadioBtn id={id} {...restProps}/>
                <RadioLabel htmlFor={id}>{children}</RadioLabel>
            </Radio> 
    )
}

TestForm.TextArea = function TestFormTextArea({...restProps}) {
    return <TextArea {...restProps} />
}

TestForm.SubmitBtn = function TestFormSubmitBtn({children, ...restProps}) {
    return <SubmitBtn {...restProps}>{children}</SubmitBtn>
}

TestForm.Text = function TestFormText({children, ...restProps}) {
    return <Text {...restProps}>{children}</Text>
}

TestForm.TextSmall = function TestFormTextSmall({children, ...restProps}) {
    return <TextSmall {...restProps}>{children}</TextSmall>
}

TestForm.Link = function TestFormLink({to, children, ...restProps}) {
    return <Link to={to} {...restProps}>{children}</Link>
}