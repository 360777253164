import React from 'react'
import {Form, Details, FieldLabel, Input, Layout, Select} from './styles/addressForm'


export default function AddressForm({disabled,children, ...restProps}) {
    return(
        <Form disabled={disabled} {...restProps}>{children}</Form>
    )
}               

AddressForm.Details = function AddressDetails({disabled, children, ...restProps}){
    return <Details {...restProps}>{children}</Details>
}

AddressForm.Layout = function AddressFormLayout({gridAreaDef, children, ...restProps}) {
    return <Layout gridDef={gridAreaDef} {...restProps}>{children}</Layout>
}

AddressForm.FieldLabel = function AddressFieldLabel({id, children, ...restProps}) {
    return <FieldLabel id={id} {...restProps}>{children}</FieldLabel>
}

AddressForm.Input = function AddressInput({passedRef, type, id, onChange, onBlur, ...restProps}) {
    return <Input ref={passedRef} type={type} id={id} onChange={onChange} onBlur={onBlur}{...restProps} />
}

AddressForm.Select = function AddressInputStuled({children, passedRef, id, onChange, onBlur, ...restProps}) {
    return <Select ref={passedRef} id={id} onChange={onChange} onBlur={onBlur} {...restProps}>{children}</Select>
}
