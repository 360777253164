export const HOME = '/'

export const LOGIN = '/login'
export const SIGNUP = '/signup'
export const PROFILE = '/p/:username'
export const NOT_FOUND = '/not-found'
export const DEV_TEST = '/devtest'
export const DEV_TEST2 = '/devtest2'
export const DEV_TEST3 = '/devtest3'
export const DEV_TEST4 = '/devtest4'
export const DEV_TEST5 = '/devtest5'
export const DEV_TEST6 = '/devtest6'

export const ADD_PROP = '/add-prop'
export const PROP_SUMMARY = '/pages'

//export const ADD_COM_PROP = '/add-prop/commercial'
//export const ADD_RES_PROP = '/add-prop/residential'

export const ADD_PROP_2 = '/add-prop/details_1'
export const ADD_PROP_ADDR = '/add-prop/addr'
export const ADD_PROP_MAP = '/add-prop/map'
export const ADD_PROP_COM_BASIC = '/add-prop/COM'
export const ADD_PROP_RES_BASIC = '/add-prop/RES'
export const ADD_PROP_MXD_BASIC = '/add-prop/MXD'
export const ADD_PROP_LND_BASIC = '/add-prop/LND'
export const PREFERENCES = '/preferences'
export const MANAGE_COOKIES = '/preferences/manage-cookeis'
export const USER = '/user'

export const MAPWORKSPACE = '/map'
