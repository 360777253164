import styled from 'styled-components';
import { Link } from 'react-router-dom'

export const Container = styled.section`
    margin: 0.5em 0.5em 0em -0.5em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  
    /* border: 1px solid red; */

    /* overflow: hidden; */
`;

export const NavLink = styled(Link)`
    font-size: 0.65rem;
    color: darkgreen;
    font-weight: 600;
    padding: 0 0.3em;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 3px;
    letter-spacing: .3px;

    &:visited {
        color: darkgreen;
    }
    &:hover {
        border: 1px solid darkgreen;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    }
    ${Container} > &:first-child {
        /* margin-left: -1.25em; */
    }
    ${Container} > &:last-child {
        margin-right: 1.5em;
    }
    
`;

export const DropBtn = styled.button`
  font-size: 0.65rem;
  font-weight: 600;
  padding: 0 0.3em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  letter-spacing: 0.3px;
  
  border: none;
  outline: none;
  color: darkgreen;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
  /* border: 1px solid fuchsia; */
  
  &:hover {
        /* border-bottom: 1px solid darkgreen; */
        border: 1px solid darkgreen;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

    }
`;

export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  top:40px;
  background-color: #f9f9f9;
  min-width: 60px; /** 160px */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;

  & ${NavLink} {
    float: none;
    color: darkgreen;
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    /* border: 1px solid red; */
  }

  & ${NavLink}:hover {
    background-color: #ddd;
  }


`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 1px;

  /* border: 1px solid pink; */
  
  &:hover {
      background-color: inherit;
  }

  &:hover ${DropDownContent} {
    display: block;
  }
  &:hover ${DropBtn} {
      border: 1px solid darkgreen;
  }
`;

