import React from 'react'
import { Container, NavLink, DropDown, DropBtn, DropDownContent } from './styles/navbar'

export default function Navbar({ children, ...restProps }) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

Navbar.NavLink = function NavbarNavLink({ to, children, ...restProps }) {
    return <NavLink to={to} {...restProps}>{children}</NavLink>
}

Navbar.DropDown = function NavbarDropDown({ children, ...restProps }) {
    return <DropDown {...restProps}>{children}</DropDown>
}

Navbar.DropBtn = function NavbarDropBtn({ children, ...restProps }) {
    return <DropBtn {...restProps}>{children}</DropBtn>
}

Navbar.DropDownContent = function NavbarDropDownContent({ children, ...restProps }) {
    return <DropDownContent {...restProps}>{children}</DropDownContent>
}