import React from 'react'
import { Container, H3, CopyrightText } from './styles/footer.js'

export default function Footer({children, ...restProps}){
  return <Container {...restProps}>{children}</Container>
}

Footer.H3 = function FooterH3({children, ...restProps}){
  return <H3 {...restProps}>{children}</H3>
}

Footer.CopyrightText = function FooterCopyrightText({children, ...restProps}){
  return <CopyrightText {...restProps}>{children}</CopyrightText>
}