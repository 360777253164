import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { trimGpsCoords } from '../../helpers/geo-helpers'
import * as MAPPINGMODES from '../../constants/geocodeDefaultStructs'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import './styles/map.css'
import { MapPopupMedium } from '../index.js'
import { sysConfig } from '../../config'

import { BASE_URL } from '../../services/BaseURL'

mapboxgl.accessToken = sysConfig.REACT_APP_MAPBOX_TOKEN

// TODO: Change mapFeatures to GeoJSON Points w/ information fields
// mapFeatures = [ {lng: -86, lat: 30} ]  Array of point objects

const MAPSTYLES = ['mapbox://styles/mapbox/satellite-streets-v11?optimize=true', 'mapbox://styles/mapbox/streets-v11?optimize=true']

export function DisplayResultsMap({
  mapFeatures = [{ type: 'feature', geometry: { type: 'Point', coordinates: [null, null] }, properties: { zoom: 0 } }],
  center, // = [-30,30],
  zoom = 5,
  zoomToLastPin = false,
  setGpsPos,
  setLastMarker,
  setPinDropPos,
  switchMap = false,
  mode = MAPPINGMODES.ADDRMODE,
  NAVCTRL,
  h = 200,
  w = 200,
  bdr = '7px solid gray',
  br = 25,
  DEBUG = false,
  pinDim = { width: '45px', height: '45px' },
  recenterAndZoom = false
}) {
  const [mapStyle, setMapStyle] = useState(MAPSTYLES[0])

  const boundsDistance = useRef([0, 0])


  // exportGpsPos is [lng, lat]
  //const [exportGpsPos, setExportGpsPos] = useState(center)

  // mapCenterCoords is [lng, lat] so is center.
  const [mapCenterCoords, setMapCenterCoords] = useState(center)

  // ContextCoords is [x, y] screen coords - Not used at present
  const [contextCoords, setContextCoords] = useState([0, 0])

  // geoPoints is an array of map features: [ {type:'feature, geometry:{ type: 'point',coordinates:[lng,lat]}, properties:{zoom: 0}} ]
  const [geoPoints, setGeoPoints] = useState([{ type: 'feature', geometry: { type: 'Point', coordinates: [null, null] }, properties: { zoom: 0 } }])

  const [pointSetFlag, setPointSetFlag] = useState(false)

  const [markerList, setMarkerList] = useState([])

  const [updateGeoPoints, setUpdateGeoPoints] = useState(false)

  // To handle mapSwitch initial glitches, 'initialized' is set as the last step of map instantiation
  const [initialized, setInitialized] = useState(false)
  const [mapSwitch, setMapSwitch] = useState(false)

  const [curZoomLevel, setCurZoomLevel] = useState(0.15)
  const [curMapCenter, setCurMapCenter] = useState(center)

  const [_pinDropPos, _setPinDropPos] = useState({ gps: [null, null], zoom })
  //const [_pinDropPos, _setPinDropPos] = useState({gps:center, zoom})
  const [zoomToPin, setZoomToPin] = useState(false)
  const [reCenterAndZoom, setReCenterAndZoom] = useState(false)

  const [initialLoad, setInitialLoad] = useState(true)


  const mapContainer = useRef()
  const map = useRef()


  useEffect(() => {
    console.log("CENTER: ", center)
  }, [])

  function mouseEnter() {
    console.log("MOUSE ENTER")
  }



  function clearMarkers() {
    markerList.forEach(mark => mark.remove())
    setMarkerList([])
    setUpdateGeoPoints(false)

    setInitialLoad(true)

  }

  // TODO: Write the Context Menu handler
  const userContextMenu = (e) => {
    e.preventDefault()
    const { pageX, pageY } = e
    setContextCoords([pageX, pageY])
    if (DEBUG) {
      console.log(`ContextMenu right - click: [x: ${pageX}, y: ${pageY}]`)
    }
  }


  //
  // SWITCH MAP STYLE Handling
  // 
  // 
  //useLayoutEffect(()=>{ setMapSwitch(false) },[])

  useEffect(() => {
    // Toggle map style on signal
    //console.log("MapSwitch: initialized: ", initialized)
    if (initialized) {
      setMapSwitch(true)
      setCurZoomLevel(map.current.getZoom())
      // console.log("setCurZoomLevel: ", map.current.getZoom())
      setCurMapCenter(map.current.getCenter())
      // console.log("CENTER: ", map.current.getCenter())
      // Set the current map style
      setMapStyle(switchMap)// ? MAPSTYLES[1] : MAPSTYLES[0] )
      // Switch the actual map
      // console.log("Start setStyle()....")
      map.current.setStyle(switchMap ? MAPSTYLES[1] : MAPSTYLES[0])
      // console.log("End setStyle()....")
      map.current.setCenter(curMapCenter)
      //map.current.setZoom( curZoomLevel )

      // Do NOT allow markers to redraw on a map switch:
      setPointSetFlag(true)
    }
  }, [switchMap])

  useEffect(() => {
    setZoomToPin(true)
  }, [zoomToLastPin])

  useEffect(() => {
    console.log("%cSetRecenterAndZoom set", "color:fuchsia")
    setReCenterAndZoom(true)
  }, [recenterAndZoom])


  useEffect(() => {
    console.log("CHANGE IN MAP FEATURES ARRAY")
    //console.log("MAP: setGeoPoints")

    // Allow map to redraw new pins on mapFeature changes
    //setPointSetFlag(false)

    // copy to internal state provider
    setGeoPoints(mapFeatures)
    setPointSetFlag(false)

    setCurZoomLevel(map.current.getZoom())

    // clear current markers on a change in mapFeatures
    clearMarkers()

  }, [mapFeatures])


  // MARKERS----------------------------
  useEffect(() => {

    console.log("map- mapFeatures: ", mapFeatures)
    // Array of GeoJSON points

    console.log("geoPoints: ", geoPoints && geoPoints)
    console.log("pointSetFlag: ", pointSetFlag)

    if (!mapSwitch && geoPoints && geoPoints.length > 0 && !pointSetFlag && geoPoints[0].geometry.coordinates[0] !== null) {  //&& !updateGeoPoints   !recenterAndZoom &&
      setPointSetFlag(true)

      console.log("%cGEOPOINTS: ", "color:fuchsia", geoPoints)

      geoPoints.forEach((geoPoint) => {
        console.log("GEOPOINT-COORDS", geoPoint.geometry.coordinates)
        const geoPointCoords = geoPoint.geometry.coordinates
        const geoPointIconSize = geoPoint.properties.iconSize || [35, 35]  //TODO: Change default size to pinDim dimensions
        const thumbnail = geoPoint.properties.image

        let el = document.createElement('div')
        el.className = 'mapbox_marker'
        el.style.width = (geoPointIconSize[0] || pinDim.width) + 'px'
        el.style.height = (geoPointIconSize[1] || pinDim.height) + 'px'

        // TODO: Make this a card w/ styled content
        // create a popup

        // Check for address Properties present and construct popup string....
        let popupStr = MapPopupMedium(thumbnail, geoPoint, trimGpsCoords(geoPointCoords), mouseEnter);
        const popup = new mapboxgl.Popup({ offset: 20 }).setHTML(popupStr)

        //console.log("CREATE NEW MARKER")
        ////////////////////////////////////////  CREATE MARKER ////////////////////////////////////
        //
        // Create the Marker and add it to the map
        // console.log("Instantiate marker at: ", point)
        const marker = new mapboxgl.Marker(el, {
          anchor: 'bottom',
          draggable: geoPoint.properties.draggable,
          color: 'green',
          scale: 10,
        })
          .setLngLat(geoPointCoords)
          .setPopup(popup)  // Attach popup to marker  NOTE: COULD have used .getPopupt() instead to create a bound popup instance
          .on('dragstart', () => {
            // console.log("DRAG BEGIN ------------------------------")
          })
          .on('dragend', () => {
            // When the pin is dragged and dropped, record the new GPS coordinates of
            // where the pin was placed and re-center the map on those coordinates.
            //
            const geoPointNDX = geoPoint.properties.index
            const { lng, lat } = marker.getLngLat()
            // console.log("DRAG END---------------- ",[lng,lat])
            setLastMarker({ coords: [lng, lat], zoom: map.current.getZoom() })
            _setPinDropPos({ gps: [lng, lat], zoom: map.current.getZoom() })
            setMapCenterCoords([lng, lat])
            map.current.flyTo({
              center: [lng, lat],
            })
            popup.setHTML(MapPopupMedium(geoPoint, trimGpsCoords([lng, lat])))

            //update lng/lat in the geoPoint array entry
            const modGeoPoints = geoPoints.map(gp => {
              return (
                (gp.properties.index === geoPointNDX) ?
                  { ...gp, geometry: { coordinates: [lng, lat] }, } :  //properties:{...gp.properties, zoom: map.current.getZoom() 
                  gp
              )
            }
            )
            //console.log("Modified geoPoints: ", modGeoPoints)
            setUpdateGeoPoints(true)
            setGeoPoints(modGeoPoints)

          })
          .on('drag', () => {
            // TODO: Add configuration bit to disable/enable marker updating during drags
            const { lng, lat } = marker.getLngLat()
            popup.setHTML(MapPopupMedium(geoPoint, trimGpsCoords([lng, lat])))
          })
          .addTo(map.current);

        // Add the marker to the markerList:
        !mapSwitch && setMarkerList(markerList => [...markerList, marker])
        //console.log("Marker List: ", markerList)
      })

      // Arbitrarily take the first point at present.
      // TODO: Later we'll bound the group if more than one loc is present.
      //console.log( ".setCenter To: ",geoPoints[0])

      // geoPoints is an array of Point objects 
      let [lng, lat] = geoPoints[0].geometry.coordinates

      // Re-center the map - only on pin drop
      // Function expects an array comprised of two values [lng,lat]. Thats it. Don't change.
      // Set the zoom to a decent zoom-in level: 18 seems good...
      // If only one point, get the zoom from it. Otherwise use arbitrary default.
      // TODO: implement bounding box for two or more points
      //       Fix centering with more than one point.
      //

      //console.log("mapSwitch: ", mapSwitch )

      //  ZOOM Handling for all cases......
      if (reCenterAndZoom) {
        map.current.setZoom(zoom)
      } else if (mapSwitch) {
        //console.log("CurrentZoomLevel: ", curZoomLevel)
        map.current.setZoom(curZoomLevel)  // value preserved at the start of mapSwitch
      } else if (geoPoints[0].properties.zoom && geoPoints.length === 1) {
        //console.log("Marker ZOOM:", geoPoints[0].properties.zoom )
        map.current.setZoom(geoPoints[0].properties.zoom)
        //map.current.setZoom( curZoomLevel )
      } else if (geoPoints.length > 1) {
        console.log("%cCurZoom: ", "color:red", curZoomLevel)
        map.current.setZoom(curZoomLevel)
      } else { map.current.setZoom(7) }  //7  curZoomLevel


      // Map Centering for All Cases....
      if (reCenterAndZoom) {
        map.current.setCenter(center)
        [lng, lat] = geoPoints[0].geometry.coordinates
        // map.current.setZoom(zoom)
        setReCenterAndZoom(false)
      } else if (mapSwitch) {
        map.current.setCenter(curMapCenter)
      } else {
        //map.current.setCenter([lng, lat])
        map.current.setCenter(geoPoints[0].geometry.coordinates)
      }
      _setPinDropPos({ gps: map.current.getCenter(), zoom: map.current.getZoom() })
      //console.log("OSM-Center: ", map.current.getCenter())
      //console.log("OSM-Zoom: ", map.current.getZoom())
      //map.current.setCenter( {lng,lat})

      setMapCenterCoords([lng, lat])
      setMapSwitch(false)

    } else {
      if (mapSwitch) {
        map.current.setCenter(curMapCenter)
        map.current.setZoom(curZoomLevel)
        setMapSwitch(false)
      }
    }

    return () => { }
  }, [geoPoints, mapStyle])  // , mapStyle



  useEffect(() => {
    console.log("Zoom to Pin: ", JSON.stringify(_pinDropPos))
    map.current.setCenter(_pinDropPos.gps)
    map.current.setZoom(Math.min(19, _pinDropPos.zoom))  // Maximum zoom set to 19. //TODO: make user configurable
    setMapCenterCoords(_pinDropPos.gps)
    setZoomToPin(false)
  }, [zoomToPin])



  useEffect(() => {
    console.log(reCenterAndZoom)
    if (reCenterAndZoom) {
      console.log("%cCENTER: ", "color:red", center)
      console.log("%cZOOM: ", "color:red", zoom)
      //map.current.setCenter(center)
      //setMapCenterCoords(center)
      // setReCenterAndZoom(false)
      setCurMapCenter(center)
      //map.current.setZoom(zoom)
      map.current.flyTo({
        center: mapCenterCoords,
      })
    }

  }, [recenterAndZoom])


  //////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// CREATE THE MAP  //////////////////////////////////////////
  useLayoutEffect(() => {

    // if (DEBUG) {
    //   console.log('mapCenterCoords : ', mapCenterCoords )
    //   console.log('ZOOM: :', zoom)  // zoom is an attribute to Map
    // }

    console.log("mapCenterCoords : ", mapCenterCoords)

    //
    //center: [lng,lat] or {lng:30, lat:30}
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: MAPSTYLES[0],              //mapStyle,
      center: mapCenterCoords,
      zoom: curZoomLevel,
      doubleClickZoom: false,
    })

    if (NAVCTRL) {
      map.current.addControl(new mapboxgl.NavigationControl())
    }

    // Center the map on the coordinates of any clicked symbol from the 'symbols' layer.
    //Ok, this works....
    map.current.on('dblclick', function (e) {
      //console.log('Re-ceter map on clicked symbol++++++++++++++++++++++++')
      map.current.flyTo({
        center: [e.lngLat.lng, e.lngLat.lat],
      })
      if (setGpsPos) {
        setGpsPos([e.lngLat.lng, e.lngLat.lat])
      }
    })

    map.current.on('zoom', () => {
      //console.log("Bounds: ", map.current.getBounds())
      // const bounds = map.current.getBounds()
      // const neX = bounds._ne.lng
      // const neY = bounds._ne.lat
      // const swX = bounds._sw.lng
      // const swY = bounds._sw.lat
      // const distanceSquared = Math.sqrt(Math.pow((neX - swX), 2) + Math.pow((neY - swY), 2))
      // console.log("DELTA: ", distanceSquared, " : ", boundsDistance.current[0])
      // const deltaDist = Math.abs(Math.abs(distanceSquared) - Math.abs(boundsDistance.current[0]))
      // if (deltaDist[0] - deltaDist[1] < .01)
      //   console.log("Delta-distance:", deltaDist)
      // boundsDistance.current = [boundsDistance.current[1], deltaDist]
      clearTimeout(boundsDistance.current)
      boundsDistance.current = setTimeout(() => {
        console.log("Bounds: ", map.current.getBounds())
      }, 170)

    })

    map.current.on('click', (e) => {
      const { ctrlKey } = e.originalEvent;
      //console.log("MapClick: ", e)

      // var features = map.current.queryRenderedFeatures(e.point, {});
      // console.log("FEATURES: ", features)

      if (setGpsPos) {
        console.log("Map-OnClick: ", [e.lngLat.lng, e.lngLat.lat])
        setGpsPos([e.lngLat.lng, e.lngLat.lat])
        if (ctrlKey && mode === MAPPINGMODES.MAPMODE) {
          _setPinDropPos({ gps: [e.lngLat.lng, e.lngLat.lat], zoom: map.current.getZoom() })
          setPinDropPos({ gps: [e.lngLat.lng, e.lngLat.lat], zoom: map.current.getZoom() })
        }
      }
    })

    //setInitialized(true)

    map.current.on('load', () => {
      //console.log("MAP ON LOAD called =======================")
      setInitialized(true)

    })

    return () => { map.current.remove() }
  }, [])
  //}, [mapStyle])

  console.log("CurrentmapCenterCoords : ", mapCenterCoords)
  console.log("markerList Length: ", markerList.length)

  return (
    <div
      onContextMenu={userContextMenu}
      ref={mapContainer}
      style={{
        height: h,
        width: w,
        borderRadius: br,
        border: bdr,
        backgroundColor: 'navy',
      }}
    />
  )
}
