// <div className="h-20 w-full m-0 p-0 flex items-center justify-center  border-gray-500">
// <h3 className="font-bold p-0 m-0 text-gray-400">footer</h3>

import React from 'react'
import Footer from '../components/Footer'

export default function FooterContainer() {
    return(
        <Footer>
	    <a href="mailto:info@ribbitpropertydata.com" style={{textDecoration:'none'}}>
	      <h4 style={{color:'white', opacity:'.5'}}>info@ribbitpropertydata.com</h4>
	   </a><br />
            <Footer.CopyrightText>Copyright 2021 Ribbit Property Data</Footer.CopyrightText>
        </Footer>
    )
}
