import React, {useState, useContext, useEffect} from 'react'
import {countrySelectOptionList, currencyByCountryAbv, getCntryNameByAbv} from '../../services/countryCodeSvc.js'
import {currencySelectList1, currencySymbolByISO} from '../../services/currencies'

import {RibbitStdCommercialTypesII, 
        RibbitStdResidentialTypesII, 
        RibbitStdLandTypesII, 
        RibbitStdMixedUseTypesII, 
        RibbitPrimaryPropTypesI, 
        RibbitStdSpaceUseClassCodes} from '../../constants/RibbitPropertyTypes'

import {SearchBar} from '../'
import {AppContext} from '../../contexts/AppContext'


export default function SearchBarTop( props ){

  const { localCntry } = useContext(AppContext) // Set/Modify user's Country

  const [countrySel,        setCountrySel         ] = useState('')      // Country
  const [searchText,        setSearchText         ] = useState('')      // Text for textsearch
  const [pType,             setPType              ] = useState('COM')   // Property Type: COM/RES/MXD/LND/BNB
  const [tType,             setTType              ] = useState('SAL')   // Transaction Type  SALE/LEASE
  const [propTypeQueryList, setPropTypeQueryList  ] = useState([])      // Property Types List 
  const [spaceUseQueryList, setSpaceUseQueryList  ] = useState([])      // Space Use List of selected uses (checkboxes)
  const [currencyVal,       setCurrencyVal        ] = useState('AUD')   // Currency value for Select control - hold state only - D/N Search w/ this value
  const [srchCur,           setSrchCur            ] = useState()        // Currency to search for in query - Search with THIS value instead!!
  const [minPrice,          setMinPrice           ] = useState(0)       // Minimum Price
  const [maxPrice,          setMaxPrice           ] = useState(0)       // Maximum Price
  const [minSize,           setMinSize            ] = useState(0)       // Minimum Size
  const [maxSize,           setMaxSize            ] = useState(0)       // Maximum Size
  const [minLeaseRate,      setMinLeaseRate       ] = useState(0)       // Lease Rate, Mimimum
  const [maxLeaseRate,      setMaxLeaseRate       ] = useState(0)       // Lease Rate, Maximum
  const [priceType,         setPriceType          ] = useState()        // Price Type - Fixed Currency or OTH: select from list of currencies - STATE Variable only
  const [sizeUnit,          setSizeUnit           ] = useState()        // Building Size Area Unit
  const [sortOrder,         setSortOrder          ] = useState()        // Sort field - field & direction
  const [leaseRateTimePPUA, setLeaseRateTimePPUA  ] = useState()        // Price Per Unit Area per unit Time - [ft/m mo/yr]
  const [minSA,             setMinSA              ] = useState(0)       // Space Available, mimimum
  const [maxSA,             setMaxSA              ] = useState(0)       // Space Available, maximum
  const [sAAU,              setSAAU               ] = useState()        // Space Available Area Units: SqFt, SqM
 
  
 // eslint-disable-next-line react-hooks/exhaustive-deps
  let searchCriteria = {
    ct:    countrySel,        // string  ct:  country
    st:    searchText,        // string  st:  searchText
    pt:    pType,             // string  pt:  propType
    tt:    tType,             // string  tt:  transType
    ptl:   propTypeQueryList, // Array   ptl: propTypes
    sul:   spaceUseQueryList, // Array   sul:  spaceUses
    pn:    minPrice,          // number  pn:  minPrice
    px:    maxPrice,          // number  px:  maxPrice
    sn:    minSize,           // number  sn:  minSize
    sx:    maxSize,           // number  sx:  maxSize
    su:    sizeUnit,          // string  su:  sizeUnit
    lrn:   minLeaseRate,      // number  lrn: lseRateMin
    lrx:   maxLeaseRate,      // number  lrx: lseRateMax
    lrt:   leaseRateTimePPUA, // string  lrt: lseRateTime
    san:   minSA,             // number  san: spaceAMin
    sax:   maxSA,             // number  sax: spaceAMax
    sau:   sAAU,              // string  sau: spaceAUnits
    so:    sortOrder,         // string  so:  sortOrder
    pp:    priceType,         // string  pp:  priceType   - Price Per (unit)
    cr:    srchCur,           // string  cr:  crncy       - Use if priceType is OTH
  }

  function createQueryStr( sc ) {
    let qStr = "?"
    for(let key in sc ) {
      console.log("SearchKey: ", key, " Value: ",sc[key] )
      switch(key) {
        case  `sul` :
        case  'ptl' : { if( sc[key].includes('*') ) {
                            qStr += `${key}=*&`
                        } else {
                          for(let type in sc[key]){
                            qStr += `${key}=${sc[key][type]}&`
                          }
                        }; break;}
        case 'ct'   : { if (sc[key] === '---') continue; }          // if country equals '---' continue (ignore this value), else fall through.
        default : { if(sc[key]){ qStr += `${key}=${sc[key]}&`}; break;}      
      }
    }
    console.log("qStr: ", qStr.slice(0,qStr.length-1))
    return qStr.slice(0,qStr.length-1)
  }

  useEffect(()=>{
    createQueryStr( searchCriteria )
  },[searchCriteria])

  
  const ptypesSelectList = RibbitPrimaryPropTypesI.map(ptype => (<option key={ptype.ab} value={ptype.ab}>{`${ptype.nm.toUpperCase()}`}</option>))


  // const propTypeList = [{nm:"Select All",             ab:'ALL'},
  //                       {nm:"Office",                 ab:'OFF'},
  //                       {nm:"Industrial",             ab:'IND'},
  //                       {nm:"Retail",                 ab:'RTL'},
  //                       {nm:"Restaurant",             ab:'RST'},
  //                       {nm:"Shopping Center",        ab:'SHC'},
  //                       {nm:"Multifamily",            ab:'MTF'},
  //                       {nm:"Specialty",              ab:'SPC'},
  //                       {nm:"Health Care",            ab:'HCR'},
  //                       {nm:"Sports & Entertainment", ab:'SAE'},
  //                       {nm:"Land",                   ab:'LND'} ]

  // const propTypesCheckList = propTypeList.map((el,i)=>(<SearchBar.CheckBoxItem id={`p${i}`} name="propType" onChange={()=>console.log(`p${el.nm}`)} value={`${i}`}> {`${el.nm}`} </SearchBar.CheckBoxItem>))


  const ribbit_COM_PRopTypeCheckList = RibbitStdCommercialTypesII.map((el,i)=>(<SearchBar.CheckBoxItem id={`p${++i}`} name="propType" onChange={checkboxHndlr} value={`${el.ab}`} checked={propTypeQueryList.includes(`${el.ab}`)}>{`${el.nm}`} </SearchBar.CheckBoxItem>))

  const ribbit_RES_PropTypeCheckList = RibbitStdResidentialTypesII.map((el,i)=>(<SearchBar.CheckBoxItem id={`p${++i}`} name="propType" onChange={checkboxHndlr} value={`${el.ab}`} checked={propTypeQueryList.includes(`${el.ab}`)}>{`${el.nm}`}</SearchBar.CheckBoxItem>))

  const ribbit_LND_PropTypeCheckList = RibbitStdLandTypesII.map((el,i)=>(<SearchBar.CheckBoxItem id={`p${++i}`} name="propType" onChange={checkboxHndlr} value={`${el.ab}`} checked={propTypeQueryList.includes(`${el.ab}`)}>{`${el.nm}`}</SearchBar.CheckBoxItem>))

  const ribbit_MXD_PropTypeCheckList = RibbitStdMixedUseTypesII.map((el,i)=>(<SearchBar.CheckBoxItem id={`p${++i}`} name="propType" onChange={checkboxHndlr} value={`${el.ab}`} checked={propTypeQueryList.includes(`${el.ab}`)}>{`${el.nm}`} </SearchBar.CheckBoxItem>))

  // DEFAULT - Select All option------------------------------
  const defaultPropTypeCheckBoxItem = <SearchBar.CheckBoxItem id={`p0`} name="propType" onChange={checkboxHndlr} value={`*`} checked={propTypeQueryList.includes(`*`)}>{`Select All`}</SearchBar.CheckBoxItem>
  

  // const spaceUseList = [
  //         {nm:"All",        ab:'*'  },
  //         {nm:"Office",     ab:"OFF"},
  //         {nm:"Coworking",  ab:"CWK"},
  //         {nm:"Industrial", ab:"IND"},
  //         {nm:"Retail",     ab:"RTL"},
  //         {nm:"Restaurant", ab:"RST"},
  //         {nm:"Flex",       ab:"FLX"},
  //         {nm:"Medical",    ab:"MED"},
  //         {nm:"Land",       ab:"LND"},
  //         {nm:"Storage",    ab:"STR"}
  //       ]

  const spaceUseSelectList = RibbitStdSpaceUseClassCodes.map((el,i) => (<SearchBar.CheckBoxItem id={`su${i}`} name="spaceUse" value={el.ab} onChange={(e)=>checkboxHndlr(e)} checked={spaceUseQueryList.includes(`${el.ab}`)}>{el.nm}</SearchBar.CheckBoxItem>))



  function propertyCheckList() {
    switch( pType ) {
      case 'COM' : return ribbit_COM_PRopTypeCheckList
      case 'RES' : return ribbit_RES_PropTypeCheckList
      case 'MXD' : return ribbit_MXD_PropTypeCheckList
      case 'LND' : return ribbit_LND_PropTypeCheckList
      case 'BNB' : return null //ribbit_BNB_PropTypeCheckList
      default: return ribbit_COM_PRopTypeCheckList
    }
  }

  function setPTypeHndlr(e) {
    const {value} = e.target
    // On a change in property type, clear these lists
    setPropTypeQueryList([])
    setSpaceUseQueryList([])
    //...and set the new property
    setPType( value )
  }

  // Non-React Kludge Alert!!
  // If the select is hit before the radio button is selected, 
  // set the radio button's checked property and set the value
  function currencySelectHndlr(e) {
     const {value} = e.target
     document.getElementById('scX').checked=true;
     setSrchCur( value )
     setCurrencyVal(value)
  }

  // Add SpaceUse items to query list
  function checkboxHndlr(e) {
      e.stopPropagation()
      const {name, value} = e.target
      console.log("(checkBoxHndlr): Name: ", name, "value: ",value)

      //Add and remove option from query list:
      if( name === "propType"){
        if(e.target.checked) {
          setPropTypeQueryList(prev => [...prev, value])
        } else {
          setPropTypeQueryList( prev => {
            return prev.filter( el => el !== value)
          })
        }
      } else if( name === "spaceUse" ) {
          if(e.target.checked) {
            setSpaceUseQueryList(prev => [...prev, value])
          } else {
            setSpaceUseQueryList( prev => {
              return prev.filter( el => el !== value)
            })
          }
      }
  }

  //
  // Handler for ALL Radio Button Selections
  //
  function radioButtonHndlr(e) {
    e.stopPropagation()
    const {name, value} = e.target
    
    console.log("(radioButtonHandlr): name: ", name, "value: ",value)

    if(e.target.checked) {
      switch( name ) {
        case 'priceType'  : setPriceType(value); break;
        // eslint-disable-next-line no-lone-blocks
        case 'searchCurrency': { if(value === 'OTH') {
                                   setSrchCur( currencyVal )
                                   //console.log("CurrencySelect: ", currencyVal)
                                 } else {
                                   setSrchCur( value )
                                 }
                               }; break;
        case 'sizeUnit'   : setSizeUnit(value); break;
          // Sort direction handler
        case 'searchSort' : setSortOrder(value); break;
          // Lease Rate Time-based Price Per Unit Area handler
        case 'lrPPUA'     : setLeaseRateTimePPUA(value); break;
          // Space Available Area Units handler
        case 'sa_measure' : setSAAU(value); break;
        default: ;
      }
    }
  }

  return (
    <SearchBar>
      <SearchBar.Row>

        <SearchBar.Select placeholder="Search Select" name="cntryVal" value={countrySel} onChange={(e)=>setCountrySel(e.target.value)}>{countrySelectOptionList}</SearchBar.Select>
        
        <SearchBar.TextInput name="searchText" placeholder="Location Search" value={searchText} onChange={(e)=>setSearchText(e.target.value)} />

        {/* PROPERTY TYPE ----------------------------------------------------------------------------------------------------------------------*/}
        <SearchBar.Select placeholder="COM" name="pType" value={pType} onChange={(e)=>setPTypeHndlr(e)}>
              {ptypesSelectList}
        </SearchBar.Select>
        

        { pType !=='BNB' ? (
          <SearchBar.Group>

            {/* ---------------------------------- T R A N S A C T I O N   T Y P E -------------------------------------------------------------- */}
              <SearchBar.Select  name='tType' value={tType} onChange={(e)=>setTType(e.target.value)} >
                  <option value='SAL'>SALE</option>
                  <option value='LSE'>LEASE</option>
                  {/* <option value='INV'>INVEST</option> */}
              </SearchBar.Select>            
        

            {/* if SALE Type ----------------------------------------------------------------------------------------------------------------------*/}
            { tType === 'SAL' ? (
                <SearchBar.Group>

                  {/* -------------------- P R O P E R T Y   T Y P E S -------------------------------------------------------------------  */}
                  <SearchBar.DropDown>
                    <SearchBar.DropBtn>Property Types </SearchBar.DropBtn>
                    <SearchBar.DropDownContent>
                      {defaultPropTypeCheckBoxItem}
                      {propertyCheckList()}
                    </SearchBar.DropDownContent>
                  </SearchBar.DropDown>

                  {/* -------------------- P R I C E -------------------------------------------------------------------  */}
                  <SearchBar.DropDown>
                    <SearchBar.DropBtn>PRICE </SearchBar.DropBtn>
                    <SearchBar.DropDownContent>

                      <SearchBar.TextInputItem id="min_price" name='min_price' value={minPrice} onChange={(e)=>setMinPrice(e.target.value)}>MIN:</SearchBar.TextInputItem>
                      <SearchBar.TextInputItem id="max_price" name='max_price' value={maxPrice} onChange={(e)=>setMaxPrice(e.target.value)}>MAX:</SearchBar.TextInputItem>

                      <div className="priceType">
                        <p><strong>Price Per:</strong></p><hr />  {/** //priceTypeHndlr */}
                        <SearchBar.RadioButtonItem id="PT1" name="priceType" value="TOT" onChange={radioButtonHndlr}>Total</SearchBar.RadioButtonItem>
                        <SearchBar.RadioButtonItem id="PT2" name="priceType" value="PSF" onChange={radioButtonHndlr}>Per SqFt</SearchBar.RadioButtonItem>
                        <SearchBar.RadioButtonItem id="PT3" name="priceType" value="PSM" onChange={radioButtonHndlr}>Per SqM</SearchBar.RadioButtonItem>
                        <SearchBar.RadioButtonItem id="PT4" name="priceType" value="PAC" onChange={radioButtonHndlr}>Per Acre</SearchBar.RadioButtonItem>
                        <SearchBar.RadioButtonItem id="PT5" name="priceType" value="PHA" onChange={radioButtonHndlr}>Per Hectare</SearchBar.RadioButtonItem>
                        <SearchBar.RadioButtonItem id="PT6" name="priceType" value="PUN" onChange={radioButtonHndlr}>Per Unit</SearchBar.RadioButtonItem>
                      </div>

                      <div className="currency">
                        <p><strong>Currency:</strong></p><hr /> {/** setSrchCurHndlr */}
                        <SearchBar.RadioButtonItem id="sc0" name="searchCurrency" value={currencyByCountryAbv(localCntry)} onChange={radioButtonHndlr}>
                            {`${currencyByCountryAbv(localCntry)} - ${getCntryNameByAbv(localCntry)} - ${currencySymbolByISO(currencyByCountryAbv(localCntry))}`}
                        </SearchBar.RadioButtonItem>
                        <SearchBar.RadioButtonItem id="sc1" name="searchCurrency" value="USD" onChange={radioButtonHndlr}>USD</SearchBar.RadioButtonItem>
                        <SearchBar.RadioButtonItem id="sc2" name="searchCurrency" value="EUR" onChange={radioButtonHndlr}>EUR</SearchBar.RadioButtonItem>
                        <SearchBar.RadioButtonItem id="sc3" name="searchCurrency" value="CNY" onChange={radioButtonHndlr}>CNY / RMB</SearchBar.RadioButtonItem>     
                        <SearchBar.RadioButtonItem id="sc4" name="searchCurrency" value="BTC" onChange={radioButtonHndlr}>BTC</SearchBar.RadioButtonItem>
                        
                        <SearchBar.RadioButtonItem id="scX" name="searchCurrency" value="OTH" onChange={radioButtonHndlr}>Other: 
                              <SearchBar.Select placeholder="Currency-Other" name="currencyVal" value={currencyVal} onChange={(e)=>currencySelectHndlr(e)}>
                                  {currencySelectList1}
                              </SearchBar.Select>
                        </SearchBar.RadioButtonItem>
                      </div>

                    </SearchBar.DropDownContent>
                  </SearchBar.DropDown>

                  {/* -------------------- L A N D  /  B U I L D I N G   S I Z E -------------------------------------------------------------------  */}
                  <SearchBar.DropDown>
                    { pType === 'LND' ? <SearchBar.DropBtn>PARCEL SIZE </SearchBar.DropBtn>
                                      : <SearchBar.DropBtn>BLDG SIZE </SearchBar.DropBtn> }
                    <SearchBar.DropDownContent>
                      <SearchBar.TextInputItem id="min_size" name='min_size' value={minSize} onChange={(e)=>setMinSize(e.target.value)}>MIN:</SearchBar.TextInputItem>
                      <SearchBar.TextInputItem id="max_size" name='max_size' value={maxSize} onChange={(e)=>setMaxSize(e.target.value)}>MAX:</SearchBar.TextInputItem>
                      <SearchBar.RadioButtonItem id="SU1" name="sizeUnit" value="SqFt" onChange={radioButtonHndlr}>SqFt</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="SU2" name="sizeUnit" value="SqM" onChange={radioButtonHndlr}>SqM</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="SU3" name="sizeUnit" value="AC" onChange={radioButtonHndlr}>AC</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="SU4" name="sizeUnit" value="HA" onChange={radioButtonHndlr}>HA</SearchBar.RadioButtonItem>
                    </SearchBar.DropDownContent>
                  </SearchBar.DropDown>

                  {/* -------------------- S O R T  -------------------------------------------------------------------  */}
                  <SearchBar.DropDown>
                    <SearchBar.DropBtn>SORT</SearchBar.DropBtn>
                    <SearchBar.DropDownContent>     {/**  setSortHndlr */}
                      <SearchBar.RadioButtonItem id="S1" name="searchSort" value="P_lo2hi" onChange={radioButtonHndlr}>Price: low to high</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="S2" name="searchSort" value="P_hi2lo" onChange={radioButtonHndlr}>Price: high to low</SearchBar.RadioButtonItem>
                      { pType==="LND" ? (<>
                        <SearchBar.RadioButtonItem id="s5" name="searchSort" value="ps_lo2hi" onChange={radioButtonHndlr}>Parcel Size: low to high</SearchBar.RadioButtonItem>
                        <SearchBar.RadioButtonItem id="p6" name="searchSort" value="ps_hi2lo" onChange={radioButtonHndlr}>Parcel Size: high to low</SearchBar.RadioButtonItem>                    
                      </>):(<>
                        <SearchBar.RadioButtonItem id="S3" name="searchSort" value="S_lo2hi" onChange={radioButtonHndlr}>BldgSize: low to high</SearchBar.RadioButtonItem>
                        <SearchBar.RadioButtonItem id="S4" name="searchSort" value="S_hi2lo" onChange={radioButtonHndlr}>BldgSize: high to low</SearchBar.RadioButtonItem>
                      </>)}
                    </SearchBar.DropDownContent>
                  </SearchBar.DropDown>    
                  <SearchBar.Btn>More Filters </SearchBar.Btn>        

              </SearchBar.Group> 
            ) : null }
            


            {/* if LEASE Type ----------------------------------------------------------------------------------------------------------------------*/}
            { tType === 'LSE' ? (
              <SearchBar.Row>
                <SearchBar.DropDown>

                  {/* COMMERCIAL */}
                  <div hidden={(['RES','MXD','LND'].includes(pType))}>
                    <SearchBar.DropBtn>Space_Use </SearchBar.DropBtn>
                    <SearchBar.DropDownContent>
                      {spaceUseSelectList}
                    </SearchBar.DropDownContent>
                  </div>

                  {/* RESIDENTIAL / MIXED USE / LAND / BNB */}
                  <div hidden={(['COM'].includes(pType))}>
                    <SearchBar.DropBtn>Property Types </SearchBar.DropBtn>
                    <SearchBar.DropDownContent>
                      {defaultPropTypeCheckBoxItem}
                      {propertyCheckList()}
                    </SearchBar.DropDownContent>
                  </div>

                </SearchBar.DropDown>

                { pType === 'LND' ?  (<>
                  <SearchBar.DropDown>
                    <SearchBar.DropBtn>Lease Rate </SearchBar.DropBtn>
                    <SearchBar.DropDownContent>
                      <SearchBar.TextInputItem id="min_LR" name='min_leaseRate' value={minLeaseRate} onChange={(e)=>setMinLeaseRate(e.target.value)}>MIN:</SearchBar.TextInputItem>
                      <SearchBar.TextInputItem id="max_LR" name='max_leaseRate' value={maxLeaseRate} onChange={(e)=>setMaxLeaseRate(e.target.value)}>MAX:</SearchBar.TextInputItem>
                      {/** setLrTimePPUAHndlr */}
                      <SearchBar.RadioButtonItem id="lrPPUA_1" name="lrPPUA" value="SqFtYr" onChange={radioButtonHndlr}>SqFt/Yr</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="lrPPUA_2" name="lrPPUA" value="SqFrMo" onChange={radioButtonHndlr}>SqFt/Mo</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="lrPPUA_3" name="lrPPUA" value="SqMYr"  onChange={radioButtonHndlr}>SqM/Yr</SearchBar.RadioButtonItem>                  
                      <SearchBar.RadioButtonItem id="lrPPUA_4" name="lrPPUA" value="SqMMo"  onChange={radioButtonHndlr}>SqM/Mo</SearchBar.RadioButtonItem>                  
                      <SearchBar.RadioButtonItem id="lrPPUA_5" name="lrPPUA" value="ACYr"   onChange={radioButtonHndlr}>AC/Yr</SearchBar.RadioButtonItem>                  
                      <SearchBar.RadioButtonItem id="lrPPUA_6" name="lrPPUA" value="ACMo"   onChange={radioButtonHndlr}>AC/Mo</SearchBar.RadioButtonItem>                  
                      <SearchBar.RadioButtonItem id="lrPPUA_7" name="lrPPUA" value="HAYr"   onChange={radioButtonHndlr}>HA/Yr</SearchBar.RadioButtonItem>                  
                      <SearchBar.RadioButtonItem id="lrPPUA_8" name="lrPPUA" value="HAMo"   onChange={radioButtonHndlr}>HA/Mo</SearchBar.RadioButtonItem>                  
                    </SearchBar.DropDownContent>
                  </SearchBar.DropDown>

                   <SearchBar.DropDown>
                    <SearchBar.DropBtn>PARCEL SIZE </SearchBar.DropBtn>
                    <SearchBar.DropDownContent>
                      <SearchBar.TextInputItem id="min_size" name='min_size' value={minSize} onChange={(e)=>setMinSize(e.target.value)}>MIN:</SearchBar.TextInputItem>
                      <SearchBar.TextInputItem id="max_size" name='max_size' value={maxSize} onChange={(e)=>setMaxSize(e.target.value)}>MAX:</SearchBar.TextInputItem>
                      <SearchBar.RadioButtonItem id="SU1" name="sizeUnit" value="SqFt" onChange={radioButtonHndlr}>SqFt</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="SU2" name="sizeUnit" value="SqM" onChange={radioButtonHndlr}>SqM</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="SU3" name="sizeUnit" value="AC" onChange={radioButtonHndlr}>AC</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="SU4" name="sizeUnit" value="HA" onChange={radioButtonHndlr}>HA</SearchBar.RadioButtonItem>
                    </SearchBar.DropDownContent>
                  </SearchBar.DropDown>
                </>) : (<>
                  <SearchBar.DropDown>
                    <SearchBar.DropBtn>Lease Rate </SearchBar.DropBtn>
                    <SearchBar.DropDownContent>
                      <SearchBar.TextInputItem id="min_LR" name='min_leaseRate' value={minLeaseRate} onChange={(e)=>setMinLeaseRate(e.target.value)}>MIN:</SearchBar.TextInputItem>
                      <SearchBar.TextInputItem id="max_LR" name='max_leaseRate' value={maxLeaseRate} onChange={(e)=>setMaxLeaseRate(e.target.value)}>MAX:</SearchBar.TextInputItem>
                      {/** setLrTimePPUAHndlr */}
                      <SearchBar.RadioButtonItem id="lrPPUA_1" name="lrPPUA" value="SqFtYr" onChange={radioButtonHndlr}>SqFt/Yr</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="lrPPUA_2" name="lrPPUA" value="SqFrMo" onChange={radioButtonHndlr}>SqFt/Mo</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="lrPPUA_3" name="lrPPUA" value="SqMYr"  onChange={radioButtonHndlr}>SqM/Yr</SearchBar.RadioButtonItem>                  
                      <SearchBar.RadioButtonItem id="lrPPUA_4" name="lrPPUA" value="SqMMo"  onChange={radioButtonHndlr}>SqM/Mo</SearchBar.RadioButtonItem>                  
                    </SearchBar.DropDownContent>
                  </SearchBar.DropDown>

                  <SearchBar.DropDown>
                    <SearchBar.DropBtn>Available Space </SearchBar.DropBtn>
                    <SearchBar.DropDownContent>
                      <SearchBar.TextInputItem id="min_sa" name='min_sa' value={minSA} onChange={(e)=>setMinSA(e.target.value)}>MIN:</SearchBar.TextInputItem>
                      <SearchBar.TextInputItem id="max_sa" name='max_sa' value={maxSA} onChange={(e)=>setMaxSA(e.target.value)}>MAX:</SearchBar.TextInputItem>
                      {/** setSAAUHdlr */}
                      <SearchBar.RadioButtonItem id="sa_1" name="sa_measure" value="sqFt" onChange={radioButtonHndlr}>SqFt</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="sa_2" name="sa_measure" value="sqM"  onChange={radioButtonHndlr}>SqM</SearchBar.RadioButtonItem>
                    </SearchBar.DropDownContent>
                  </SearchBar.DropDown>
                </>)}

                <SearchBar.DropDown>
                  <SearchBar.DropBtn>SORT</SearchBar.DropBtn>
                  <SearchBar.DropDownContent>
                    <SearchBar.RadioButtonItem id="s1" name="searchSort" value="lr_lo2hi" onChange={radioButtonHndlr}>Lease Rate: low to high</SearchBar.RadioButtonItem>
                    <SearchBar.RadioButtonItem id="s2" name="searchSort" value="lr_hi2lo" onChange={radioButtonHndlr}>Lease Rate: high to low</SearchBar.RadioButtonItem>
                    { pType==="LND" ? (<>
                      <SearchBar.RadioButtonItem id="s5" name="searchSort" value="ps_lo2hi" onChange={radioButtonHndlr}>Parcel Size: low to high</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="p6" name="searchSort" value="ps_hi2lo" onChange={radioButtonHndlr}>Parcel Size: high to low</SearchBar.RadioButtonItem>                    
                    </>):(<>
                      <SearchBar.RadioButtonItem id="s3" name="searchSort" value="sa_lo2hi" onChange={radioButtonHndlr}>Avail Space: low to high</SearchBar.RadioButtonItem>
                      <SearchBar.RadioButtonItem id="s4" name="searchSort" value="sa_hi2lo" onChange={radioButtonHndlr}>Avail Space: high to low</SearchBar.RadioButtonItem>
                    </>)}
                  </SearchBar.DropDownContent>
                </SearchBar.DropDown>    

                <SearchBar.Btn>More Filters </SearchBar.Btn>                      

              </SearchBar.Row>
            ) : null }

            {/* { tType === 'INV' ? (
              <li hidden={ tType !== 'INV' }>
                <SearchBar.DropDown>
                  <SearchBar.DropBtn>Investment Types </SearchBar.DropBtn>
                  <SearchBar.DropDownContent>
                  <SearchBar.CheckBoxItem id="iOne" name="propType" value="1">Invest One</SearchBar.CheckBoxItem>
                    <SearchBar.CheckBoxItem id="iTwo" name="propType" value="2">Invest Two</SearchBar.CheckBoxItem>
                    <SearchBar.CheckBoxItem id="iThree" name="propType" value="3">Invest Three</SearchBar.CheckBoxItem>
                  </SearchBar.DropDownContent>
                </SearchBar.DropDown>
              </li>
            ) : null } */}

          </SearchBar.Group>
          ) : 
          // BNB Goes Here - Vacation Rental
          null }
        
      </SearchBar.Row>
    </SearchBar>    
  )
}