import React from 'react'
import {AppMain} from './styles/Main'

export default function Main({children, ...restProps}) {
    return(
        <AppMain {...restProps} >
            { children }
        </AppMain>
    )
}
