import React, { useState, useEffect } from 'react'
import './styles/SideNav.css'



export default function SideNav({position='right', hWidth='250px', children,  ...rest}) {
  const [isOpen, setIsOpen] = useState(false)
  const [width, setWidth] = useState( hWidth )
  

  useEffect(()=>{
    if(isOpen) { setWidth(hWidth) }
    else { setWidth("0px") }
  },[isOpen])
 
  let horizPos = position ==='left' ? 'left: 0;' : 'right: 0;'
  //console.log("Width: ", hWidth,width)
  console.log("IsOpen: ", isOpen)
  return(
    <>
      <div className="menuStrip" onMouseEnter={()=>setIsOpen(true)}></div>
      
      { !isOpen ? null : 
        <div {...rest} id="mySidenav" class="sidenav" style={{width, horizPos}}>
          <div class="closebtn" onClick={()=>setIsOpen(false)}>&times;</div>
          {children}
        </div>
      }
    </>
  )
}