export const MAPMODE = 'MAPMODE'
export const ADDRMODE = 'ADDRMODE'


// Used in AddProperty2 to hold mapFeatures (markers) sent to map
export const GEOCODE_DEFAULT_DATA_STRUCT = {
  type: 'feature',
  geometry: {
    type: 'Point',
    coordinates: [null, null]
  },
  properties: {
    RPID: "",
    zoom: 17.5,
    draggable: true,
    addr: {
      country: "",
      country_code: "",
      house_number: "",
      road: "",
      state: "",
      state_code: "",
      city: "",
      postcode: ''
    },
    bounds: {}, 
    coordinates: {}, 
    mgrs:  '', 
    maidenhead: '',
    mercator: {},
    geohash: '',
    currency: {},
    un_m49: {},
  }
}