/* 
	Copyright 2016-2021 by Downing Hopkins. All rights reserved.
	This code is proprietary and trade secret and may not
	be copied, stored or transmitted without the written
	permision of the author.
*/
/*
    -------------- RIBBIT PROPERTY TYPE CODES -------------------------------
    
    9/13/16:    Added Mass property codes.
    
    9/9/16:     Divided services into new files. Created typeServices.js 
                to hold all type details
    
    5/14/21:    added exports and array partitioning
                Extended subarrays to support infinite level of subclassification
    5/15/21:    Added 'No Selection' as terminator placeholder in subarrays.

    Dev Note:  Need to add ZONING classification service
               CHANGE TO USE BASE36 CODING
*/

// nm: name, ab: abbreviation  sc: sub-category(ies)[array]

//===================================================================
//      RIBBIT STANDARD PRIMARY PROPERTY CLASS - LEVEL I
//===================================================================
export const RibbitPrimaryPropTypesI = [
  {nm: 'Commercial',      ab:'COM', sc: null },
  {nm: 'Residential',     ab:'RES', sc: null },
  {nm: 'Land',            ab:'LND', sc: null },
  {nm: 'Mixed Use',       ab:'MXD', sc: null },
  {nm: 'Holiday / Vacation Rental', ab:"BNB", sc: null },
  ];

//===================================================================
//     RIBBIT STANDARD LAND TYPES - LEVEL II Classification
//===================================================================
export const RibbitStdLandTypesII = [
  {nm: 'Agricultural / Hortocultural / Timber',                     ab:'L010',
    sc: [
      //{nm: 'Productive Land',                                               ab:'OSPC-', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Farm',                                                          ab:'L002',     sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Cranberry Bog',                                                 ab:'OSPC-270', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Tobacco, Sod',                                                  ab:'OSPC-271', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Truck Crops - Vegetables',                                      ab:'OSPC-272', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Field Crops - Hay, wheat, tillable forage, etc',                ab:'OSPC-273', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Orchards - pears, apples, grape vineyards, etc.',               ab:'OSPC-274', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Christmas Trees',                                               ab:'OSPC-275', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Necessary related land-farm roads, ponds, bldg land',           ab:'OSPC-276', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Productive Woodland - woodlots',                                ab:'OSPC-277', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Pasture',                                                       ab:'OSPC-278', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Nurseries',                                                     ab:'OSPC-279', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      //{nm: 'NON-PRODUCTIVE LAND',                                           ab:'OSPC-', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Wet land, scrub land, rock land',                               ab:'OSPC-290', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm: 'Open Land',                                                 ab:'L015',
    sc:[
      {nm: 'Open Land - Residential',                                     ab:'L020', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Open Land - Residential/Multifamily',                         ab:'L025', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Open Land - Commercial',                                      ab:'L030', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Open Land - Rural',                                           ab:'L040', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Open Land - Industrial',                                      ab:'L050', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Open Land - Agricultureal',                                   ab:'L052', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Open Land - Other',                                           ab:'L060', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm: 'Forest Land',                                               ab:'L070',
    sc:[
      {nm: 'Productive woodland - woodlots',                              ab:'OSPC-280', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Tree Farm',                                                   ab:'L071', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm: 'Recreational',                                              ab:'L080',
    sc: [
      {nm: 'Hiking - Trails or Paths, Camping, Nature Study',             ab:'OSPC-281', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Boating - Lake',                                              ab:'OSPC-282', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Boating - River',                                             ab:'OSPC-282', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Golfing, Golf Courses',                                       ab:'OSPC-283', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Horseback Riding - Trails or areas',                          ab:'OSPC-284', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Hunting / Fishing areas',                                     ab:'OSPC-285', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Alpine Skiing - Downhill or Nordic',                          ab:'OSPC-286', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Swimming Areas, Picnicking Areas',                            ab:'OSPC-287', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Public, Non-Comercial Flying',                                ab:'OSPC-288', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Public, Non-commercial Target Shooting areas, Skeet, Etc.',   ab:'OSPC-289', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Forest - public',                                             ab:'OSPC-261', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]}, ] },
      
  {nm: 'Non-Productive Land',                                       ab:'L090', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Developable Land',                                          ab:'L100', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Potentially Developable Land',                              ab:'L110', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Undevelopable Land',                                        ab:'L120',
    sc:[
      {nm:'Undevelopable - General',                                      ab:'L122', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm:'Underwater Land or Marshes - Public',                          ab:'L124', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm:'Underwater Land or Marshes - NonPublic',                       ab:'L126', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },
  {nm: 'Land - Other',                                              ab:'L999', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]}
];

//===================================================================
//   RIBBIT STANDARD RESIDENTIAL TYPES - LEVEL II Classification
//===================================================================
export const RibbitStdResidentialTypesII = [
  {nm: 'Residence - House / Villa / Condo' ,                       ab:'R010',
    sc: [
      {nm: 'Single Family',                    ab:'R020', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Condominium',                      ab:'R030', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Mobile Home',                      ab:'R040', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Two-Family',                       ab:'R050', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Three-Family',                     ab:'R060', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Accessory Land',                   ab:'R070', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Multiple Houses',                  ab:'R080', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Prefab / Manufac',                 ab:'R090', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] }, 

  {nm:'Multifamily',                       ab:'RA10', 
    sc:[
        {nm:'Garden',                         ab:'RA11', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm:'Low-Rise',                       ab:'RA12', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm:'Mid-Rise',                       ab:'RA13', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm:'High-Rise',                      ab:'RA14', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm:'Dormitory',                      ab:'RA15', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm:'Manufactured Housing',           ab:'RA16', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm:'Independent / Builder Floor',       ab:'RB10', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm:'Farmhouse',                         ab:'RC10', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},

  {nm: 'Apartments',                       ab:'R110',
    sc: [
      {nm: 'Apt: Four to Eight Units',         ab:'R120', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Apt: Eight to 16 Units',           ab:'R130', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Apt: More than 16 Units',          ab:'R140', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm: 'Non-Transient Group Quarters',      ab:'R210', 
    sc: [
      {nm: 'Rooming and Boarding Houses',      ab:'R220', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Fraternity and Sorority Houses',   ab:'R230', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Residence Halls / Domitories',     ab:'R240', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Rectories, Convents, Monasteries', ab:'R250', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Other Congregate Housing',         ab:'R260', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm: 'Vacant Land in Residential Zone',  ab:'R310',
    sc: [
      {nm: 'Developable Land',                 ab:'R320', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Potentially Developable Land',     ab:'R330', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Undevelopable Land',               ab:'R340', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm: 'Other Facilities',                 ab:'R410',
    sc:[
      {nm: 'Child Care Facility',              ab:'R420', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Nursing Homes',                    ab:'R421', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Senior Care Living',               ab:'R422', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Memory Care Living',               ab:'R423', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Assisted Living',                  ab:'R424', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm: 'Residential - Other',              ab:'R999',
    sc:[
      {nm:'Community - Fly-In',               ab:'RZ10'},
      {nm:'Mobile Home Park',                 ab:'RZ20'},
      {nm:'Community - Gated',                ab:'RZ30'} ] }
];

//===================================================================
//    RIBBIT STANDARD COMMERCIAL TYPES - LEVEL II Classification
//===================================================================
export const RibbitStdCommercialTypesII = [
  
  {nm: 'Office - Buildings',                                 ab:'C000',
    sc: [
      {nm: '---- Select ----',                                 ab:'sel',  sc:[{nm:'-- No Selections --',ab:'n',sc: null }]},
      {nm: 'General Office Buildings',                         ab:'C010', 
                sc:[{nm: '---- Select ----', ab:'sel',  sc:null},
                    {nm:'TEST3',             ab:'test', sc: null}]},
      {nm: 'Office-LoRise',                                    ab:'C020', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Office-MidRise',                                   ab:'C030', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Office-HiRise',                                    ab:'C040', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Office Park',                                      ab:'C050', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Bank Buildings',                                   ab:'C060', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Medical Office Buildings',                         ab:'C070', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Industrial Live/Work',                             ab:'C080', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Loft/Creative Space',                              ab:'C090', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Medical',                                          ab:'C091', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Office/Storefront Retail',                         ab:'C092', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Office Live/Work Unit',                            ab:'C093', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Office/Residential',                               ab:'C094', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Telecom Hotel/Data Hotel',                         ab:'C095', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Office Buildings - Other',                         ab:'C099', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm: 'Transportation',                                   ab:'CX050',
    sc: [
      {nm: 'Airport',                                         ab:'CX051', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Airport - Hangar Facilities',                     ab:'CX052', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Airport - Terminal Facilities',                   ab:'CX053', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Ports',                                           ab:'CX054', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Ports - Piers and Wharfs',                        ab:'CS055', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Ports - Cruise Line Terminal',                    ab:'CS056', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm: 'Hospitality',                                   ab:'C100', //'TRANSIENT GROUP QUARTERS'
      sc: [
        {nm: 'Hotels',                                           ab:'C110', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Motels',                                           ab:'C120', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Hotel Casino',                                     ab:'C122', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Single Room Occupancy',                            ab:'C124', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Long Term Stay',                                   ab:'C126', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Inns, Resorts, Tourist Homes',                     ab:'C130', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Nursing Homes - not medical',                      ab:'C140', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Transient Group Quarters',                         ab:'CB50', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm:'Healthcare',                                    ab:'CB00',
      sc: [
        {nm: 'Medical Office',                                   ab:'CB10', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Hospital',                                         ab:'CB20', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Private Hospital',                                 ab:'CB30', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Congregate Senior Housing',                        ab:'CB31', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Continuing Care Retirement',                       ab:'CB32', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Rehabilitation Center',                            ab:'CB33', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Skilled Nursing Facility',                         ab:'CB34', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
        {nm: 'Care and Treatment Facilities',                    ab:'CB40', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },
        

  {nm: 'Storage / Warehouses / Distribution Facilities',     ab:'C200',
    sc: [
      {nm: 'Tanks - Fuel or Oil',                              ab:'C210', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Bottled Gas and Propane',                          ab:'C220', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Grain and Feed Elevators',                         ab:'C230', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Lumber Yards',                                     ab:'C240', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Trucking Terminals',                               ab:'C250', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Piers, Wharves, Docks and related',                ab:'C260', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Other Storage, Warehouse and Distribution',        ab:'C299', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Farm Buildings - barns, silos, utility etc',       ab:'C280', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Greenhouses, commercial',                          ab:'C290', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm: 'Retail Trade - General',                             ab:'C300',
    sc: [
      {nm: 'Bank',                                                ab:'C301', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Day Care Facility / Nursing Home',                    ab:'C302', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Drug Store',                                          ab:'C303', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Freestanding',                                        ab:'C304', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Service/Gas Station',                                 ab:'C306', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Showroom',                                            ab:'C307', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Storefront',                                          ab:'C308', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Storefront Retail/Office',                            ab:'C309', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Storefront Retail/Residential',                       ab:'C30A', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},      
      {nm: 'Facilities for building materials, hardware etc',     ab:'C310', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Discount Stores, Junior Dept stores, Dept Stores',    ab:'C320', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Shopping Centers, Malls',                             ab:'C330', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Strip Mall',                                          ab:'C335', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Grocery / Convenience Store',                         ab:'C336', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Supermarkets - excess of 10k sq.ft',                  ab:'C340', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Small Retails and Services Stores, under 10k sq.ft',  ab:'C350', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Eating and Drinking Establishments',                  ab:'C360', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Retail Trade - Other',                                ab:'C399', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },
      
  {nm: 'Retail Trade - Automotive, Marine, Engine',        ab:'C400',
    sc: [
      {nm: 'Vehicle Related',                                  ab:'C405', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Automotive Sales and Service',                     ab:'C410', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Automotive Supplies Sales and Service',            ab:'C420', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Auto Repair Facilities',                           ab:'C430', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Fule Service Areas - Providing engine repair',     ab:'C440', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Gasoline Service Stations',                        ab:'C450', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Car Wash Facilities',                              ab:'C460', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Parking Garages',                                  ab:'C470', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Parking Lots - commercial',                        ab:'C480', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Other Motor Vehicle Sales and Service',            ab:'C499', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm: 'Shopping Center', ab:'CS100', 
    sc:[ 
      {nm:'Airport Retail',                                    ab:'CS110', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm:'Community Centre',                                  ab:'CS120', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm:'Lifestyle Centre',                                  ab:'CS130', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm:'Neighborhood Centre',                               ab:'CS140', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm:'Outlet Centre',                                     ab:'CS150', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm:'Power Centre',                                      ab:'CS160', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm:'Regional Mall',                                     ab:'CS170', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm:'Theme/Festival Centre',                             ab:'CS180', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm:'Strip Centre',                                      ab:'CS190', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm:'Super Regional Mall',                               ab:'CS1A0', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

      
  {nm: 'Public Service',                            ab:'C500',
    sc: [
      {nm: 'Postal Service properties',                        ab:'C510', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Educational facitlities',                          ab:'C520', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Day Care Centers, Adults',                         ab:'C530', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Fraternal Organizations',                          ab:'C540', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Bus Transportation Facilities',                    ab:'C550', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Funeral Homes',                                    ab:'C560', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Miscellaneous Public Svcs',                        ab:'C570', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },


  {nm: 'Sports & Cultural Entertainment',            ab:'C600',
    sc: [
      {nm: 'Museums',                                          ab:'C610', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Art Galleries',                                    ab:'C620', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Motion Picture Theaters',                          ab:'C630', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Concert Hall',                                     ab:'C635', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Drive-in Movies',                                  ab:'C640', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Theaters, Legitimate',                             ab:'C650', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Stadiums',                                         ab:'C660', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Arenas and Fieldhouses',                           ab:'C670', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Race Tracks',                                      ab:'C680', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Fairgrounds and Amusement Parks',                  ab:'C690', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Other Cultural and Entertainment',                 ab:'C699', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm: 'Indoor Recreational Facilities',                   ab:'C700',
    sc: [
      {nm: 'Amusement Park - indoor',                          ab:'C705', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Bowling',                                          ab:'C710', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Casino',                                           ab:'C712', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Ice Skating',                                      ab:'C720', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Roller Skating',                                   ab:'C730', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Swimming Pools',                                   ab:'C740', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Health Spas',                                      ab:'C750', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Shooting / Pistol Range',                          ab:'C752', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Riding / Horse Arena',                             ab:'C754', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Tennis / Racquetball Clubs',                       ab:'C760', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Gymnasiums / Athletic Clubs',                      ab:'C770', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Archery, Billiards, Other indoor facilities',      ab:'C780', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Indoor Recreational Facilities - Other',           ab:'C799', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },


  {nm: 'Outdoor Recreational Facilities',                    ab:'C800',
    sc: [
      {nm: 'Amusement Park - outdoor',                         ab:'C805', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Archery Range',                                    ab:'C808', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Baseball Field',                                   ab:'C806', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Boat Ramp',                                        ab:'C807', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Fishing Area / Pond',                              ab:'C809', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Golf Courses',                                     ab:'C810', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Driving Range',                                    ab:'C811', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Shooting Range',                                   ab:'C813', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Tennis Courts',                                    ab:'C820', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Riding / Horse Stables',                           ab:'C830', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Skateboard Park',                                  ab:'C835', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Beaches or Swimming Pools',                        ab:'C840', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Marinas - recreational',                           ab:'C850', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Fish and Game Clubs',                              ab:'C860', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Camping Facilities - Tents, Campers, RVs',         ab:'C870', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Trails - Hiking / Running',                        ab:'C875', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Summer Camps',                                     ab:'C880', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Other Structures on Rec Land',                     ab:'C890', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]},
      {nm: 'Outdoor Recreational Facilities - Other',          ab:'C899', sc: [ {nm:'-- No Selections --',ab:'n', sc: null} ]} ] },
       
  {nm: 'Industrial',                                       ab:'C900',
    sc: [
      {nm: 'MANUFACTURING AND PROCESSING',                      ab:'CI000',
        sc: [
          {nm: 'Buildings for Manufacturing operations',            ab:'CI010'},
          {nm: 'Warehouses for storage of manuf products',          ab:'CI020'},
          {nm: 'Office Building - as part of manuf ops',            ab:'CI030'},
          {nm: 'Land - integral part of manuf ops',                 ab:'CI040'},
          {nm: 'Research and Development facilities',               ab:'CI050'},
          {nm: 'Industrial - Light',                                ab:'CI060'},
          {nm: 'Industrial - Heavy',                                ab:'CI070'},
          {nm: 'Manufacturing',                                     ab:'CI080'} ] },

      {nm: 'MINING AND QUARRYING',                              ab:'CI100',
        sc: [
          {nm: 'Sand and Gravel',                                   ab:'CI110'},
          {nm: 'Gypsum',                                            ab:'CI120'},
          {nm: 'Rock',                                              ab:'CI130'},
          {nm: 'Other',                                             ab:'CI140'} ] },

      {nm: 'UTILITY PROPERTIES',                                ab:'CI200',
        sc: [
          {nm: 'Tanks',                                             ab:'CI210'},
          {nm: 'Liquid Natural Gas Tanks',                          ab:'CI220'},
          {nm: 'Electric Transmission Right of Way',                ab:'CI230'},
          {nm: 'electricity Regulating Substation',                 ab:'CI240'},
          {nm: 'Gas Production Plants',                             ab:'CI250'},
          {nm: 'Gas Pipelene Right of Way',                         ab:'CI260'},
          {nm: 'Natural or Manufacture Gas Storage',                ab:'CI270'},
          {nm: 'Gas Pressure Control Stations',                     ab:'CI280'},
          {nm: 'Oil Storage - Salt Dome',                           ab:'CI290'} ] },

      {nm: 'UTILITY PROPERTIES - COMMUNICATIONS',               ab:'CI300',
        sc: [
          {nm: 'Telephone Exchange Stations',                       ab:'CI310'},
          {nm: 'Telephone Relay Towers',                            ab:'CI320'},
          {nm: 'Cable TV Transmitting Facilities',                  ab:'CI330'},
          {nm: 'Radio, Television Transmission Facilities',         ab:'CI340'} ] },

      {nm: 'VACANT LAND - ACCESSORY TO INDU PROPERTY',          ab:'CI400',
        sc: [
          {nm: 'Developable Land',                                  ab:'CI410'},
          {nm: 'Potentially Developable Land',                      ab:'CI420'},
          {nm: 'Undevelopable Land',                                ab:'CI430'} ] },

      {nm: 'ELECTRIC GENERATION PLANTS',                        ab:'CI500',
        sc: [
          {nm: 'Electric Generation Plants - Nuclear',              ab:'CI510'},
          {nm: 'Electric Generation Plants - Coal Fired',           ab:'CI520'},
          {nm: 'Electric Generation Plants - Natural Gas Turbine',  ab:'CI530'},
          {nm: 'Electric Generation Plants - Hydroelectric',        ab:'CI540'},
          {nm: 'Electric Generation Plants - Solar',                ab:'CI550'},
          {nm: 'Electric Generation Plants - Wind',                 ab:'CI560'},
          {nm: 'Electric Generation Plants - Other',                ab:'CI570'} ] },

      {nm: 'FLEX',                                              ab:'CI600', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},

      {nm: 'DISTRIBUTION',                                      ab:'CI700', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},

      {nm: 'R&D',                                               ab:'CI800', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},

      {nm: 'REFRIGERATION / COLD STORAGE',                      ab:'CI900', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},

      {nm: 'SERVICE',                                           ab:'CIA00', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},

      {nm: 'SHOWROOM',                                          ab:'CIB00', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},

      {nm: 'TRUCK TERMINAL',                                    ab:'CIC00', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},

      {nm: 'WAREHOUSE',                                         ab:'CID00', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},

      {nm: 'INDUSTRIAL - OTHER',                                ab:'CI999', sc:[{nm:'-- No Selections --',ab:'n', sc: null}] } 
    ] 
  },

  {nm: 'Specialty - Civic, Religious',                     ab:'C997',
    sc: [
      {nm: 'Church',                                            ab:'CIVC-805', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Synagogue',                                         ab:'CIVC-810', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Temple',                                            ab:'CIVC-815', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Mosque',                                            ab:'CIVC-820', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Rectory or Parsonnage',                             ab:'CIVC-825', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Religious - Other',                                 ab:'CIVC-830', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

  {nm: 'Specialty - Other',                                ab:'C998',
    sc: [
      {nm: 'Car Wash',               ab:'CRWH', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Marina',                 ab:'MARI', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Boat Slip',              ab:'BOAT', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Self Storage',           ab:'SLFS', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Parking',                ab:'PARK', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Gardens',                ab:'GRDN', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Parking',                ab:'PRKG', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Oil Drilling',           ab:'OILD', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Event Location',         ab:'EVNT', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Airport',                ab:'AIRP', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Hanger',                 ab:'HNGR', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Farmland',               ab:'FARM', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Studio - Film',          ab:'FILM', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Live - Work',            ab:'LVWK', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Senior Housing',         ab:'HSSR', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Police',                 ab:'POLC', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Housing',                ab:'HSNG', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Student Housing',        ab:'STDH', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Student Dormitory',      ab:'DORM', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Data Storage',           ab:'DATA', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Racetrack',              ab:'RCTK', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Gas Station',            ab:'GASS', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Scrap/Junk yard',        ab:'SJYD', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Veterinarian',           ab:'VETN', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Mall - Retail',          ab:'MALL', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'School - Elem',          ab:'SCHE', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'School - Middle',        ab:'SCHM', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'School - Highschool',    ab:'SCHH', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'School - K12',           ab:'SK12', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'University',             ab:'UNIV', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Campus - College',       ab:'CMPC', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Educational Facility',   ab:'EDUF', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
      {nm: 'Other',                  ab:'OTHR', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]}, ] },

  {nm: 'Commercial - Other',           ab:'C999', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]}
];


//===================================================================
//     RIBBIT STANDARD MIXED USE TYPES - LEVEL II Classification
//===================================================================
export const RibbitStdMixedUseTypesII = [
  {nm: 'Retail + Residential',              ab:'M000', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Retail + Office + Residential',     ab:'M010', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Retail + Office',                   ab:'M020', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Office + Residential',              ab:'M030', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Office + Industrial',               ab:'M040', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Primarily Residential',             ab:'M100', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Primarily Commercial Retail',       ab:'M110', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Primarily Commercial Office',       ab:'M120', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Primarily Commercial with Land',    ab:'M130', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Primarily Commercial Other',        ab:'M140', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Primarily Open Space',              ab:'M150', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
  {nm: 'Mixed Use - Other',                 ab:'M999', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]}
];


//===================================================================
//     RIBBIT STANDARD INDUSTRIAL TYPES - LEVEL III Classification
//===================================================================
//export const RibbitStdIndustrialTypesIII = [
// {nm: 'MANUFACTURING AND PROCESSING',                      ab:'CI000',
//   sc:[
//     {nm: 'Buildings for Manufacturing operations',            ab:'CI010'},
//     {nm: 'Warehouses for storage of manuf products',          ab:'CI020'},
//     {nm: 'Office Building - as part of manuf ops',            ab:'CI030'},
//     {nm: 'Land - integral part of manuf ops',                 ab:'CI040'},
//     {nm: 'Research and Development facilities',               ab:'CI050'},
//     {nm: 'Industrial - Light',                                ab:'CI060'},
//     {nm: 'Industrial - Heavy',                                ab:'CI070'},
//     {nm: 'Manufacturing',                                     ab:'CI080'} ] },

// {nm: 'MINING AND QUARRYING',                              ab:'CI100',
//   sc:[
//     {nm: 'Sand and Gravel',                                   ab:'CI110'},
//     {nm: 'Gypsum',                                            ab:'CI120'},
//     {nm: 'Rock',                                              ab:'CI130'},
//     {nm: 'Other',                                             ab:'CI140'} ] },

// {nm: 'UTILITY PROPERTIES',                                ab:'CI200',
//   sc: [
//     {nm: 'Tanks',                                             ab:'CI210'},
//     {nm: 'Liquid Natural Gas Tanks',                          ab:'CI220'},
//     {nm: 'Electric Transmission Right of Way',                ab:'CI230'},
//     {nm: 'electricity Regulating Substation',                 ab:'CI240'},
//     {nm: 'Gas Production Plants',                             ab:'CI250'},
//     {nm: 'Gas Pipelene Right of Way',                         ab:'CI260'},
//     {nm: 'Natural or Manufacture Gas Storage',                ab:'CI270'},
//     {nm: 'Gas Pressure Control Stations',                     ab:'CI280'},
//     {nm: 'Oil Storage - Salt Dome',                           ab:'CI290'}]},

// {nm: 'UTILITY PROPERTIES - COMMUNICATIONS',               ab:'CI300',
//   sc:[
//     {nm: 'Telephone Exchange Stations',                       ab:'CI310'},
//     {nm: 'Telephone Relay Towers',                            ab:'CI320'},
//     {nm: 'Cable TV Transmitting Facilities',                  ab:'CI330'},
//     {nm: 'Radio, Television Transmission Facilities',         ab:'CI340'}]},

// {nm: 'VACANT LAND - ACCESSORY TO INDU PROPERTY',          ab:'CI400',
//   sc:[
//     {nm: 'Developable Land',                                  ab:'CI410'},
//     {nm: 'Potentially Developable Land',                      ab:'CI420'},
//     {nm: 'Undevelopable Land',                                ab:'CI430'}]},

// {nm: 'ELECTRIC GENERATION PLANTS',                        ab:'CI500',
//   sc:[
//     {nm: 'Electric Generation Plants - Nuclear',              ab:'CI510'},
//     {nm: 'Electric Generation Plants - Coal Fired',           ab:'CI520'},
//     {nm: 'Electric Generation Plants - Natural Gas Turbine',  ab:'CI530'},
//     {nm: 'Electric Generation Plants - Hydroelectric',        ab:'CI540'},
//     {nm: 'Electric Generation Plants - Solar',                ab:'CI550'},
//     {nm: 'Electric Generation Plants - Wind',                 ab:'CI560'},
//     {nm: 'Electric Generation Plants - Other',                ab:'CI570'}]},

// {nm: 'INDUSTRIAL - OTHER',                                ab:'CI999'}
// ];


//===================================================================
//     RIBBIT STANDARD PROPERTY STATUS CODES
//===================================================================
export const RibbitStdPropStatusCodes = [
{nm: 'Off Market',        ab:'OM'},
{nm: 'For Sale - Agent',  ab:'SA'},
{nm: 'For Sale - Owner',  ab:'SO'},
{nm: 'Lease',             ab:'LS'},
{nm: 'Let',               ab:'LT'},
{nm: 'Rent',              ab:'RN'},
{nm: 'Pending',           ab:'PN'},
{nm: 'New Construction',  ab:'NC'},
{nm: 'Coming Soon',       ab:'CS'},
{nm: 'Recently Sold',     ab:'RS'},
{nm: 'Pre-Foreclosure',   ab:'PF'},
{nm: 'Foreclosure',       ab:'FC'},
{nm: 'Bank Owned',        ab:'BO'},
{nm: 'Unknown',           ab:'UN'},
{nm: 'Other',             ab:'OT'}
];


//===================================================================
//     RIBBIT STANDARD OFFICE SPACE CLASSIFICATION TYPES
//===================================================================
export const RibbitStdSpaceClassCodes = [
{nm: 'Class A',  ab:'CLSA'},
{nm: 'Class B',  ab:'CLSB'},
{nm: 'Class C',  ab:'CLSC'},
{nm: 'Other',    ab:'OTHR'},
];

//===================================================================
//     RIBBIT STANDARD OFFICE SPACE USE TYPES
//===================================================================
export const RibbitStdSpaceUseClassCodes = [
  {nm:"All",        ab:'*'  },
  {nm:"Office",     ab:"OFF"},
  {nm:"Coworking",  ab:"CWK"},
  {nm:"Industrial", ab:"IND"},
  {nm:"Retail",     ab:"RTL"},
  {nm:"Restaurant", ab:"RST"},
  {nm:"Flex",       ab:"FLX"},
  {nm:"Medical",    ab:"MED"},
  {nm:"Land",       ab:"LND"},
  {nm:"Storage",    ab:"STR"}
]


/////////////////////////////////////////////////////////////////////////////////////////////
//
//                         OTHER TYPES NOT USED AT PRESENT
//
/////////////////////////////////////////////////////////////////////////////////////////////
//
//OPEN SPACE TYPES - CODE 2
//
export const RibbitStdOpenLandClassCodes = [
{nm: 'OPEN LAND - RESIDENTIAL',                         ab:'OSPC-020',
  sc: [
    {nm: 'Residential Open Land',                           ab:'OSPC-201', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
    {nm: 'Underwater Land or Marshes - Not Public',         ab:'OSPC-202', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

{nm: 'OPEN LAND - RURAL AREA',                          ab:'OSPC-021',
  sc: [
    {nm: 'Non-Productive Agricultural - Farm',              ab:'OSPC-210', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
    {nm: 'Non-Productive Vacant',                           ab:'OSPC-211', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

{nm: 'OPEN LAND - COMMERCIAL AREA',                     ab:'OSPC-022',
    sc: [
    {nm: 'Commercial Vacant Land',                          ab:'OSPC-220', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
    {nm: 'Underwater Land or Marshes',                      ab:'OSPC-221', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

{nm: 'OPEN LAND - INDUSTRIAL AREA',                     ab:'OSPC-023',
  sc: [
    {nm: 'Industrial Vacant Line',                          ab:'OSPC-230', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]},
    {nm: 'Underwater Land or Marshes',                      ab:'OSPC-231', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

//{nm: 'PROPERTY CLASSIFIED AS OPEN SPACE',        ab:'OSPC-'},
{nm: 'FOREST LAND',                                      ab:'OSPC-026',
  sc: [
    {nm: 'Christmas Trees',                                  ab:'OSPC-262', sc:[{nm:'-- No Selections --',ab:'n', sc: null}]} ] },

{nm: 'AGRICULTURAL / HORTICULTURAL',                     ab:'OSPC-027', sc:[{nm:'-- No Selections --',ab:'n', sc: null}] },

{nm:'OPEN SPACE - OTHER',                                ab:'OSPC-OTH', sc: [] },
];


/////////////////////////////////////////////////////////////////////////////////////////////
//
//       US GOVERNMENT PROPERTY TYPES
//
/////////////////////////////////////////////////////////////////////////////////////////////
export const RibbitStdGovtClassCodes = [
{nm: 'UNITED STATES GOVERNMENT',              ab:'GOVT-100'},
{nm: 'USG Property',                          ab:'GOVT-105'},
{nm: 'Military bases, activities',            ab:'GOVT-110'},
{nm: 'Offices',                               ab:'GOVT-115'},
{nm: 'Department of Energy',                  ab:'GOVT-120'},

{nm: 'STATE GOVERNMENT',                      ab:'GOVT-200'},
{nm: 'Dept Conservation, Recreation',         ab:'GOVT-205'},
{nm: 'Dept Natural Resources',                ab:'GOVT-210'},
{nm: 'Dept State Parks',                      ab:'GOVT-215'},
{nm: 'Dept Fisheries and Wildlife',           ab:'GOVT-220'},
{nm: 'Dept Corrections',                      ab:'GOVT-225'},
{nm: 'Dept Youth Svcs',                       ab:'GOVT-230'},
{nm: 'Dept Public Health',                    ab:'GOVT-235'},
{nm: 'Dept Mental Health',                    ab:'GOVT-240'},
{nm: 'Dept Watershed Management',             ab:'GOVT-245'},
{nm: 'Dept of Education',                     ab:'GOVT-250'},
{nm: 'State College',                         ab:'GOVT-255'},
{nm: 'State University',                      ab:'GOVT-260'},
{nm: 'State Educational Facility - other',    ab:'GOVT-265'},
{nm: 'State Police Properties',               ab:'GOVT-270'},
{nm: 'State Bureau of Investigation',         ab:'GOVT-275'},
{nm: 'Dept of Roads and Highways',            ab:'GOVT-280'},
{nm: 'State Judiciary',                       ab:'GOVT-285'},
{nm: 'Bureau of State Buildings',             ab:'GOVT-290'},
{nm: 'Bureau of Records, Archives',           ab:'GOVT-300'},
{nm: 'Dept of Reveune',                       ab:'GOVT-305'},
{nm: 'National Guard',                        ab:'GOVT-310'},
{nm: 'National Guard - Armory',               ab:'GOVT-315'},
{nm: 'State Guard',                           ab:'GOVT-320'},
{nm: 'Housing Authority',                     ab:'GOVT-325'},
{nm: 'Utility Authority',                     ab:'GOVT-330'},
{nm: 'Transportation Authority',              ab:'GOVT-335'},
{nm: 'Authority - Other',                     ab:'GOVT-340'},
{nm: 'State - Other',                         ab:'GOVT-345'},

{nm: 'MUNICIPAL AND COUNTY GOVERNMENT',       ab:'GOVT-500'},
{nm: 'Public Buildings',                      ab:'GOVT-505'},
{nm: 'Courthouses',                           ab:'GOVT-510'},
{nm: 'Offices',                               ab:'GOVT-515'},
{nm: 'Warehouses',                            ab:'GOVT-520'},
{nm: 'Roadworks Facilities',                  ab:'GOVT-525'},  
{nm: 'Waterworks Facilities',                 ab:'GOVT-530'},
{nm: 'Electrical Utility Facilities',         ab:'GOVT-535'},
{nm: 'Sewer or Sanitation Facilities',        ab:'GOVT-540'},
{nm: 'Sanitation - Dump',                     ab:'GOVT-545'},
{nm: 'Recycling Facilities',                  ab:'GOVT-550'},
{nm: 'Sheriff\'s Dept',                       ab:'GOVT-555'},
{nm: 'County Police',                         ab:'GOVT-560'},        
{nm: 'Cemetary',                              ab:'GOVT-565'},
{nm: 'Library',                               ab:'GOVT-570'},        
{nm: 'Auditorium, Performance Hall',          ab:'GOVT-575'},
{nm: 'Fire Station',                          ab:'GOVT-580'},        
{nm: 'Community Center',                      ab:'GOVT-585'},
{nm: 'Fraternal Organizations',               ab:'GOVT-590'},        
{nm: 'Museums',                               ab:'GOVT-595'},
{nm: 'Hospitals',                             ab:'GOVT-600'},        
{nm: 'Charitable Services',                   ab:'GOVT-605'},
{nm: 'Recreation, Active Use',                ab:'GOVT-610'},        
{nm: 'Housing, other',                        ab:'GOVT-615'},
{nm: 'Jail, Correctional Facility',           ab:'GOVT-620'},
{nm: 'Airport',                               ab:'GOVT-625'},
{nm: 'Airport - Hangar Facilities',           ab:'GOVT-630'},
{nm: 'Airport - Terminal Facilities',         ab:'GOVT-635'},

{nm: 'RELIGIOUS ORGANIZATIONS',               ab:'CIVC-800'},        
{nm: 'Church',                                ab:'CIVC-805'},
{nm: 'Synagogue',                             ab:'CIVC-810'},
{nm: 'Temple',                                ab:'CIVC-815'},
{nm: 'Mosque',                                ab:'CIVC-820'},
{nm: 'Rectory or Parsonnage',                 ab:'CIVC-825'},
{nm: 'Other',                                 ab:'CIVC-830'},
];

/////////////////////////////////////////////////////////////////////////////////////////////
//
// Classifier - Building Types, COMMERCIAL
//
/////////////////////////////////////////////////////////////////////////////////////////////
export const RibbitStdComBuildingTypeCodes = [
  {nm: 'Medical',               ab:'MEDC'},
  {nm: 'Warehouse',             ab:'WHSE'},
  {nm: 'MultiFamily',           ab:'MFAM'},
  {nm: 'Hotel',                 ab:'HOTL'},
  {nm: 'Retail',                ab:'RETL'},
  {nm: 'Restaurant',            ab:'REST'},
];

/////////////////////////////////////////////////////////////////////////////////////////////
//
// Diferent types of specialized properties - needs organization
// 
/////////////////////////////////////////////////////////////////////////////////////////////
export const RibbitStdSpecializedPropTypeCodes = [
  {nm: 'Oil Storage',            ab:'OILS'},
];