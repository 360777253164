import React, {useContext}  from 'react'
import { Navbar } from '../components'
import * as ROUTES from '../constants/routes'
import {sysConfig} from '../config'
import {AppContext} from '../contexts/AppContext'

const DEVMODE = sysConfig.DEV_MODE || false

export function MainNavbarContainer() {

    const {loggedIn} = useContext(AppContext)

    if( DEVMODE ) {
        return (

            <Navbar>
                <Navbar.NavLink to={ROUTES.HOME}>Home</Navbar.NavLink>
                <Navbar.NavLink to={ROUTES.NOT_FOUND}>MyRibbit</Navbar.NavLink>
                <Navbar.NavLink to={ROUTES.NOT_FOUND}>Properties</Navbar.NavLink>
                <Navbar.NavLink to={ROUTES.NOT_FOUND}>Favorites</Navbar.NavLink>
                <Navbar.NavLink to={ROUTES.MAPWORKSPACE}>Mapping</Navbar.NavLink>
                <Navbar.NavLink to={ROUTES.NOT_FOUND}>About</Navbar.NavLink>
                <Navbar.DropDown>
                    <Navbar.DropBtn>Development</Navbar.DropBtn>
                    <Navbar.DropDownContent>
                        <Navbar.NavLink to={ROUTES.DEV_TEST}>DevTest</Navbar.NavLink>
                        <Navbar.NavLink to={ROUTES.DEV_TEST2}>DevTest2</Navbar.NavLink>
                        <Navbar.NavLink to={ROUTES.DEV_TEST3}>SmallCard</Navbar.NavLink>
                        <Navbar.NavLink to={ROUTES.DEV_TEST4}>MapByBounds</Navbar.NavLink>
                        <Navbar.NavLink to={ROUTES.DEV_TEST5}>SideNav</Navbar.NavLink>
                        <Navbar.NavLink to={ROUTES.DEV_TEST6}>Search</Navbar.NavLink>
                    </Navbar.DropDownContent>
                </Navbar.DropDown>
                <Navbar.NavLink to={ROUTES.ADD_PROP}>AddProperty</Navbar.NavLink>
            </Navbar>
        )
        } else {
            return (

                <Navbar>
                    <Navbar.NavLink to={ROUTES.HOME}>Home</Navbar.NavLink>
                    <Navbar.NavLink to={ROUTES.NOT_FOUND}>MyRibbit</Navbar.NavLink>
                    <Navbar.NavLink to={ROUTES.NOT_FOUND}>Properties</Navbar.NavLink>
                    <Navbar.NavLink to={ROUTES.NOT_FOUND}>Favorites</Navbar.NavLink>
                    <Navbar.NavLink to={ loggedIn ? ROUTES.DEV_TEST4 : ROUTES.NOT_FOUND}>MapByBounds</Navbar.NavLink>
                    <Navbar.NavLink to={ loggedIn ? ROUTES.ADD_PROP : ROUTES.NOT_FOUND }>AddProperty</Navbar.NavLink>
                    <Navbar.NavLink to={ROUTES.NOT_FOUND}>About</Navbar.NavLink>
                </Navbar>
            )            
        }
}
