import styled from 'styled-components'

export const Container = styled.header`
  height: 2.85rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid darkgreen;
  background-color: var(--off-white);
  /* Fix the navbar to the top of the page */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`;

export const MainLogo = styled.div`

  margin: 0 0.5em 0 0.5em;
  padding: 0;
  font-weight: 700;
  font-size: 1.85em;
  letter-spacing: -0.5px;
  color: darkgreen;
`;