import React, {useEffect, useRef, useState, useContext} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Header} from '../components'
import * as ROUTES from '../constants/routes'
import { MainNavbarContainer } from './mainNavbarContainer'

import {LoginBox} from '../components'

import {Modal, ModalPage} from '../components/Modal'
import {AppContext} from '../contexts/AppContext'

import { BASE_URL} from '../services/BaseURL'





export function HeaderContainer() {

    const { setLoggedIn, setUserName, setAuthToken, rememberMe } = useContext(AppContext)

    const [displayLoginModal, setDisplayLoginModal ] = useState(false)
    const [ username, setUsername] = useState( window.localStorage.getItem('username') || null)
    const [ pwd, setPwd ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ errResponse, setErrResponse ] = useState(null)
    const [ facebookData, setFacebookData ] = useState({})

    // Local logged-in 
    const [_loggedIn, _setLoggedIn ] = useState(  window.localStorage.getItem('loggedIn') || false)

    const headerHeightRef = useRef()
    const history = useHistory()

    function loginBtnHndlr(e) {
        e.preventDefault()
        setErrResponse(null)
        setDisplayLoginModal(true)
    }

    function logoutBtnHndlr() {
        setLoggedIn(false)
        _setLoggedIn(false)
        window.localStorage.removeItem('rtk')
        window.localStorage.removeItem('username')
        window.localStorage.removeItem('loggedIn')
	history.push('/')
    }
     
    function btnCloseModal(e) {
        setDisplayLoginModal(false)
    }

    //
    //  LOGIN with USERNAME and PASSWORD
    //
    async function btnModalOK(e) {
        e.preventDefault()
        console.log("btnModalOK button clicked")
        const URL = BASE_URL +'/users/login'
        const data = {username: username, password: pwd}
        try {
          const response = await fetch( URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                    })
          if( response.ok) {
            const loginDetails = await response.json()
            console.log("Server-Response: ", loginDetails)
            if( loginDetails && loginDetails.success ) {
                setAuthToken( loginDetails.token)
                window.localStorage.setItem( 'rtk', loginDetails.token )
                
                setUserName( username )
                window.localStorage.setItem( 'username', username )

                window.localStorage.setItem('loggedIn', true)
                setLoggedIn(true)   // Global App var
                _setLoggedIn(true)  // Local loggedIn var

                setDisplayLoginModal(false)
                return
                
            } else {
                setErrResponse("Invalid login")
                setPwd('')
                return
            }
          } else {
            setErrResponse("Invalid login credentials")
            console.log("Response NOT ok: ", response.status)
            // setPwd('')
          }
        } catch(err) {
            setErrResponse("Server Error - Retry Login")
            console.log("ERROR: ", err)
            setPwd('')
        } finally {
            // setErrResponse(null)
            setPwd('')
        }

    }

    //
    //  LOGIN with FACEBOOK via OAUTH2
    //
    useEffect(()=>{
      async function sendFbLoginData() {
        const FB_LOGIN_URL = BASE_URL + '/users/facebook/token'
        console.log("(sendFbLoginData():", facebookData)
        try {
            if(facebookData !== {}) {
                const response = await fetch( FB_LOGIN_URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${facebookData.fbToken}`
                    },
                    body: JSON.stringify( facebookData )
                    })
                if(response) {
                    const loginDetails = await response.json()

                    console.log("Server-Response: ", loginDetails)

                    if( loginDetails && loginDetails.success ) {
                        setAuthToken( loginDetails.token)
                        window.localStorage.setItem( 'rtk', loginDetails.token )
                        
                        setUserName( loginDetails.uname )
                        window.localStorage.setItem( 'username', loginDetails.uname )

                        window.localStorage.setItem('loggedIn', true)
                        setLoggedIn(true)   // Global App var
                        _setLoggedIn(true)  // Local loggedIn var

                        setDisplayLoginModal(false)
                        return
                        
                    } else {
                        setErrResponse("Invalid login")
                        setPwd('')
                        return
                    }
                } else {
                    setErrResponse("Invalid login credentials")
                    console.log("Response NOT ok: ", response.status)
                    // setPwd('')
                } 
            }
        }catch(err) {
            setErrResponse("Server Error - Retry Login")
            console.log("ERROR: ", err)
            setPwd('')
        } finally {
            // setErrResponse(null)
            setPwd('')
        } 
      }
        
      sendFbLoginData()

    },[facebookData])

    useEffect(()=>{
        //console.log("HeaderHeight: ", headerHeightRef.current.getClientRects())
    },[displayLoginModal])

    useEffect(()=>{
        // When a new window is spawned, check to see if the user is logged in with
        // a valid token and set the username and navbar accordingly:
        // 
        // check for rtk in localSession
            // check if JWT is still valid
            // set _loggedIn to true if it is
        // else _loggedIn is false
        async function checkLoginStatus() {
            const jwt = window.localStorage.getItem('rtk')
            console.log(jwt)
            if( jwt ) {
                try{
                    const respPromise = await fetch( `${BASE_URL}/users/checkJWTToken`, {
                        method: 'POST',
                        headers: {
                            'Content-Type':'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }}
                    )
                    if( respPromise ) {
                        const resp = await respPromise.json()
                        console.log("%cJWT RESPONSE: ", "color:green",resp)
                        if( resp.success) {
                            _setLoggedIn(true)
                            setUsername( window.localStorage.getItem('username') )
                        } else {
                            _setLoggedIn(false)
                            if( !rememberMe )
                                setUsername(null)
                        }
                        
                    } else {
                        // Error - fetch returned error
                        _setLoggedIn(false)
                    }
                } catch(err) {
                    // on Error, set _display to false
                    _setLoggedIn(false)
                } finally {

                }
            } else 
                _setLoggedIn(false)
        }
        checkLoginStatus()

    },[])

    return (
      <ModalPage>
        <ModalPage.Page>
            <Header Ref={headerHeightRef}>
                <div className='logo-nav'>
                    <Header.Logo><h3>RIBBIT</h3></Header.Logo>
                    <MainNavbarContainer />
                </div>
                <div hidden={_loggedIn}>
                    <div className="btn-login-grp">
                        <Link to={ROUTES.SIGNUP}><button type="button" className="btn-signup">Signup</button></Link>
                        <button type="button" className="btn-login" onClick={loginBtnHndlr}>Login</button>
                    </div>
                </div>
                <div hidden={!_loggedIn}>
                    <strong style={{color:'darkgreen', marginRight:'10px'}}>{username} </strong>
                    <button type="button" className="btn-login" onClick={logoutBtnHndlr}>Logout</button>
                </div>
            </Header>
        </ModalPage.Page>


        {/* Login Modal  */}
        <Modal displayModal={displayLoginModal} style={{left:'0%', top:'0%', zIndex:"980"}}>
            <Modal.Box>  
                {/* <div className='login-box'>
                    <h3>LOGIN MODAL</h3>
                    <h2 style={{color:'red'}}>{errResponse}</h2>
                    <form >
                        <div className='row'>
                            <label htmlFor='username'>Username</label>
                            <input type="text" id="username" name="username"  autocomplete="username" value={username} onChange={(e)=>setUsername(e.target.value)}/>
                        </div>

                        <div className='row'>
                            <label htmlFor='current-password'>Password</label>
                            <input type="password" id="current-password" name="pwd" value={pwd} onChange={(e)=>setPwd(e.target.value)}  autocomplete='off'/>
                        </div>
                    </form>
                        <button className='btn-login' type='button' onClick={(e)=>btnModalOK(e)}>OK</button>
                        <button className='btn-login' type='cancel' onClick={(e)=>btnCloseModal(e)}>Cancel</button>
                </div> */}
                <LoginBox username={username} setUsername={setUsername} password={pwd} setPassword={setPwd} setFacebookData={setFacebookData}/>
                <button className='btn-login' type='button' onClick={(e)=>btnModalOK(e)}>OK</button>
                <button className='btn-login' type='cancel' onClick={(e)=>btnCloseModal(e)}>Cancel</button>
            </Modal.Box>
        </Modal>


    </ModalPage>        
    )
}
