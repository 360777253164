

// Currency	    ISO 4217 - code	    Symbol	    Numeric
export const currencies = [
  {nm:"Australian Dollar",      iso:"AUD",  sym:["$","A$","AU$"],       num: "036"},
  {nm:"Albanian Lek",           iso:"ALL",  sym:["L"],                  num: "008"},
  {nm:"Algerian Dinar",         iso:"DZD",  sym:["دج ","(Arabic)","DA","(Latin)"], num: "012"},
  {nm:"Argentine Peso",         iso:"ARS",  sym:["$"],                  num: "032"},
  {nm:"Afghan afghani",         iso:"AFN",  sym:["؋"],                  num: "971"}, //"؋ ","(U+060B)","Af","(singular)","Afs","(plural)"
  {nm:"Angolan kwanza",         iso:"AOA",  sym:["Kz	"],               num: "973"},
  {nm:"Armenian dram",          iso:"AMD",  sym:["֏ (֏)","դր."],        num: "051"},
  {nm:"Azerbaijani manat",      iso:"AZN",  sym:["₼"],                  num: "944"},
  {nm:"British Pound",          iso:"GBP",  sym:["£"],                  num: "826"},
  {nm:"Bahamian Dollar",        iso:"BSD",  sym:["$","B$"],             num: "044"},
  {nm:"Bahraini Dinar",         iso:"BHD",  sym:[".د.ب ","(Arabic)","BD"], num: "048"},  //.د.ب
  {nm:"Bangladesh Taka",        iso:"BDT",  sym:["৳"],                  num: "050"},
  {nm:"Barbados Dollar",        iso:"BBD",  sym:["$","Bds$"],           num: "052"},
  {nm:"Belize Dollar",          iso:"BZD",  sym:["$"],                  num: "084"},
  {nm:"Bhutan Ngultrum",        iso:"BTN",  sym:["Nu."],                num: "064"},
  {nm:"Bolivian Boliviano",     iso:"BOB",  sym:["Bs"],                 num: "068"},
  {nm:"Botswana Pula",          iso:"BWP",  sym:["P"],                  num: "072"},
  {nm:"Brazilian Real",         iso:"BRL",  sym:["R$"],                 num: "986"},
  {nm:"Brunei Dollar",          iso:"BND",  sym:["$","B$"],             num: "096"},
  {nm:"Bulgarian Lev",          iso:"BGN",  sym:["лв."],                num: "975"},
  {nm:"Burundi Franc",          iso:"BIF",  sym:["FBu"],                num: "108"},
  {nm:"Belarusian ruble",       iso:"BYN",  sym:["Br"],                 num: "933"},
  {nm:"Convertible mark",       iso:"BAM",  sym:["KM"],                 num: "977"},
  {nm:"Canadian Dollar",        iso:"CAD",  sym:["$","Can$","C$","CA$","CAD"], num: "124"},
  {nm:"Chinese Yuan",           iso:"CNY",  sym:["元 / ¥"],             num: "156"},
  {nm:"Cambodia Riel",          iso:"KHR",  sym:["៛"],                  num: "116"},
  {nm:"Cape Verde Escudo",      iso:"CVE",  sym:["$"],                  num: "132"},
  {nm:"CFA Franc (BCEAO)",      iso:"XOF",  sym:["CFA"],                num: "952"},
  {nm:"CFA Franc (BEAC)",       iso:"XAF",  sym:["FCFA"],               num: "950"},
  {nm:"Chilean Peso",           iso:"CLP",  sym:["$"],                  num: "152"},
  {nm:"Colombian Peso",         iso:"COP",  sym:["$"],                  num: "170"},
  {nm:"Comoros Franc",          iso:"KMF",  sym:["CF"],                 num: "174"},
  {nm:"Costa Rica Colon",       iso:"CRC",  sym:["₡"],                  num: "188"},
  {nm:"Croatian Kuna",          iso:"HRK",  sym:["kn"],                 num: "191"},
  {nm:"Cuban Peso",             iso:"CUP",  sym:["$","\\MN","₱"],       num: "192"},
  {nm:"Czech Koruna",           iso:"CZK",  sym:["Kč"],                 num: "203"},
  {nm:"Congolese franc",        iso:"CDF",  sym:["FC"],                 num: "976"},
  {nm:"Danish Krone",           iso:"DKK",  sym:["kr."],                num: "208"},
  {nm:"Djibouti Franc",         iso:"DJF",  sym:["Fdj"],                num: "262"},
  {nm:"Dominican Peso",         iso:"DOP",  sym:["$","RD$"],            num: "214"},
  {nm:"Euro",                   iso:"EUR",  sym:["€"],                  num: "978"},
  {nm:"East Caribbean Dollar",  iso:"XCD",  sym:["$"],                  num: "951"},
  {nm:"Egyptian Pound",         iso:"EGP",  sym:["E£","r ج.م ","L.E."], num: "818"},
  {nm:"Ethiopian Birr",         iso:"ETB",  sym:["Br","ብር"],            num: "230"},
  {nm:"Eritrean nakfa",         iso:"ERN",  sym:["Nkf"],                num: "232"},
  {nm:"Fiji Dollar",            iso:"FJD",  sym:["FJ$","FJD"],          num: "242"},
  {nm:"Gambian Dalasi",         iso:"GMD",  sym:["D"],                  num: "270"},
  {nm:"Guatemala Quetzal",      iso:"GTQ",  sym:["Q"],                  num: "320"},
  {nm:"Guernsey Pound",         iso:"GGP",  sym:["£"],                  num: "GGP"}, /** NO ISO 4217 */
  {nm:"Guinea Franc",           iso:"GNF",  sym:["FG"],                 num: "324"},
  {nm:"Guyana Dollar",          iso:"GYD",  sym:["$","G$","GY$"],       num: "328"},
  {nm:"Ghanaian Cedi",          iso:"GHS",  sym:["GH₵"],                num: "936"},
  {nm:"Georgian lari",          iso:"GEL",  sym:["₾","ლ","GEL"],       num: "981"},
  {nm:"Hong Kong Dollar",       iso:"HKD",  sym:["HK$"],                num: "344"},
  {nm:"Haiti Gourde",           iso:"HTG",  sym:["G"],                  num: "332"},
  {nm:"Honduras Lempira",       iso:"HNL",  sym:["L"],                  num: "340"},
  {nm:"Hungarian Forint",       iso:"HUF",  sym:["Ft"],                 num: "348"},
  {nm:"Indonesian Rupiah",      iso:"IDR",  sym:["Rp"],                 num: "360"},
  {nm:"Indian Rupee",           iso:"INR",  sym:["₹"],                  num: "356"},
  {nm:"Iceland Krona",          iso:"ISK",  sym:["kr"],                 num: "352"},
  {nm:"Iran Rial",              iso:"IRR",  sym:["﷼	"],                 num: "364"},
  {nm:"Iraqi Dinar",            iso:"IQD",  sym:["د.ع	"],               num: "368"},
  {nm:"Israeli Shekel",         iso:"ILS",  sym:["₪"],                  num: "376"},
  {nm:"Japanese Yen",           iso:"JPY",  sym:["¥","円","圓"],        num: "392"},
  {nm:"Jamaican Dollar",        iso:"JMD",  sym:["$"],                  num: "388"},
  {nm:"Jordanian Dinar",        iso:"JOD",  sym:["د.أ	"],               num: "400"},
  {nm:"Kazakhstan Tenge",       iso:"KZT",  sym:["₸"],                  num: "398"},
  {nm:"Kenyan Shilling",        iso:"KES",  sym:["KSh","/=","/-","K"],  num: "404"},
  {nm:"Korean Won",             iso:"KRW",  sym:["₩"],                  num: "410"},
  {nm:"Kuwaiti Dinar",          iso:"KWD",  sym:["ك ","KD"],            num: "414"},
  {nm:"Kyrgyzstan Som",         iso:"KGS",  sym:["Som"],                num: "417"},
  {nm:"Macau Pataca",           iso:"MOP",  sym:["MOP$"],               num: "446"},
  {nm:"Macedonian Denar",       iso:"MKD",  sym:["den / ден"],          num: "807"},
  {nm:"Malawi Kwacha",          iso:"MWK",  sym:["K"],                  num: "454"},
  {nm:"Malaysian Ringgit",      iso:"MYR",  sym:["RM"],                 num: "458"},
  {nm:"Maldives Rufiyaa",       iso:"MVR",  sym:["Rf","MRf","MVR."],    num: "ރ	462"},
  {nm:"Mauritania Ougulya",     iso:"MRO",  sym:["UM"],                 num: "478"},
  {nm:"Mauritius Rupee",        iso:"MUR",  sym:["₨"],                  num: "480"},
  {nm:"Mexican Peso",           iso:"MXN",  sym:["$","Mex$"],           num: "484"},
  {nm:"Moldovan Leu",           iso:"MDL",  sym:["$","Mex$"],           num: "498"},
  {nm:"Mongolian Tugrik",       iso:"MNT",  sym:["₮"],                  num: "496"},
  {nm:"Moroccan Dirham",        iso:"MAD",  sym:["DH"],                 num: "504"},
  {nm:"Myanmar Kyat",           iso:"MMK",  sym:["K"],                  num: "104"},
  {nm:"Malagasy ariary",        iso:"MGA",  sym:["Ar"],                 num: "969"},
  {nm:"Mozambican metical",     iso:"MZN",  sym:["MT","MTn"],           num: "943"},
  {nm:"Namibian Dollar",        iso:"NAD",  sym:["$","N$"],             num: "516"},
  {nm:"Nepalese Rupee",         iso:"NPR",  sym:["रु"],                  num: "524"},
  {nm:"New Zealand Dollar",     iso:"NZD",  sym:["$","NZ$"],            num: "554"},
  {nm:"Nicaragua Cordoba",      iso:"NIO",  sym:["C$"],                 num: "558"},
  {nm:"Nigerian Naira",         iso:"NGN",  sym:["₦"],                  num: "566"},
  {nm:"North Korean Won",       iso:"KPW",  sym:["₩"],                  num: "408"},
  {nm:"Norwegian Krone",        iso:"NOK",  sym:["kr"],                 num: "578"},
  {nm:"Omani Rial",             iso:"OMR",  sym:["ر.ع. ; R.O."],        num: "512"},
  {nm:"Pakistani Rupee",        iso:"PKR",  sym:["₨"],                  num: "586"},
  {nm:"Papua New Guinea Kina",  iso:"PGK",  sym:["K"],                  num: "598"},
  {nm:"Paraguayan Guarani",     iso:"PYG",  sym:["₲"],                  num: "600"},
  {nm:"Peruvian Nuevo Sol",     iso:"PEN",  sym:["S/"],                 num: "604"},
  {nm:"Philippine Peso",        iso:"PHP",  sym:["₱"],                  num: "608"},
  {nm:"Polish Zloty",           iso:"PLN",  sym:["zł"],                 num: "985"},
  {nm:"Qatar Rial",             iso:"QAR",  sym:["QR","ر.ق	"],         num: "634"},
  {nm:"Romanian New Leu",       iso:"RON",  sym:["L"],                  num: "946"},
  {nm:"Russian Rouble",         iso:"RUB",  sym:["₽","руб / р."],       num: "643"},
  {nm:"Rwanda Franc",           iso:"RWF",  sym:["FRw","RF","R₣"],      num: "646"},
  {nm:"Swiss Franc",            iso:"CHF",  sym:["Fr.","Rp."],          num: "756"},
  {nm:"Samoa Tala",             iso:"WST",  sym:["WS$"],                num: "882"},
  {nm:"Saudi Arabian Riyal",    iso:"SAR",  sym:["SAR","ر.س " , "﷼	"], num: "682"},
  {nm:"Seychelles Rupee",       iso:"SCR",  sym:["SCR","SR"],           num: "690"},
  {nm:"Sierra Leone Leone",     iso:"SLL",  sym:["Le"],                 num: "694"},
  {nm:"Singapore Dollar",       iso:"SGD",  sym:["S$","$"],             num: "702"},
  {nm:"Solomon Islands Dollar", iso:"SBD",  sym:["$","SI$"],            num: "090"},
  {nm:"Somali Shilling",        iso:"SOS",  sym:["Sh.So."],             num: "706"},
  {nm:"South African Rand",     iso:"ZAR",  sym:["R"],                  num: "710"},
  {nm:"Sri Lanka Rupee",        iso:"LKR",  sym:["Rs","රු,","ரூ"],      num: "144"},
  {nm:"Sudanese Pound",         iso:"SDG",  sym:["SDG","£SD","ج.س"],    num: "938"},
  {nm:"Swaziland Lilageni",     iso:"SZL",  sym:["L", "E","(pl.)"],     num: "748"},
  {nm:"Swedish Krona",          iso:"SEK",  sym:["kr"],                 num: "752"},
  {nm:"Syrian Pound",           iso:"SYP",  sym:["LS","£S"],            num: "760"},
  {nm:"Serbian dinar",          iso:"RSD",  sym:["din / дин"],          num: "941"},
  {nm:"Surinamese dollar",      iso:"SRD",  sym:["$"],                  num: "968"},
  {nm:"Thai Baht",              iso:"THB",  sym:["฿"],                  num: "764"},
  {nm:"Turkish Lira",           iso:"TRY",  sym:["₺","kr"],             num: "949"},
  {nm:"Taiwan Dollar",          iso:"TWD",  sym:["NT$"],                num: "901"},
  {nm:"Tanzanian Shilling",     iso:"TZS",  sym:["TSh"],                num: "834"},
  {nm:"Tongan paʻanga",         iso:"TOP",  sym:["T$"],                 num: "776"},
  {nm:"Trinidad Tobago Dollar", iso:"TTD",  sym:["$","TT$"],            num: "780"},
  {nm:"Tunisian Dinar",         iso:"TND",  sym:["	د.ت ","DT"],        num: "788"},
  {nm:"Tajikistani somoni",     iso:"TJS",  sym:["SM"],                 num: "972"},
  {nm:"Turkmenistan manat",     iso:"TMT",  sym:["T"],                  num: "934"},
  {nm:"United States Dollar",   iso:"USD",  sym:["$","US$","U$"],       num: "840"},
  {nm:"UAE Dirham",             iso:"AED",  sym:["د.إ	"],               num: "784"},
  {nm:"Ugandan Shilling",       iso:"UGX",  sym:["USh"],                num: "800"},
  {nm:"Ukraine Hryvnia",        iso:"UAH",  sym:["₴","грн"],            num: "980"},
  {nm:"Uruguayan New Peso",     iso:"UYU",  sym:["$","$U"],             num: "858"},
  {nm:"Uzbekistan Sum",         iso:"UZS",  sym:["soʻm"],               num: "860"},
  {nm:"Vanuatu Vatu",           iso:"VUV",  sym:["VT"],                 num: "548"},
  {nm:"Vietnam Dong",           iso:"VND",  sym:["₫/đ"],                num: "704"},
  {nm:"Yemen Riyal",            iso:"YER",  sym:["ر.ي, ﷼"],             num: "886"},
  {nm:"Zambian kwacha",         iso:"ZMW",  sym:["K"],                  num: "967"}
];


export const currencySelectList1 = currencies.map( cur => (<option key={`${cur.num}`} value={`${cur.iso}`}>{`${cur.iso} - ${cur.nm}`}</option>)  )

export const currencySymbolByISO = (ISO_val) => currencies.filter( cur => cur.iso === ISO_val)[0].sym[0]


